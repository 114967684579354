import React, { useState, useEffect } from 'react';
import {
	TextField,
	DateField,
	ReferenceManyField,
	SimpleShowLayout,
	useDataProvider,
	useNotify
} from 'react-admin';
import { makeStyles, createStyles, Divider, Container } from '@material-ui/core';
import { ColumnCustom } from '../common/ColumnCustom';
import { ValueCodeListsFilters } from './ValueCodeListsFilters';
import { CustomDatagrid } from '../common/customDataGrid/CustomDataGrid';

export const ValueCodeLists = (props: any) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const classes = useStyles();
	const [search] = useState('');
	const [services, setServices] = useState<any>([]);
	const [filterVCL, setFilterVCL] = useState<any>({ location: props.record?.id, status: 'all' });

	useEffect(() => {
		if (services.length === 0) {
			dataProvider
				.getList('services', {
					filter: {},
					sort: { field: 'name', order: 'ASC' },
					pagination: { page: 0, perPage: 1000000 }
				})
				.then(({ data }) => {
					setServices(data);
				})
				.catch((error) => {
					notify('Error getting services: ' + error, 'warning');
				});
		}
	}, [services]);

	return (
		<>
			<Container className={classes.container}>
				<SimpleShowLayout>
					<span className={classes.menuTitle}>Associated Value Code Lists</span>
					<Divider className={classes.dividerDetailShow} />
					<ValueCodeListsFilters
						filterVCL={filterVCL}
						setFilterVCL={setFilterVCL}
						services={services}
						locationVCL={props.record?.id}
					/>
					<div className={classes.listContainer}>
						<ReferenceManyField
							reference='valuecodelists'
							target='parent'
							filter={filterVCL}
							addLabel={false}
						>
							<CustomDatagrid
								hover={true}
								classes={{
									table: classes.table,
									thead: classes.thead,
									tbody: classes.tbody,
									headerCell: classes.headerCell,
									rowOdd: classes.rowOdd
								}}
								filter={{ key: 'label', value: `${search}` }}
								size='small'
								hasRowClick={true}
							>
								<TextField label='List ID' source='id' />
								<TextField label='List Name' source='name' />
								<TextField label='Service' source='service.name' className={classes.service} />
								<DateField label='Date Created' source='createdOn' showTime />
								<DateField label='Last Updated' source='lastUpdated' showTime />
								<ColumnCustom label='Status' />
								<TextField label='Total Codes' source='totalCodes' />
							</CustomDatagrid>
						</ReferenceManyField>
					</div>
				</SimpleShowLayout>
			</Container>
		</>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		listContainer: {
			maxWidth: '1070px',
			marginLeft: 0,
			borderTop: '1px solid #E2E3E9',
			background: '#F2F4FB'
		},
		listRoot: {
			margin: '24px 40px 0px 40px'
		},
		listContent: {
			boxShadow: '0 0 0 white',
			background: '#F2F4FB'
		},
		table: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			background: '#FFFFFF'
		},
		thead: {
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px'
		},
		tbody: {
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px'
		},
		headerCell: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			letterSpacing: '-0.5px',
			color: '#111111',
			padding: '20px 24px',
			width: '100px',
			'&:first-child': {
				width: '20px'
			},
			'&:last-child': {
				width: '10px'
			}
		},
		'&:nth-child(2)': {
			width: '50px'
		},
		tableCell: {
			padding: '0px',
			'&:first-cell': {
				width: '100px'
			}
		},
		branchName: {
			fontWeight: 'bold',
			color: '#1695CB'
		},
		location: {
			fontWeight: 'bold',
			color: '#1695CB'
		},
		status: {
			color: '#2D7D44',
			backgroundColor: '#E9F7ED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		rowOdd: {
			backgroundColor: '#F8F9FA'
		},
		exportBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '8px, 16px, 8px, 12px',
			borderRadius: '8px',
			width: '180px',
			height: '40px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		exportBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		menuTitle: {
			fontWeight: 700,
			fontSize: '18px',
			fontFamily: 'Manrope',
			marginBottom: '10px',
			marginTop: '10px'
		},
		dividerDetailShow: {
			marginBottom: '20px',
			color: 'grey'
		},
		container: {
			paddingTop: '5px',
			maxWidth: '1250px'
		},
		service: {
			width: '10px'
		}
	})
);

export default ValueCodeLists;
