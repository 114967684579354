import React from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import AlertIcon from '../../../assets/alert.svg';
import { DeleteCurrencyTypeDialogProps } from './DialogsProps';

export const DeleteCurrencyTypeDialog = ({
	deleteCurrencyTypeClosed,
	openDeleteCurrencyType,
	deleteCurrencyTypeClicked
}: DeleteCurrencyTypeDialogProps) => {
	return (
		<Dialog open={openDeleteCurrencyType} onClose={deleteCurrencyTypeClosed}>
			<DialogContent
				style={{
					textAlign: 'center'
				}}
			>
				<img src={AlertIcon} alt='alertIcon' />
				<DialogContentText
					style={{
						fontSize: '22px',
						fontWeight: 'bold',
						color: 'black',
						textAlign: 'center',
						margin: '0px 30px'
					}}
				>
					Are you sure you want to
					<br /> delete this Currency Type?
				</DialogContentText>
				<DialogContentText
					style={{
						fontSize: '16px',
						color: '#5F5F5F',
						textAlign: 'center'
					}}
				>
					Warning, this action is permanent
					<br /> and cannot be undone.
				</DialogContentText>
			</DialogContent>
			<DialogActions
				style={{
					backgroundColor: '#EEEFF3',
					justifyContent: 'center',
					padding: '20px 0px'
				}}
			>
				<Button
					onClick={deleteCurrencyTypeClosed}
					size='small'
					variant='contained'
					color='default'
					style={{
						width: '150px',
						fontWeight: 'bold',
						backgroundColor: 'white'
					}}
				>
					cancel
				</Button>
				<Button
					onClick={deleteCurrencyTypeClicked}
					size='small'
					variant='contained'
					style={{
						width: '150px',
						fontWeight: 'bold',
						backgroundColor: '#EB5757'
					}}
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};
