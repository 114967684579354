import { makeStyles, createStyles } from '@material-ui/core';
import uiColor from '../../utils/utilsColors';

export const useStyles = makeStyles(() =>
    createStyles({
        roomList: {
            background: 'white',
            marginTop: '16px'
        },
        showRoot: {},
        showNoActions: {
            marginTop: '20px'
        },
        showCard: {
            boxShadow: '0 0 0'
        },
        showLayout: {
            padding: 0,
            '&:first-child': {
                paddingTop: '0px'
            },
            '&:last-child': {
                paddingBottom: '0px'
            }
        },
        gridWrapper: {
            display: 'flex',
            margin: '0px 40px'
        },
        table: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            background: '#FFFFFF',
            minWidth: '980px'
        },
        thead: {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px'
        },
        tbody: {
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px'
        },
        headerCell: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '-0.5px',
            color: '#111111',
            padding: '20px 24px',
            width: '200px',
            '&:last-child': {
                width: '50px'
            }
        },
        locationName: {
            fontWeight: 'bold',
            color: uiColor.secondary,
            letterSpacing: '-0.5px'
        },
        tableRow: {
            '&$hover:hover': {
                background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
                cursor: 'pointer'
            }
        },
        hover: {},
        tableCell: {
            paddingTop: '16px',
            paddingBottom: '16px',
            borderRight: '1px solid #EEEFF3',
            paddingLeft: '24px'
        },
        tableCellNoBorder: {
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '24px'
        },
        showBtn: {
            visibility: 'hidden',
            '$hover:hover &': {
                visibility: 'visible'
            }
        },
        normalField: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#111111'
        },
        addRoomBtnRoot: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            padding: '1px 0px',
            borderRadius: '8px',
            width: '154px',
            height: '36px',
            background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
        },
        addRoomBtnTxt: {
            color: '#FFFFFF',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 'bold',
            letterSpacing: '0.5px'
        },
        tabsRoot: {
            borderBottom: '1px solid lightgray',
            padding: '0 40px'
        },
        tabsIndicator: {
            background: '#1695CB'
        },
        tabRoot: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            letterSpacing: '0.75px',
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '25px',
            color: '#969696',
            padding: 0,
            minWidth: '73px',
            marginRight: '32px'
        },
        tabSelected: {
            color: '#1695CB'
        },
        humanReadableId: {
            fontWeight: 'bold',
            color: '#1695CB',
            cursor: 'pointer'
        },
        rowOdd: {
            backgroundColor: '#F8F9FA'
        },
        listRoot: {
            paddingTop: 0,
            PaddingBottom: 0
        },
        listContent: {
            boxShadow: '0 0 0 white'
        }
    })
);
