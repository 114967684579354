import React, { useState } from 'react';
import { List, TextField, DateField } from 'react-admin';
import { useStyles } from './servicesStyles';
import { EmptyGrid } from '../common/EmptyGrid';
import { HeaderList } from '../common/HeaderList';
import { InfiniteDataGrid } from '../common/customDataGrid/InfiniteDataGrid';
import { ColumnCustom } from '../common/ColumnCustom';
import { MyShowButton } from '../common/myDataGrid/MyDatagrid';
import { DeleteServiceDialog } from './Dialogs/DeleteServiceDialog';
import { ServicesFilters } from './ServicesFilters';

function Services(props: any) {
	const classes = useStyles();
	const [search, searchInput] = React.useState('');
	const [openDeleteServiceDialog, setOpenDeleteServiceDialog] = useState(false);
	const [serviceId] = useState('');
	const [serviceName] = useState('');

	const [filter, setFilter] = useState<any>({ status: 'all' });

	const deleteServiceDialogClosed = () => {
		setOpenDeleteServiceDialog(false);
	};

	return (
		<>
			<HeaderList searchInput={searchInput} idSearch='branchName' showSearch={true} {...props} />
			<ServicesFilters filter={filter} setFilter={setFilter} />
			<div className={classes.listContainer}>
				<List
					{...props}
					bulkActionButtons={false}
					empty={<EmptyGrid />}
					actions={null}
					pagination={false}
					perPage={10}
					filter={filter}
					sort={{ field: 'name', order: 'ASC' }}
					syncWithService
					classes={{
						root: classes.listRoot,
						content: classes.listContent
					}}
				>
					<InfiniteDataGrid
						hover={true}
						classes={{
							table: classes.table,
							thead: classes.thead,
							tbody: classes.tbody,
							headerCell: classes.headerCell,
							rowOdd: classes.rowOdd
						}}
						hasRowClick={true}
						filter={{ key: 'label', value: `${search}` }}
					>
						<TextField label='Service Name' source='name' />
						<DateField label='Date Created' source='createdOn' showTime />
						<DateField label='Last Updated' source='lastUpdated' showTime />
						<ColumnCustom label='Status' />
						<MyShowButton />
					</InfiniteDataGrid>
				</List>
				<DeleteServiceDialog
					deleteServiceDialogClosed={deleteServiceDialogClosed}
					openDeleteServiceDialog={openDeleteServiceDialog}
					serviceId={serviceId}
					serviceName={serviceName}
					active={true}
				/>
			</div>
		</>
	);
}

export default Services;
