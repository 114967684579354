export function hasView(access: any, name: any) {
	if (access === undefined) access = localStorage.getItem('cscvc_roles');
	const r = JSON.parse(access).find((element: any) => element.type === name);
	return r.permissions.view;
}

export function hasViewById(access: any, name: any, id: any) {
	if (access === undefined || access === null) access = localStorage.getItem('cscvc_roles');
	const r = JSON.parse(access).find((element: any) => element.type === name);
	if (r.id) return r.permissions.view && r.id === id ? r.permissions.view : false;
	else return r.permissions.view ? r.permissions.view : false;
}

export function hasCreate(access: any, name: any) {
	if (access === undefined || access === null) access = localStorage.getItem('cscvc_roles');
	let r;
	if (access != null) r = JSON.parse(access).find((element: any) => element.type === name);
	else return false;
	return r.permissions.create;
}

export function hasEdit(access: any, name: any) {
	if (access === undefined || access === null) access = localStorage.getItem('cscvc_roles');
	const r = JSON.parse(access).find((element: any) => element.type === name);
	return r.permissions.edit;
}

export function hasDelete(access: any, name: any) {
	if (access === undefined || access === null) access = localStorage.getItem('cscvc_roles');
	const r = JSON.parse(access).find((element: any) => element.type === name);
	return r.permissions.delete;
}
