import React from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import uiColor from '../../../utils/utilsColors';
import AlertIcon from '../../../assets/alert.svg';
import { ActiveInactivePros } from './DialogsProps';

export const ActiveInactiveConsumerDialog = ({
	activeInactiveClosed,
	openActiveInactive,
	activeInactiveClicked,
	active
}: ActiveInactivePros) => {
	return (
		<Dialog open={openActiveInactive} onClose={activeInactiveClosed}>
			<DialogContent
				style={{
					textAlign: 'center'
				}}
			>
				<img src={AlertIcon} alt='alertIcon' />
				<DialogContentText
					style={{
						fontSize: '22px',
						fontWeight: 'bold',
						color: 'black',
						textAlign: 'center',
						margin: '0px 30px'
					}}
				>
					Are you sure you want to
					<br /> {active === true ? 'activate' : 'deactivate'} this Consumer?
				</DialogContentText>
			</DialogContent>
			<DialogActions
				style={{
					backgroundColor: '#EEEFF3',
					justifyContent: 'center',
					padding: '20px 0px'
				}}
			>
				<Button
					onClick={activeInactiveClosed}
					size='small'
					variant='contained'
					color='default'
					style={{
						width: '150px',
						fontWeight: 'bold',
						backgroundColor: 'white'
					}}
				>
					cancel
				</Button>
				{!active && (
					<Button
						onClick={activeInactiveClicked}
						size='small'
						variant='contained'
						style={{
							width: '150px',
							fontWeight: 'bold',
							backgroundColor: uiColor.icon_offline
						}}
					>
						Deactivate
					</Button>
				)}
				{active && (
					<Button
						onClick={activeInactiveClicked}
						size='small'
						variant='contained'
						style={{
							width: '150px',
							fontWeight: 'bold',
							backgroundColor: uiColor.icon_online
						}}
					>
						Activate
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
