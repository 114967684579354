import { isEqual } from 'lodash';
import { Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Datagrid, Loading } from 'react-admin';
import { EmptyGrid } from '../EmptyGrid';
import {
	DEFAULT_LIST_RANGE_VALUE,
	FILTERING_LOADING_LABEL,
	GENERAL_LOADING_LABEL,
	SECONDARY_LOADING_LABEL
} from './constants';
import CustomDatagridBody from './CustomDataGridBody';
import useStyles from './styles';
import { FilterProps } from './CustomDataGrid';

export const InfiniteDataGrid = (props: any) => {
	const { loading, filter, ids, setPerPage, total, setTotalHeader, perPage, setPerPageValue } =
		props;
	const classes = useStyles();
	const [disableLoading, setDisableLoading] = useState(false);
	const [currFilter, setCurrFilter] = useState<FilterProps>();

	useEffect(() => {
		if (!isEqual(currFilter, filter)) {
			setDisableLoading(false);
			setCurrFilter(filter);
			setPerPage(DEFAULT_LIST_RANGE_VALUE);
		}
	}, [currFilter, filter, setPerPage]);

	if (!disableLoading && loading) {
		return (
			<div className={classes.loadingContainer}>
				<Loading
					loadingPrimary={filter && filter.value ? FILTERING_LOADING_LABEL : GENERAL_LOADING_LABEL}
					loadingSecondary={SECONDARY_LOADING_LABEL}
				/>
			</div>
		);
	}

	if (ids.length > 0) {
		// const { currentSort } = useListSortContext();
		// if (setCurSort) setCurSort(currentSort);
		if (setTotalHeader) setTotalHeader(total);
		if (setPerPageValue) setPerPageValue(perPage);
		const continueLoading = total > DEFAULT_LIST_RANGE_VALUE && total !== ids.length;

		return (
			<Paper
			// className={classes.customDataGrid}
			// style={{
			//   height: `${height}px`,
			// }}
			>
				<Datagrid
					{...props}
					key={props.id}
					body={
						<CustomDatagridBody
							// filterSearch={props.filterSearch}
							hasRowClick={props.hasRowClick}
							whenRowClicked={props.whenRowClicked}
							continueLoading={continueLoading}
							withAutoLoading={true}
							setDisableLoading={setDisableLoading}
						/>
					}
					hasBulkActions={false}
				/>
			</Paper>
		);
	}

	return <EmptyGrid />;
};
