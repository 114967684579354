const uiColor = {
	secondary: '#1695CB',
	hover: '#E0E0E0',
	active: '#2D7D44',
	inactive: '#5F5F5F',
	error: 'red',
	green: '#36821B',

	login_bk: '#888888',
	login_hover: '#505050',
	login_txt: 'white',
	login_page_bk: '#E0E0E0',

	sidebar_item: '#FFFFFF',
	sidebar_active: '#41C6FF',
	sidebar_hover: '#41C6FF60',
	sidebar_bk: '#232F64',

	icon_online: '#1695CB',
	icon_offline: '#F86D6D', // error pink
	icon_txt: '#FFF',

	state_available: '#3BA359',
	background_list: '#F2F4FB;',
	grey_label: '#969696'
};

export default uiColor;
