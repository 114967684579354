import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
	createStyles({
		showBtn: {
			visibility: 'hidden',
			'$hover:hover &': {
				visibility: 'visible'
			}
		},
		hover: {},
		tableRow: {
			'&$hover:hover': {
				background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
				cursor: 'pointer'
			},
			'&:nth-child(even)': {
				background: '#F8F9FA'
			}
		},
		tableCellValueCodeLists: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#111111',
			padding: '12px 24px',
			'&:first-child': {
				width: '200px'
			},
			'&:nth-child(2)': {
				width: '200px'
			},
			'&:nth-child(3)': {
				width: '300px'
			},
			'&:nth-child(4)': {
				width: '250px'
			},
			'&:nth-child(5)': {
				width: '150px'
			},
			'&:nth-child(6)': {
				width: '150px'
			},
			'&:last-child': {
				width: '80px',
				textAlign: 'right'
			}
		},
		tableCellTransactions: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#111111',
			padding: '12px 24px',
			'&:first-child': {
				width: '200px'
			},
			'&:last-child': {
				width: '80px'
			}
		},
		tableCellTransactionsFromCode: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#111111',
			padding: '12px 24px'
		},
		tableCellValueCodes: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#111111',
			padding: '12px 24px'
		},
		tableRowActivity: {
			'&$hover:hover': {
				background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)'
			}
		},

		tableCellActivity: {
			fontWeight: 500,
			textAlign: 'left'
		},
		emptyResultsContainer: {
			marginTop: '134px',
			marginBottom: '134px'
		},
		emptyResultsTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '24px',
			lineHeight: '33px',
			textAlign: 'center',
			letterSpacing: '-0.5px',
			color: '#111111'
		}
	})
);

export default useStyles;
