export const DEFAULT_FORMAT_DATE = 'MM/dd/yyyy';
export const ALL = 'All';
export const TODAY = 'Today';
export const DEFAULT_START_YEAR = 2010;
export const DEFAULT_DATES_LABEL = 'Dates';
export const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];
export const DEFAULT_LABEL_WIDTH = 70;
