import React from 'react';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import useStyles from './styles';

/**
 * Wrapper for TextField, specific to the consumer search view
 * @param props
 * @constructor
 */
const SearchConsumersField = (props: any) => {
	const classes = useStyles();

	const { label, source, filterSearch, setFilterSearch, autoFocus, onEnterPressed } = props;

	const inputValueChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newFilterSearch = filterSearch;
		if (event.target.value !== '') {
			newFilterSearch[source] = event.target.value;
		} else {
			delete newFilterSearch[source];
		}
		setFilterSearch({ ...newFilterSearch, withBalance: true });
	};

	const inputOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onEnterPressed();
		}
	};

	return (
		<Box display='flex'>
			<Typography className={classes.searchInputLabel}>{label}</Typography>
			<TextField
				label=''
				variant='outlined'
				onChange={inputValueChanged}
				classes={{
					root: classes.searchInputItem
				}}
				autoFocus={autoFocus}
				onKeyDown={inputOnKeyDown}
			/>
		</Box>
	);
};

export const SearchConsumers = (props: any) => {
	const classes = useStyles();

	const { filterSearch, setFilterSearch, setShowResults, setTotal } = props;

	const validateSearchInputLength = () => {
		if (Object.keys(filterSearch).length === 0) return false;
		return true;
	};

	const searchBtnClicked = () => {
		localStorage.setItem('csc_consumer_filterSearch', JSON.stringify(filterSearch));

		// set default to 0
		// after results show it will change to accurate number
		setTotal(0);
		setShowResults(true);
	};

	return (
		<div className={classes.searchAnyFieldContainer}>
			<div className={classes.searchAnyFieldBox}>
				<Typography className={classes.searchAnyFieldTitle}>Search Any Field</Typography>

				<Box className={classes.searchFieldBox}>
					<Box display='flex'>
						<Box flex={1}>
							<SearchConsumersField
								label='First Name'
								source='firstName'
								autoFocus={true}
								filterSearch={filterSearch}
								setFilterSearch={setFilterSearch}
								onEnterPressed={searchBtnClicked}
							/>

							<SearchConsumersField
								label='Last Name'
								source='lastName'
								filterSearch={filterSearch}
								setFilterSearch={setFilterSearch}
								onEnterPressed={searchBtnClicked}
							/>

							<SearchConsumersField
								label='Email'
								source='email'
								filterSearch={filterSearch}
								setFilterSearch={setFilterSearch}
								onEnterPressed={searchBtnClicked}
							/>

							<SearchConsumersField
								label='Phone'
								source='phone'
								filterSearch={filterSearch}
								setFilterSearch={setFilterSearch}
								onEnterPressed={searchBtnClicked}
							/>

							<Button
								classes={{
									root: classes.searchBtnRoot,
									label: classes.searchBtnLabel
								}}
								variant='contained'
								color='secondary'
								onClick={searchBtnClicked}
								disabled={!validateSearchInputLength()}
							>
								Search
							</Button>
						</Box>
					</Box>
				</Box>
			</div>
		</div>
	);
};
