import * as React from 'react';
import { forwardRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useRefresh, useNotify, useMutation } from 'react-admin';
import {
	Typography,
	Button,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	TextField,
	DialogActions,
	IconButton
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import useStyles from '../styles';
import { AddCurrencyTypeProps } from './DialogsProps';
import CloseIcon from '@material-ui/icons/Close';

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export const AddCurrencyTypeDialog = ({
	openAddCurrencyType,
	addCurrencyTypeClosed,
	serviceId
}: AddCurrencyTypeProps) => {
	const classes = useStyles();
	const refresh = useRefresh();
	const notify = useNotify();
	const [mutate] = useMutation();
	const [addCurrencyName, setCurrencyName] = useState('');
	const [addCurrencyDescription, setCurrencyDescription] = useState('');

	const onAddCurrencyTypeClicked = () => {
		mutate(
			{
				type: 'create',
				resource: 'currencytypes',
				payload: {
					data: {
						Name: addCurrencyName,
						Description: addCurrencyDescription,
						serviceId: serviceId,
						Monetary: false
					}
				}
			},
			{
				onSuccess: ({ data }) => {
					addCurrencyTypeClosed();
					refresh();
					notify(`Currency type created: ${addCurrencyName}.`);
				},
				onFailure: (error) => {
					notify(`Currency type not added`, 'warning');
					console.log('Error: ' + error);
				}
			}
		);
	};

	const handleChangeName = (event: any) => {
		setCurrencyName(event.target.value);
	};
	const handleChangeDescription = (event: any) => {
		setCurrencyDescription(event.target.value);
	};

	const onCancelClicked = (event: any) => {
		setCurrencyName('');
		addCurrencyTypeClosed();
	};

	return (
		<Dialog
			open={openAddCurrencyType}
			onClose={addCurrencyTypeClosed}
			TransitionComponent={Transition}
		>
			<AppBar className={classes.addServiceDialogAppBar}>
				<Toolbar>
					<Typography className={classes.addServiceDialogTitle}>Add New Currency Type</Typography>
					<IconButton aria-label='close' className={classes.closeBtn} onClick={onCancelClicked}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Name'
						size='small'
						type='search'
						value={addCurrencyName}
						onChange={handleChangeName}
					/>
				</div>
			</div>
			<div>
				<div className={classes.addRoomDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Description'
						size='small'
						type='search'
						multiline
						value={addCurrencyDescription}
						onChange={handleChangeDescription}
					/>
				</div>
			</div>
			<DialogActions>
				<Button
					onClick={onCancelClicked}
					size='small'
					variant='contained'
					color='default'
					classes={{
						root: classes.cancelBtnRoot,
						label: classes.cancelBtnTxt
					}}
				>
					Cancel
				</Button>
				<Button
					disabled={isEmpty(addCurrencyName.trim())}
					onClick={onAddCurrencyTypeClicked}
					size='small'
					color='secondary'
					variant='contained'
					classes={{
						root: classes.yesBtnRoot,
						label: classes.yesBtnTxt,
						disabled: classes.addRoomBtnDisabled
					}}
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
};
