import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
	createStyles({
		calendar: {
			backgroundColor: '#ffffff',
			border: 'none'
		},
		wrapper: {
			backgroundColor: 'blue'
		},
		weekDay: {
			backgroundColor: 'red'
		},
		customContainer: {
			'& .react-datepicker': {
				boxShadow:
					'0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
				borderRadius: '8px'
			}
		},
		customHeader: {
			margin: 10,
			display: 'flex',
			justifyContent: 'center'
		}
	})
);

export default useStyles;
