import * as React from 'react';
import { Layout } from 'react-admin';
import CSCAppBar from './MyAppBar';
import MyMenu from './MyMenu';

/**
 * Ref:
 * https://marmelab.com/react-admin/Admin.html#layout
 * https://marmelab.com/react-admin/Theming.html#using-a-custom-layout
 *
 * Top level layout component that allows customization of react-admin navigation and notification components
 * @param props
 * @constructor
 */
const MyLayout = (props: any) => {
	return <Layout {...props} appBar={CSCAppBar} menu={MyMenu} />;
};

export default MyLayout;
