import React from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useRefresh, useNotify, useMutation, useDataProvider } from 'react-admin';
import uiColor from '../../../utils/utilsColors';
import AlertIcon from '../../../assets/alert.svg';
import { DeleteServiceDialogPros } from './DialogsProps';

export const DeleteServiceDialog = ({
	deleteServiceDialogClosed,
	openDeleteServiceDialog,
	serviceId,
	serviceName,
	active
}: DeleteServiceDialogPros) => {
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();
	const dataProvider = useDataProvider();

	const updateServicesLocalStorage = () => {
		dataProvider
			.getList('services', {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then((response) => {
				localStorage.setItem('cscvc_filterServices', JSON.stringify(response.data));
			})
			.catch(() => {
				localStorage.setItem('cscvc_filterServices', '[]');
			});
	};

	const onDeleteServiceClicked = () => {
		mutate(
			{
				type: 'delete',
				resource: 'services',
				payload: {
					id: serviceId
				}
			},
			{
				onSuccess: () => {
					updateServicesLocalStorage();
					deleteServiceDialogClosed();
					refresh();
					notify(`Service: ${serviceName} ${active === true ? 'activated.' : 'deactivated.'}`);
				},
				onFailure: (error) => {
					notify(
						`Service: ${serviceName} ${active === true ? 'not activated.' : 'not deactivated.'}`,
						'warning'
					);
					console.log('Error: ' + error);
				}
			}
		);
	};

	return (
		<Dialog open={openDeleteServiceDialog} onClose={deleteServiceDialogClosed}>
			<DialogContent
				style={{
					textAlign: 'center'
				}}
			>
				<img src={AlertIcon} alt='alertIcon' />
				<DialogContentText
					style={{
						fontSize: '22px',
						fontWeight: 'bold',
						color: 'black',
						textAlign: 'center',
						margin: '0px 30px'
					}}
				>
					Are you sure you want to
					<br /> {active === true ? 'activate' : 'deactivate'} this Service?
				</DialogContentText>
			</DialogContent>
			<DialogActions
				style={{
					backgroundColor: '#EEEFF3',
					justifyContent: 'center',
					padding: '20px 0px'
				}}
			>
				<Button
					onClick={deleteServiceDialogClosed}
					size='small'
					variant='contained'
					color='default'
					style={{
						width: '150px',
						fontWeight: 'bold',
						backgroundColor: 'white'
					}}
				>
					Cancel
				</Button>
				{!active && (
					<Button
						onClick={onDeleteServiceClicked}
						size='small'
						variant='contained'
						style={{
							width: '150px',
							fontWeight: 'bold',
							backgroundColor: uiColor.icon_offline
						}}
					>
						Deactivate
					</Button>
				)}
				{active && (
					<Button
						onClick={onDeleteServiceClicked}
						size='small'
						variant='contained'
						style={{
							width: '150px',
							fontWeight: 'bold',
							backgroundColor: uiColor.icon_online
						}}
					>
						Activate
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
