import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
	createStyles({
		rootDiv: {
			position: 'relative',
			width: '240px'
		},
		margin: {
			margin: 0,
			marginLeft: '5px'
		},
		textField: {
			width: '165px',
			height: '48px'
		},
		label: {
			fontSize: '16px',
			lineHeight: '22px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			color: '#969696',
			'&.Mui-focused': {
				color: '#1695CB',
				fontWeight: 'bold'
			}
		},
		inputRoot: {
			borderColor: '#E0E0E0',
			borderRadius: '8px',
			boxSizing: 'border-box',
			width: '165px',
			paddingRight: 0,
			height: '48px',
			cursor: 'pointer',
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: '#1695CB'
			}
		},
		input: {
			padding: '11px 0px 9px 16px',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#111111',
			mixBlendMode: 'normal',
			marginRight: 0,
			width: '110px',
			cursor: 'pointer'
		},
		inputAdornment: {
			marginRight: '7px'
		},
		inputAdornmentEnd: {
			marginLeft: '10px'
		},
		svgIcon: {
			width: '20px',
			height: '22px'
		},
		wrapper: {
			boxShadow:
				'0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
			backgroundColor: '#FFFFFF',
			borderRadius: '8px',
			position: 'absolute',
			float: 'left',
			zIndex: 3,
			width: '372px'
		},
		toolbar: {
			margin: '20px 32px 32px 32px',
			display: 'flex',
			justifyContent: 'space-between'
		},
		leftBtn: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			border: '1px solid white',
			fontWeight: 'bold',
			letterSpacing: '0.5px',
			padding: 0,
			borderRadius: '8px',
			width: '103px',
			height: '32px',
			background: '#FFFFFF'
		},
		doneBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			fontWeight: 'bold',
			letterSpacing: '0.5px',
			padding: '1px 0px',
			borderRadius: '8px',
			width: '103px',
			height: '32px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		doneBtnTxt: {
			color: '#FFFFFF'
		}
	})
);

export default useStyles;
