import * as React from 'react';
import { forwardRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useRefresh, useNotify, useMutation, useDataProvider } from 'react-admin';
import { useHistory } from 'react-router-dom';
import {
	Typography,
	Button,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	TextField,
	DialogActions,
	IconButton
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import useStyles from '../styles';
import { AddServiceProps } from './DialogsProps';
import CloseIcon from '@material-ui/icons/Close';

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export const AddServiceDialog = ({ openAddService, addServiceClosed }: AddServiceProps) => {
	const classes = useStyles();
	const history = useHistory();
	const refresh = useRefresh();
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const [mutate] = useMutation();
	const [addServiceName, setAddServiceName] = useState('');
	const [addConsumerUrl, setAddConsumerUrl] = useState('');

	const updateServicesLocalStorage = () => {
		dataProvider
			.getList('services', {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then((response) => {
				localStorage.setItem('cscvc_filterServices', JSON.stringify(response.data));
			})
			.catch(() => {
				localStorage.setItem('cscvc_filterServices', '[]');
			});
	};

	const onAddServiceClicked = () => {
		mutate(
			{
				type: 'create',
				resource: 'services',
				payload: {
					data: {
						Name: addServiceName,
						ConsumerUrl: addConsumerUrl
					}
				}
			},
			{
				onSuccess: ({ data }) => {
					updateServicesLocalStorage();
					addServiceClosed();
					history.push(`/services/${data.id}/show`);
					refresh();
					notify(`Service created: ${addServiceName}.`);
				},
				onFailure: (error) => {
					notify(`Service not added`, 'warning');
					console.log('Error: ' + error);
				}
			}
		);
	};

	const handleChange = (event: any) => {
		setAddServiceName(event.target.value);
	};

	const onCancelClicked = (event: any) => {
		setAddServiceName('');
		setAddConsumerUrl('');
		addServiceClosed();
	};

	const handleChangeConsumerUrl = (event: any) => {
		setAddConsumerUrl(event.target.value);
	};

	return (
		<Dialog open={openAddService} onClose={addServiceClosed} TransitionComponent={Transition}>
			<AppBar className={classes.addServiceDialogAppBar}>
				<Toolbar>
					<Typography className={classes.addServiceDialogTitle}>Add New Service</Typography>
					<IconButton aria-label='close' className={classes.closeBtn} onClick={onCancelClicked}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<div>
				<div className={classes.addRoomDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Service Name'
						size='small'
						type='search'
						value={addServiceName}
						onChange={handleChange}
					/>
				</div>
				<div className={classes.addRoomDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Consumer URL'
						size='small'
						type='search'
						value={addConsumerUrl}
						onChange={handleChangeConsumerUrl}
					/>
				</div>
			</div>
			<DialogActions>
				<Button
					onClick={onCancelClicked}
					size='small'
					variant='contained'
					color='default'
					classes={{
						root: classes.cancelBtnRoot,
						label: classes.cancelBtnTxt
					}}
				>
					Cancel
				</Button>
				<Button
					disabled={isEmpty(addServiceName.trim())}
					onClick={onAddServiceClicked}
					size='small'
					color='secondary'
					variant='contained'
					classes={{
						root: classes.yesBtnRoot,
						label: classes.yesBtnTxt,
						disabled: classes.addRoomBtnDisabled
					}}
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
};
