import React, { useState } from 'react';
import { List, TextField, DateField } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { EmptyGrid } from '../common/EmptyGrid';
import { HeaderList } from '../common/HeaderList';
import { InfiniteDataGrid } from '../common/customDataGrid/InfiniteDataGrid';
import { ColumnCustom } from '../common/ColumnCustom';
import { MyShowButton } from '../common/myDataGrid/MyDatagrid';
import { LocationsFilters } from './LocationsFilters';

function LocationList(props: any) {
	const classes = useStyles();
	const [search, searchInput] = useState('');
	const [filter, setFilter] = useState<any>({ parent: true, status: 'all' });
	const [sort] = useState({ field: 'name', order: 'ASC' });

	return (
		<>
			<HeaderList searchInput={searchInput} idSearch='branchName' showSearch={true} {...props} />
			<LocationsFilters filter={filter} setFilter={setFilter} view={'list'} />
			<div className={classes.listContainer}>
				<List
					{...props}
					bulkActionButtons={false}
					empty={<EmptyGrid />}
					actions={null}
					pagination={false}
					sort={sort}
					perPage={10}
					syncWithLocation
					filter={filter}
					classes={{
						root: classes.listRoot,
						content: classes.listContent
					}}
				>
					<InfiniteDataGrid
						hover={true}
						classes={{
							table: classes.table,
							thead: classes.thead,
							tbody: classes.tbody,
							headerCell: classes.headerCell,
							rowOdd: classes.rowOdd
						}}
						hasRowClick={true}
						filter={{ key: 'label', value: `${search}` }}
					>
						<TextField label='Location' source='name' className={classes.location} />
						<TextField label='Child Locations' source='childrenLocations' />
						<DateField label='Date Created' source='createdOn' showTime />
						<DateField label='Last Updated' source='lastUpdated' showTime />
						<ColumnCustom label='Status' />
						<MyShowButton />
					</InfiniteDataGrid>
				</List>
			</div>
		</>
	);
}

const useStyles = makeStyles(() =>
	createStyles({
		listContainer: {
			width: 'auto',
			marginLeft: 0,
			borderTop: '1px solid #E2E3E9',
			background: '#F2F4FB'
		},
		listRoot: {
			margin: '24px 40px 0px 40px'
		},
		listContent: {
			boxShadow: '0 0 0 white',
			background: '#F2F4FB'
		},
		table: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			background: '#FFFFFF',
			minWidth: '980px'
		},
		thead: {
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px'
		},
		tbody: {
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px'
		},
		headerCell: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#111111',
			padding: '20px 24px',
			width: '100px',
			'&:first-child': {
				width: '200px'
			},
			'&:last-child': {
				width: '10px'
			}
		},
		branchName: {
			fontWeight: 'bold',
			color: '#1695CB'
		},
		location: {
			fontWeight: 'bold',
			color: '#1695CB'
		},
		status: {
			color: '#2D7D44',
			backgroundColor: '#E9F7ED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		rowOdd: {
			backgroundColor: '#F8F9FA'
		},
		exportBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '8px, 16px, 8px, 12px',
			borderRadius: '8px',
			width: '163px',
			height: '40px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		exportBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		}
	})
);

export default LocationList;
