/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
import * as React from 'react';
import { forwardRef, useState, useEffect } from 'react';
import {
	useRefresh,
	useNotify,
	useMutation,
	useDataProvider,
	getResources,
	useRedirect
} from 'react-admin';
import { useSelector } from 'react-redux';
import { RaBox } from 'ra-compact-ui';
import { isEmpty } from 'lodash';
import {
	Typography,
	Button,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	TextField,
	IconButton,
	DialogContent,
	Box,
	FormGroup,
	Checkbox,
	FormControlLabel,
	MenuItem
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TransitionProps } from '@material-ui/core/transitions';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { useStyles } from '../permissionsStyles';
import { AddRoleProps } from './DialogsProps';
import { stringFirstCharUpperCase } from '../../../utils/util';
import { Filter } from '../Filter';
const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export const AddRoleDialog = ({ openAddRole, addRoleClosed }: AddRoleProps) => {
	const classes = useStyles();
	const refresh = useRefresh();
	const notify = useNotify();
	const redirect = useRedirect();
	const [mutate] = useMutation();
	const dataProvider = useDataProvider();
	const [roleName, setRoleName] = useState('');
	const resources: Array<unknown> = useSelector(getResources);
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheckAllCreate, setIsCheckAllCreate] = useState(false);
	const [isCheckAllView, setIsCheckAllView] = useState(false);
	const [isCheckAllEdit, setIsCheckAllEdit] = useState(false);
	const [isCheckAllDelete, setIsCheckAllDelete] = useState(false);
	const [isCheck, setIsCheck] = useState<any>([]);
	const [resourcesClean, setResourcesClean] = useState<any>([]);
	const [services, setServices] = useState<any>([]);
	const [valueCodeLists, setValueCodeLists] = useState<any>([]);
	const [serviceSelected, setServiceSelected] = useState('');
	const [valueCodeListSelected, setValueCodeListSelected] = useState('');
	const [locationsToSelect, setLocationsToSelect] = useState<any>([]);
	const [locations, setLocations] = useState<any>([]);
	const [location, setLocation] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [selectedLocation, setSelectedLocation] = useState('');
	const [isApplication, setIsApplication] = useState(false);
	const [showToken, setShowToken] = useState(false);
	const [token, setToken] = useState('');
	const [auth0Scope, setAuth0Scope] = useState('');

	const retrieveValueCodeLists = () => {
		dataProvider
			.getList('valuecodelists', {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then(({ data }) => {
				setValueCodeLists(data);
			});
	};

	useEffect(() => {
		const rc = [] as any;
		resources.map((resource: any) => {
			if (
				resource.name !== 'webhooks' &&
				resource.name !== 'currencytypes' &&
				resource.name !== 'roles'
			) {
				rc.push({
					name: resource.name,
					label: resource.options.label
				});
			}
		});
		rc.push({
			name: 'valuestores',
			label: 'Value Stores'
		});
		rc.push({
			name: 'secrets',
			label: 'Secrets'
		});
		setResourcesClean(rc);
	}, [resources]);

	useEffect(() => {
		setLocations(JSON.parse(localStorage.getItem('cscvc_filterLocations') as string));
		setServices(JSON.parse(localStorage.getItem('cscvc_filterServices') as string));
		retrieveValueCodeLists();
	}, []);

	const onAddRoleClicked = () => {
		const selectedPermissions: permissions[] = [] as any;
		resourcesClean.map((resource: any) => {
			const json: permissionsOptions = {} as any;
			isCheck
				.filter((a: string | any[]) => a.includes(resource.name))
				.map((p: any) => {
					if (p.split('_')[1] === 'create') {
						json.create = true;
					}
					if (p.split('_')[1] === 'view') {
						json.view = true;
					}
					if (p.split('_')[1] === 'edit') {
						json.edit = true;
					}
					if (p.split('_')[1] === 'delete') {
						json.delete = true;
					}
				});

			if (resource.name === 'services' && serviceSelected !== '') {
				selectedPermissions.push({
					type: resource.name,
					id: serviceSelected,
					permissions: json
				});
			} else if (resource.name === 'valuecodelists' && valueCodeListSelected !== '') {
				selectedPermissions.push({
					type: 'valueCodeLists',
					id: valueCodeListSelected,
					permissions: json
				});
			} else if (resource.name === 'locations' && location !== '') {
				selectedPermissions.push({
					type: resource.name,
					id: location,
					permissions: json
				});
			} else if (resource.name === 'valuecodes') {
				selectedPermissions.push({
					type: 'valueCodes',
					permissions: json
				});
			} else if (resource.name === 'valuecodelists' && valueCodeListSelected === '') {
				selectedPermissions.push({
					type: 'valueCodeLists',
					permissions: json
				});
			} else if (resource.name === 'valuestores') {
				selectedPermissions.push({
					type: 'valueStores',
					permissions: json
				});
			} else {
				selectedPermissions.push({
					type: resource.name,
					permissions: json
				});
			}
		});

		mutate(
			{
				type: 'create',
				resource: 'roles',
				payload: {
					data: {
						Name: roleName,
						PermissionsJSON: selectedPermissions,
						IsApplication: isApplication,
						Token: isApplication ? token : null,
						Auth0Scope: !isApplication ? auth0Scope : null
					}
				}
			},
			{
				onSuccess: ({ data }) => {
					addRoleClosed();
					setAuth0Scope('');
					setToken('');
					setRoleName('');
					refresh();
					redirect(`/roles/${data.id}/show`);
					notify(`Role created: ${roleName}.`);
				},
				onFailure: (error) => {
					notify(`${error}`, 'warning');
					console.log('Error: ' + error);
				}
			}
		);
	};

	const handleRoleNameChange = (event: any) => {
		setRoleName(event.target.value);
	};

	const onCancelClicked = (event: any) => {
		addRoleClosed();
		setRoleName('');
		setIsCheck([]);
		setIsCheckAll(false);
		setIsCheckAllView(false);
		setIsCheckAllCreate(false);
		setIsCheckAllEdit(false);
		setIsCheckAllDelete(false);
		setIsApplication(false);
		setToken('');
		setAuth0Scope('');
		setLocation('');
		setSelectedLocation('');
		setLocationsToSelectFunc(null);
		setServiceSelected('');
		setValueCodeListSelected('');
		setShowToken(false);
	};

	const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		const allPermissions: string[] = [];
		resourcesClean.map((resource: any) => {
			if (resource.name === 'secrets') {
				allPermissions.push(`${resource.name}_view`);
			} else {
				allPermissions.push(`${resource.name}_create`);
				allPermissions.push(`${resource.name}_view`);
				allPermissions.push(`${resource.name}_edit`);
				allPermissions.push(`${resource.name}_delete`);
			}
		});

		setIsCheckAll(!isCheckAll);
		setIsCheck(allPermissions);
		if (isCheckAll) {
			setIsCheck([]);
			setIsCheckAllCreate(false);
			setIsCheckAllView(false);
			setIsCheckAllEdit(false);
			setIsCheckAllDelete(false);
		} else {
			setIsCheckAllCreate(true);
			setIsCheckAllView(true);
			setIsCheckAllEdit(true);
			setIsCheckAllDelete(true);
		}
	};

	const handleChangeAllCreate = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!isCheckAllCreate) {
			const allPermissions: string[] = [];
			if (isCheck.length === 0) {
				resourcesClean.map((resource: any) => {
					allPermissions.push(`${resource.name}_create`);
					setIsCheck(allPermissions);
				});
			} else {
				resourcesClean.map((resource: any) => {
					const index = isCheck.indexOf(`${resource.name}_create`);
					if (index === -1) {
						isCheck.push(`${resource.name}_create`);
						setIsCheck(isCheck);
					}
				});
			}
		}

		setIsCheckAllCreate(!isCheckAllCreate);
		if (isCheckAllCreate) {
			const allPermissionsCreate: string[] = isCheck;
			resourcesClean.map((resource: any) => {
				const index = allPermissionsCreate.indexOf(`${resource.name}_create`);
				if (index !== -1) {
					allPermissionsCreate.splice(index, 1);
				}
			});
			setIsCheck(allPermissionsCreate);
		}
	};

	const handleChangeAllView = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!isCheckAllView) {
			const allPermissions: string[] = [];
			if (isCheck.length === 0) {
				resourcesClean.map((resource: any) => {
					allPermissions.push(`${resource.name}_view`);
					setIsCheck(allPermissions);
				});
			} else {
				resourcesClean.map((resource: any) => {
					const index = isCheck.indexOf(`${resource.name}_view`);
					if (index === -1) {
						isCheck.push(`${resource.name}_view`);
						setIsCheck(isCheck);
					}
				});
			}
		}

		setIsCheckAllView(!isCheckAllView);
		if (isCheckAllView) {
			const allPermissionsView: string[] = isCheck;
			resourcesClean.map((resource: any) => {
				const index = allPermissionsView.indexOf(`${resource.name}_view`);
				if (index !== -1) {
					allPermissionsView.splice(index, 1);
				}
			});
			setIsCheck(allPermissionsView);
		}
	};

	const handleChangeAllEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!isCheckAllEdit) {
			const allPermissions: string[] = [];
			if (isCheck.length === 0) {
				resourcesClean.map((resource: any) => {
					allPermissions.push(`${resource.name}_edit`);
					setIsCheck(allPermissions);
				});
			} else {
				resourcesClean.map((resource: any) => {
					const index = isCheck.indexOf(`${resource.name}_edit`);
					if (index === -1) {
						isCheck.push(`${resource.name}_edit`);
						setIsCheck(isCheck);
					}
				});
			}
		}

		setIsCheckAllEdit(!isCheckAllEdit);
		if (isCheckAllEdit) {
			const allPermissionsEdit: string[] = isCheck;
			resourcesClean.map((resource: any) => {
				const index = allPermissionsEdit.indexOf(`${resource.name}_edit`);
				if (index !== -1) {
					allPermissionsEdit.splice(index, 1);
				}
			});
			setIsCheck(allPermissionsEdit);
		}
	};

	const handleChangeAllDelete = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!isCheckAllDelete) {
			const allPermissions: string[] = [];
			if (isCheck.length === 0) {
				resourcesClean.map((resource: any) => {
					allPermissions.push(`${resource.name}_delete`);
					setIsCheck(allPermissions);
				});
			} else {
				resourcesClean.map((resource: any) => {
					const index = isCheck.indexOf(`${resource.name}_delete`);
					if (index === -1) {
						isCheck.push(`${resource.name}_delete`);
						setIsCheck(isCheck);
					}
				});
			}
		}

		setIsCheckAllDelete(!isCheckAllDelete);
		if (isCheckAllDelete) {
			const allPermissionsDelete: string[] = isCheck;
			resourcesClean.map((resource: any) => {
				const index = allPermissionsDelete.indexOf(`${resource.name}_delete`);
				if (index !== -1) {
					allPermissionsDelete.splice(index, 1);
				}
			});
			setIsCheck(allPermissionsDelete);
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { id, checked } = event.target;
		setIsCheck([...isCheck, id]);
		if (!checked) {
			setIsCheck(isCheck.filter((item: string) => item !== id));
		}
	};

	const serviceChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const service = event.target.value as string;
		setServiceSelected(service);
	};

	const setLocationsToSelectFunc = (id: any) => {
		let locationsArray: any[] = [];
		locationsArray = locations
			.filter((location: any) => location.parentId == null)
			.map((loc: any) => {
				return {
					label: loc.name,
					value: loc.id,
					checked: id === loc.id,
					children:
						loc.childrenLocations === 0
							? []
							: locations
									.filter((childrenLocation: any) => childrenLocation.parentId === loc.id)
									.map((childLoc: any) => {
										return {
											label: childLoc.name,
											value: childLoc.id,
											checked: id === childLoc.id
										};
									})
				};
			});
		setLocationsToSelect(locationsArray);
	};

	const valueCodeListChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const valueCodeList = event.target.value as string;
		setValueCodeListSelected(valueCodeList);
		const vcl = valueCodeLists.find((v: any) => v.id === valueCodeList);
		setServiceSelected(vcl.serviceId);
		if (vcl.locationId != null) {
			setIsCheck([...isCheck, vcl.locationId]);
			setLocationsToSelectFunc(vcl.locationId);
			setLocation(vcl.locationId);
			setSelectedLocation(vcl.location.name);
		}
	};

	useEffect(() => {
		let locationsArray: any[] = [];
		locationsArray = locations
			.filter((location: any) => location.parentId == null)
			.map((loc: any) => {
				return {
					label: loc.name,
					value: loc.id,
					checked: false,
					children:
						loc.childrenLocations === 0
							? []
							: locations
									.filter((childrenLocation: any) => childrenLocation.parentId === loc.id)
									.map((childLoc: any) => {
										return {
											label: childLoc.name,
											value: childLoc.id,
											checked: false
										};
									})
				};
			});
		setLocationsToSelect(locationsArray);
	}, [locations]);

	const locationChanged = (currentNode: any) => {
		const location = currentNode.value;
		const locationsArray = locationsToSelect.slice();
		locationsArray.forEach((loc: any) => {
			if (loc.value === location) {
				loc.checked = currentNode.checked;
			} else loc.checked = false;
			loc.children.forEach((childLoc: any) => {
				if (childLoc.value === location) {
					childLoc.checked = currentNode.checked;
				} else childLoc.checked = false;
			});
		});
		setLocationsToSelect(locationsArray);
		setLocation(currentNode.value);
		setSelectedLocation(currentNode.label);
	};

	const handleIsApplication = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsApplication(!isApplication);
		if (!isApplication) {
			setShowToken(true);
		} else {
			setShowToken(false);
		}
	};

	const handleTokenChange = (event: any) => {
		setToken(event.target.value);
	};

	const handleAuth0ScopeChange = (event: any) => {
		setAuth0Scope(event.target.value);
	};

	return (
		<Dialog fullScreen open={openAddRole} onClose={addRoleClosed} TransitionComponent={Transition}>
			<AppBar className={classes.addUserDialogAppBar}>
				<Toolbar>
					<IconButton aria-label='back' onClick={onCancelClicked}>
						<ArrowBackIcon className={classes.backIcon} />
					</IconButton>
					<Typography className={classes.addUserDialogTitle}>Add New Role</Typography>
					<Button
						onClick={onCancelClicked}
						size='small'
						variant='contained'
						color='default'
						classes={{
							root: classes.cancelBtnRoot,
							label: classes.cancelBtnTxt
						}}
					>
						Cancel
					</Button>
					<Button
						disabled={
							isEmpty(roleName.trim()) ||
							(isApplication && isEmpty(token.trim())) ||
							(!isApplication && isEmpty(auth0Scope.trim()))
						}
						onClick={onAddRoleClicked}
						size='small'
						color='secondary'
						variant='contained'
						classes={{
							root: classes.yesBtnRoot,
							label: classes.yesBtnTxt,
							disabled: classes.addUserBtnDisabled
						}}
					>
						Add Role
					</Button>
				</Toolbar>
			</AppBar>
			<DialogContent classes={{ root: classes.dialogContent }}>
				<RaBox>
					<div>
						<div>
							<div className={classes.addUserDialogItem}>
								<span className={classes.addUserItemLabel}>Role Name</span>
								<TextField
									id='outlined-helperText'
									variant='outlined'
									label='Role Name'
									size='small'
									onChange={handleRoleNameChange}
									value={roleName}
									inputProps={{
										style: {
											height: '26px'
										}
									}}
									required
								/>
							</div>
							<div className={classes.roleNameContainer}>
								<FormControlLabel
									className={classes.checkBoxApplication}
									control={
										<Checkbox
											checked={isApplication}
											onChange={handleIsApplication}
											name='isApplication'
											style={{
												marginLeft: '125px'
											}}
										/>
									}
									label='Application'
									labelPlacement='start'
								/>
							</div>
							{showToken ? (
								<div className={classes.addUserDialogItem}>
									<span className={classes.addUserItemLabel}>Token</span>
									<TextField
										id='outlined-helperText'
										variant='outlined'
										label='Token'
										size='small'
										onChange={handleTokenChange}
										value={token}
										inputProps={{
											style: {
												height: '26px'
											}
										}}
									/>
								</div>
							) : (
								<div className={classes.addUserDialogItem}>
									<span className={classes.addUserItemLabel}>Auth0 Scope</span>
									<TextField
										id='outlined-helperText'
										variant='outlined'
										label='Auth0 Scope'
										size='small'
										onChange={handleAuth0ScopeChange}
										value={auth0Scope}
										inputProps={{
											style: {
												height: '26px'
											}
										}}
									/>
								</div>
							)}
							<div className={classes.subtitleRolePermissions}>Select Permissions</div>
							<div className={classes.searchAnyFieldContainer}>
								<div className={classes.selectAllContainer}>
									<FormControlLabel
										className={classes.checkBoxAll}
										control={
											<Checkbox
												checked={isCheckAll}
												onChange={handleChangeAll}
												name='checkedAll'
												style={{
													marginLeft: '60px'
												}}
											/>
										}
										label='Select All'
									/>
									<FormControlLabel
										className={classes.checkBoxAll}
										control={
											<Checkbox
												checked={isCheckAllCreate}
												onChange={handleChangeAllCreate}
												name='checkedAllCreate'
												style={{
													marginLeft: '60px'
												}}
											/>
										}
										label='Select All Create'
									/>
									<FormControlLabel
										className={classes.checkBoxAll}
										control={
											<Checkbox
												checked={isCheckAllView}
												onChange={handleChangeAllView}
												name='checkedAllView'
												style={{
													marginLeft: '60px'
												}}
											/>
										}
										label='Select All View'
									/>
									<FormControlLabel
										className={classes.checkBoxAll}
										control={
											<Checkbox
												checked={isCheckAllEdit}
												onChange={handleChangeAllEdit}
												name='checkedAllEdit'
												style={{
													marginLeft: '60px'
												}}
											/>
										}
										label='Select All Edit'
									/>
									<FormControlLabel
										className={classes.checkBoxAll}
										control={
											<Checkbox
												checked={isCheckAllDelete}
												onChange={handleChangeAllDelete}
												name='checkedAllDelete'
												style={{
													marginLeft: '60px'
												}}
											/>
										}
										label='Select All Delete'
									/>
								</div>
								<div className={classes.searchAnyFieldBoxRoles}>
									{resourcesClean
										.sort((a: any, b: any) => b.name.localeCompare(a.name))
										.map((resource: any, index: number) => {
											return (
												<Box key={index} flex={12} className={classes.searchFieldBox}>
													<Box display='flex'>
														<Box flex={12}>
															<div className={classes.permissionsText}>
																<span className={classes.valueCodesItemLabel}>
																	{resource.label}
																</span>
															</div>
															<div className={classes.permissionsTextRole}>
																{resource.name === 'services' ? (
																	<div className={classes.addRoleDialogItem}>
																		<span className={classes.addRoleItemLabel}>Service</span>
																		<Filter
																			id='service_label'
																			label='Service'
																			value={serviceSelected}
																			onChange={serviceChanged}
																			labelWidth={10}
																		>
																			{services.map((service: any, index: number) => {
																				return (
																					<MenuItem
																						key={`services${index}`}
																						value={service.id}
																						selected={serviceSelected === service.id}
																					>
																						{service.name}
																					</MenuItem>
																				);
																			})}
																		</Filter>
																	</div>
																) : resource.name === 'valuecodelists' ? (
																	<div className={classes.addRoleDialogItem}>
																		<span className={classes.addRoleItemLabel}>
																			Value Code List
																		</span>
																		<Filter
																			id='valueCodeList_label'
																			label='Value Code List'
																			value={valueCodeListSelected}
																			onChange={valueCodeListChanged}
																			labelWidth={10}
																		>
																			{valueCodeLists.map((valueCodeList: any, index: number) => {
																				return (
																					<MenuItem
																						key={`valueCodeList${index}`}
																						value={valueCodeList.id}
																						selected={valueCodeListSelected === valueCodeList.id}
																					>
																						{valueCodeList.name}
																					</MenuItem>
																				);
																			})}
																		</Filter>
																	</div>
																) : resource.name === 'locations' ? (
																	<div className={classes.addRoleDialogItemLocations}>
																		<span className={classes.addRoleItemLabelLocations}>
																			Location
																		</span>
																		<DropdownTreeSelect
																			data={locationsToSelect}
																			mode='radioSelect'
																			className={classes.locationsSelect}
																			onChange={locationChanged}
																			texts={{ placeholder: 'Location' }}
																		/>
																	</div>
																) : (
																	''
																)}
																{resource.name === 'secrets' ? (
																	<FormGroup>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={isCheck.includes(`${resource.name}_view`)}
																					key={`checked${stringFirstCharUpperCase(
																						resource.name
																					)}View`}
																					id={`${resource.name}_view`}
																					onChange={handleChange}
																					name={`checked${stringFirstCharUpperCase(
																						resource.name
																					)}View`}
																				/>
																			}
																			label='View'
																		/>
																	</FormGroup>
																) : (
																	<div>
																		<FormGroup>
																			<FormControlLabel
																				control={
																					<Checkbox
																						checked={isCheck.includes(`${resource.name}_create`)}
																						key={`checked${stringFirstCharUpperCase(
																							resource.name
																						)}Create`}
																						id={`${resource.name}_create`}
																						onChange={handleChange}
																						name={`checked${stringFirstCharUpperCase(
																							resource.name
																						)}Create`}
																					/>
																				}
																				label='Create'
																			/>
																			<FormControlLabel
																				control={
																					<Checkbox
																						checked={isCheck.includes(`${resource.name}_view`)}
																						key={`checked${stringFirstCharUpperCase(
																							resource.name
																						)}View`}
																						id={`${resource.name}_view`}
																						onChange={handleChange}
																						name={`checked${stringFirstCharUpperCase(
																							resource.name
																						)}View`}
																					/>
																				}
																				label='View'
																			/>
																			<FormControlLabel
																				control={
																					<Checkbox
																						checked={isCheck.includes(`${resource.name}_edit`)}
																						key={`checked${stringFirstCharUpperCase(
																							resource.name
																						)}Edit`}
																						id={`${resource.name}_edit`}
																						onChange={handleChange}
																						name={`checked${stringFirstCharUpperCase(
																							resource.name
																						)}Edit`}
																					/>
																				}
																				label='Edit'
																			/>
																			<FormControlLabel
																				control={
																					<Checkbox
																						checked={isCheck.includes(`${resource.name}_delete`)}
																						key={`checked${stringFirstCharUpperCase(
																							resource.name
																						)}Delete`}
																						id={`${resource.name}_delete`}
																						onChange={handleChange}
																						name={`checked${stringFirstCharUpperCase(
																							resource.name
																						)}Delete`}
																					/>
																				}
																				label='Delete'
																			/>
																		</FormGroup>
																	</div>
																)}
															</div>
														</Box>
													</Box>
												</Box>
											);
										})}
								</div>
							</div>
						</div>
					</div>
				</RaBox>
			</DialogContent>
		</Dialog>
	);
};
