import React, { useEffect, useState } from 'react';
import { HeaderValueCodeList } from './HeaderValueCodeList';
import { ValueCodeListsFilters } from '../valueCodeLists/ValueCodeListsFilters';
import { ValueCodeLists } from '../valueCodeLists/ValueCodeLists';
import { useDataProvider, downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

function ValueCodeList(props: any) {
	const dataProvider = useDataProvider();
	const [services, setServices] = useState<any>([]);
	const [locations, setLocations] = useState<any>([]);
	const [search, searchInput] = React.useState('');
	const [filter, setFilter] = useState<any>({
		status: 'all'
	});

	useEffect(() => {
		setServices(JSON.parse(localStorage.getItem('cscvc_filterServices') as string));
		setLocations(JSON.parse(localStorage.getItem('cscvc_filterLocations') as string));
	}, []);

	const onExports = () => {
		dataProvider
			.getList(`valuecodelists`, {
				filter,
				sort: { field: 'createdOn', order: 'ASC' },
				pagination: { page: 1, perPage: 1000000 }
			})
			.then((valueCodeLists: any) => {
				const listsForExport = valueCodeLists.data.map((list: any) => ({
					Id: list.id,
					Name: list.name,
					Service: list.service?.name,
					Location: list.location?.name,
					CreatedOn: list.createdOn,
					CreatedBy: list.createdBy,
					TotalCodes: list.totalCodes,
					settingsJSON: list.settingsJSON,
					Active: list.active
				}));
				jsonExport(
					listsForExport,
					{
						headers: []
					},
					// eslint-disable-next-line n/handle-callback-err
					(err, csv) => {
						downloadCSV(csv, 'valuecodelists');
					}
				);
			});
	};

	return (
		<>
			<HeaderValueCodeList
				searchInput={searchInput}
				idSearch='branchName'
				showSearch={true}
				locations={locations}
				services={services}
				onExports={onExports}
				{...props}
			/>
			<ValueCodeListsFilters
				locations={locations}
				services={services}
				filter={filter}
				setFilter={setFilter}
			/>
			<ValueCodeLists {...props} search={search} filter={filter} />
		</>
	);
}

export default ValueCodeList;
