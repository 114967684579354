import * as React from 'react';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from 'ra-core';

import { MenuItemLink, getResources, ReduxState } from 'react-admin';

import DefaultIcon from '@material-ui/icons/Label';
import { useStyles } from './MyMenuStyles';

/**
 * Custom side-navigation (viewport left)
 * @param onMenuClick
 * @constructor
 */
const MyMenu = ({ onMenuClick }: any) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const resources: Array<unknown> = useSelector(getResources);

	useEffect(() => {
		if (open) dispatch(toggleSidebar());
	}, []);
	const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);

	return (
		<div id='my_menu' className={classes.myMenu}>
			{resources.map((resource: any) => {
				return resource.name !== 'currencytypes' &&
					resource.name !== 'webhooks' &&
					resource.name !== 'roles' &&
					resource.name !== 'valuestores' ? (
					<MenuItemLink
						className={classes.root}
						activeClassName={classes.active}
						classes={classes}
						key={resource.name}
						to={`/${resource.name}`}
						primaryText={(resource.options && resource.options.label) || resource.name}
						leftIcon={
							resource.icon ? (
								<resource.icon
									id={'menu_icon' + resource.name}
									className={
										resource.name === 'valuecodelists' || resource.name === 'services'
											? classes.iconFill
											: classes.icon
									}
								/>
							) : (
								<DefaultIcon id={'menu_icon_' + resource.name} />
							)
						}
						onClick={onMenuClick}
						sidebarIsOpen={false}
						id={'menu_' + resource.name}
					/>
				) : (
					''
				);
			})}
		</div>
	);
};

export default MyMenu;
