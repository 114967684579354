import React, { useState } from 'react';
import { createStyles, makeStyles, Typography, Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useRedirect } from 'react-admin';
import { AddServiceDialog } from '../services/Dialogs/AddServiceDialog';
import { AddLocationDialog } from '../locations/Dialogs/AddLocationDialog';
import { AddUserDialog } from '../permissions/Dialogs/AddUserDialog';
import { AddRoleDialog } from '../permissions/Dialogs/AddRoleDialog';
import uiColor from '../../utils/utilsColors';
import { hasCreate } from '../../utils/permissions';

const useStyles = makeStyles(() =>
	createStyles({
		showTitle: {
			width: 'auto',
			marginTop: '8px',
			marginLeft: 0,
			background: '#FFFFFF'
		},
		secondaryTitle: {
			display: 'flex',
			justifyContent: 'space-between',
			borderBottom: '1px solid #E0E0E0',
			marginBottom: '30px',
			paddingBottom: '15px'
		},
		secondaryTitlePermissions: {
			borderBottom: '1px solid #E0E0E0',
			marginBottom: '30px',
			paddingBottom: '15px'
		},
		titleBackBranchList: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			paddingTop: '25px',
			color: '#969696',
			'&:hover': {
				cursor: 'pointer'
			}
		},
		breadcrumbElement: {
			color: 'gray',
			display: 'flex',
			alignItems: 'center',
			paddingLeft: '40px',
			paddingBottom: '10px',
			borderBottom: '1px solid #E0E0E0'
		},
		colorId: {
			color: 'black'
		},
		breadcrumbBase: {
			fontSize: '44px',
			fontWeight: 800,
			lineHeight: '113%',
			color: '#232F64',
			letterSpacing: '-1px',
			marginLeft: '40px',
			marginTop: '21px'
		},
		divider: {
			borderLeft: '1px solid lightgrey',
			paddingLeft: '15px',
			color: 'grey',
			display: 'flex',
			flexDirection: 'column'
		},
		dividerItems: {
			flex: 1
		},
		dividerSpace: {
			paddingRight: '25px'
		},
		navigationIcon: {
			position: 'relative',
			top: '20px',
			margin: '0px 5px 0px 6px',
			color: '#BDBDBD'
		},
		searchResultsTitle: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#5F5F5F',
			marginTop: '33px'
		},
		filter: {
			position: 'absolute',
			right: '30px',
			top: '78px'
		},
		createListButton: {
			position: 'absolute',
			right: '40px',
			marginTop: '98px'
		},
		newBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '8px, 16px, 8px, 12px',
			borderRadius: '8px',
			width: '169px',
			height: '40px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		newBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		exportBtnContainer: {
			position: 'absolute',
			right: '30px',
			top: '23px'
		},
		exportBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '1px 0px',
			borderRadius: '8px',
			width: '129px',
			height: '36px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		exportBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		fieldInBlue: {
			color: uiColor.secondary,
			fontWeight: 'bold',
			letterSpacing: '-0.5px',
			cursor: 'pointer',
			marginLeft: '40px',
			fontSize: '17px'
		},
		fieldInGrey: {
			color: uiColor.inactive,
			fontWeight: 'bold',
			letterSpacing: '-0.5px',
			cursor: 'pointer',
			marginLeft: '40px',
			fontSize: '17px'
		},
		optionsPermissions: {
			marginTop: '25px'
		}
	})
);

export function HeaderList(props: any) {
	const classes = useStyles();
	const history = useHistory();
	const redirect = useRedirect();
	const [openAddServiceDialog, setOpenAddServiceDialog] = useState(false);

	const [openAddLocationDialog, setOpenAddLocationDialog] = useState(false);

	const [openAddUserDialog, setOpenAddUserDialog] = useState(false);

	const [openAddRoleDialog, setOpenAddRoleDialog] = useState(false);

	const addServiceClicked = () => {
		setOpenAddServiceDialog(true);
	};

	const addServiceDialogClosed = () => {
		setOpenAddServiceDialog(false);
	};

	const addLocationClicked = () => {
		setOpenAddLocationDialog(true);
	};

	const addLocationDialogClosed = () => {
		setOpenAddLocationDialog(false);
	};

	const addUserClicked = () => {
		setOpenAddUserDialog(true);
	};

	const addUserDialogClosed = () => {
		setOpenAddUserDialog(false);
	};

	const addRoleClicked = () => {
		setOpenAddRoleDialog(true);
	};

	const addRoleDialogClosed = () => {
		setOpenAddRoleDialog(false);
	};

	const renderAddButton = () => {
		if (props.resource === 'services') {
			if (hasCreate(null, props.resource)) {
				return (
					<Button
						variant='contained'
						size='small'
						startIcon={<AddIcon />}
						onClick={addServiceClicked}
						classes={{
							root: classes.newBtnRoot,
							label: classes.newBtnTxt
						}}
					>
						New Service
					</Button>
				);
			}
		} else if (props.resource === 'locations') {
			if (hasCreate(null, props.resource)) {
				return (
					<Button
						variant='contained'
						size='small'
						startIcon={<AddIcon />}
						onClick={addLocationClicked}
						classes={{
							root: classes.newBtnRoot,
							label: classes.newBtnTxt
						}}
					>
						New Location
					</Button>
				);
			}
		} else if (props.resource === 'users') {
			if (hasCreate(null, props.resource)) {
				return (
					<Button
						variant='contained'
						size='small'
						startIcon={<AddIcon />}
						onClick={addUserClicked}
						classes={{
							root: classes.newBtnRoot,
							label: classes.newBtnTxt
						}}
					>
						Invite User
					</Button>
				);
			}
		} else if (props.resource === 'roles') {
			if (hasCreate(null, 'users')) {
				return (
					<Button
						variant='contained'
						size='small'
						startIcon={<AddIcon />}
						onClick={addRoleClicked}
						classes={{
							root: classes.newBtnRoot,
							label: classes.newBtnTxt
						}}
					>
						Create Role
					</Button>
				);
			}
		}
	};

	const goToUsers = () => {
		redirect('/users');
	};

	const goToRoles = () => {
		redirect('/roles');
	};

	return (
		<>
			<div className={classes.showTitle}>
				<Typography variant='body1' noWrap className={classes.breadcrumbElement}>
					<span
						className={classes.titleBackBranchList}
						onClick={() => {
							history.push('/' + props.resource);
						}}
					>
						{props.resource === 'valuecodelists'
							? 'Value Codes Lists'
							: props.resource === 'valuecodes'
							? 'Value Codes'
							: props.resource === 'services'
							? 'Services'
							: props.resource === 'locations'
							? 'Locations'
							: props.resource === 'transactions'
							? 'Transactions'
							: props.resource === 'consumers'
							? 'Consumers'
							: props.resource === 'users'
							? 'Permissions'
							: props.resource === 'roles'
							? 'Permissions'
							: ''}
					</span>
					<span className={classes.colorId}>{props.resource !== 'roles' ? props.id : ''}</span>
					<span
						style={{
							display:
								props.resource === 'consumers' || props.resource === 'valuecodes'
									? props.showResults
										? 'none'
										: 'none'
									: 'none'
						}}
					>
						<NavigateNextIcon fontSize='small' className={classes.navigationIcon} />
					</span>
					{props.resource === 'transactions' && (
						<div className={classes.exportBtnContainer}>
							<Button
								variant='contained'
								size='small'
								startIcon={<GetAppIcon />}
								onClick={props.onExports}
								classes={{
									root: classes.exportBtnRoot,
									label: classes.exportBtnTxt
								}}
							>
								Export
							</Button>
						</div>
					)}
				</Typography>

				<div
					className={
						props.resource === 'users' || props.resource === 'roles'
							? classes.secondaryTitlePermissions
							: classes.secondaryTitle
					}
				>
					<Typography noWrap className={classes.breadcrumbBase}>
						{props.resource === 'valuecodelists'
							? 'Value Codes Lists'
							: props.resource === 'valuecodes'
							? 'Value Codes'
							: props.resource === 'services'
							? 'Services'
							: props.resource === 'transactions'
							? 'Transactions'
							: props.resource === 'locations'
							? 'Locations'
							: props.resource === 'consumers'
							? 'Consumers'
							: props.resource === 'users'
							? 'Permissions'
							: props.resource === 'roles'
							? 'Permissions'
							: ''}
					</Typography>
					{(props.resource === 'users' || props.resource === 'roles') && (
						<div className={classes.optionsPermissions}>
							<span
								onClick={() => goToUsers()}
								className={props.resource === 'users' ? classes.fieldInBlue : classes.fieldInGrey}
							>
								USERS
							</span>
							<span
								onClick={() => goToRoles()}
								className={props.resource === 'roles' ? classes.fieldInBlue : classes.fieldInGrey}
							>
								ROLES
							</span>
						</div>
					)}
				</div>

				{props.children}
			</div>
			<div className={classes.createListButton}>{renderAddButton()}</div>
			{/* add value code list full-screen dialog */}
			{props.resource === 'services' && (
				<AddServiceDialog
					openAddService={openAddServiceDialog}
					addServiceClosed={addServiceDialogClosed}
				/>
			)}
			{props.resource === 'locations' && (
				<AddLocationDialog
					openAddLocation={openAddLocationDialog}
					addLocationClosed={addLocationDialogClosed}
				/>
			)}
			{props.resource === 'users' && (
				<AddUserDialog openAddUser={openAddUserDialog} addUserClosed={addUserDialogClosed} />
			)}
			{props.resource === 'roles' && (
				<AddRoleDialog openAddRole={openAddRoleDialog} addRoleClosed={addRoleDialogClosed} />
			)}
		</>
	);
}
