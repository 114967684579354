import * as React from 'react';
import { forwardRef } from 'react';
import { ViewTransactionProps } from './DialogsProps';
import { Typography, Dialog, Slide, AppBar, Toolbar, IconButton, Divider } from '@material-ui/core';
import useStyles from '../../valueCodeLists/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TransitionProps } from '@material-ui/core/transitions';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export const ViewTransactionDialog = (
	{ transaction, openTransactionDialog, transactionDialogClosed }: ViewTransactionProps,
	props: any
) => {
	const classes = useStyles();
	const history = useHistory();

	const goToLocation = (locationId: any) => {
		history.push(`locations/${locationId}/show`);
	};

	const goToService = (serviceId: any) => {
		history.push(`services/${serviceId}/show`);
	};

	const goToValueCode = (humanReadableId: any) => {
		history.push(`valuecodes/${humanReadableId}/show`);
	};

	if (transaction != null) {
		return (
			<Dialog
				fullScreen
				open={openTransactionDialog}
				onClose={transactionDialogClosed}
				TransitionComponent={Transition}
			>
				<AppBar className={classes.addRoomDialogAppBar}>
					<Toolbar>
						<IconButton aria-label='back' onClick={transactionDialogClosed}>
							<ArrowBackIcon className={classes.backIcon} />
						</IconButton>
						<Typography className={classes.viewValueCodeDialogTitle}>Transaction Detail</Typography>
						<Divider orientation='vertical' className={classes.divider2} />
						<div className={classes.viewValueCodeDialogSubtitle}>
							{transaction.valueCode?.humanReadableId}
						</div>
					</Toolbar>
				</AppBar>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}>Transaction Information</div>
					<span className={classes.valueCodesItemLabel}>UUID</span>
					<span className={classes.valueCodesItemText}>{transaction.id}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Value Store ID</span>
					<span className={classes.valueCodesItemText}>{transaction.valueStoreId}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Human Readable ID</span>
					<span
						onClick={() => goToValueCode(transaction.valueCode?.humanReadableId)}
						className={classes.valueCodesItemTextBlue}
					>
						{transaction.valueCode?.humanReadableId}
					</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>AS400ID</span>
					<span className={classes.valueCodesItemText}>{transaction.aS400ID}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Service</span>
					<span
						onClick={() => goToService(transaction.service?.id)}
						className={classes.valueCodesItemTextBlue}
					>
						{transaction.service?.name}
					</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Location</span>
					<span
						onClick={() => goToLocation(transaction.location?.id)}
						className={classes.valueCodesItemTextBlue}
					>
						{transaction.location?.name}
					</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Amount</span>
					<span className={classes.valueCodesItemText}>
						{Number(transaction.amount).toFixed(2)}
					</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Transaction Type</span>
					<span className={classes.valueCodesItemText}>{transaction.transactionType}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Currency Type</span>
					<span className={classes.valueCodesItemText}>{transaction.currencyType?.name}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Transaction Date</span>
					<span className={classes.valueCodesItemText}>
						{moment(transaction.transactionDate).format('MM/DD/YYYY h:mm A')}
					</span>
				</div>
			</Dialog>
		);
	} else return null;
};
