import * as React from 'react';
import { useState } from 'react';

import {
	Button,
	Toolbar,
	Typography,
	Menu,
	MenuItem,
	makeStyles,
	createStyles
} from '@material-ui/core';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useListContext } from 'react-admin';

const useStyles = makeStyles(() =>
	createStyles({
		toolbarRoot: {
			display: 'flex',
			justifyContent: 'center',
			width: 'auto',
			position: 'relative'
		},
		pageNotes: {
			position: 'absolute',
			left: '24px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#5F5F5F'
		},
		pageNumbers: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#5F5F5F'
		},
		paginationPerpage: {
			position: 'absolute',
			right: '0px',
			display: 'flex'
		},
		rowsPerPage: {
			position: 'relative',
			top: '5px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 500,
			color: '#5F5F5F'
		},
		rowsPerPageBtnRoot: {
			position: 'relative',
			top: '-4px'
		},
		calculatingContainer: {
			display: 'flex',
			justifyContent: 'center',
			width: 'auto',
			marginTop: '134px',
			marginBottom: '535px'
		},
		calculatingTxt: {
			fontWeight: 500,
			color: '#5F5F5F'
		}
	})
);

interface CustomPaginationProps {
	defaultPerPage?: number;

	/**
	 * @param total
	 */
	setTotal?: (total: number) => void;

	/**
	 * @param curPage
	 */
	setCurPage?: (curPage: number) => void;

	/**
	 * @param perPage
	 */
	setPerPage?: (perPage: number) => void;
}

/**
 * Renders pagination UI
 * @param props
 * @constructor
 */
const CustomPagination = (props: CustomPaginationProps) => {
	const classes = useStyles();
	const { page, perPage, total, setPage, setPerPage, loading } = useListContext();

	const nbPages = Math.ceil(total / perPage) || 1;
	const perPagesArr = [10, 20, 50];

	const [selectedIndex, setSelectedIndex] = useState(0);
	const [anchorElPerPagesMenu, setAanchorElPerPagesMenu] = useState<null | HTMLElement>(null);

	React.useEffect(() => {
		setPerPage(perPage);
		// display results number for Consumers search results
		const consumersSearchResultsNode = document.getElementById('consumers_search_results');
		if (consumersSearchResultsNode) {
			consumersSearchResultsNode.innerHTML = 'Search Results (' + total + ')';
		}

		// display 0 Results for Consumer Profile
		if (props.setTotal) {
			props.setTotal(total);
		}

		if (props.defaultPerPage) {
			let defaultSelectedIdx = 0;
			perPagesArr.forEach((page: number, index: number) => {
				if (props.defaultPerPage === page) {
					defaultSelectedIdx = index;
				}
			});
			setSelectedIndex(defaultSelectedIdx);
		}
	}, [total]);

	const perPageBtnClicked = (event: React.MouseEvent<HTMLElement>) => {
		setAanchorElPerPagesMenu(event.currentTarget);
	};

	const perPagesMenuClosed = () => {
		setAanchorElPerPagesMenu(null);
	};

	const perPagesMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
		setSelectedIndex(index);
		setPerPage(perPagesArr[index]);
		if (props.setPerPage) props.setPerPage(perPagesArr[index]); // for export
		setAanchorElPerPagesMenu(null);
	};

	if (loading && total === 0) {
		return (
			<Toolbar
				classes={{
					root: classes.calculatingContainer
				}}
			>
				<Typography className={classes.calculatingTxt}>Calculating...</Typography>
			</Toolbar>
		); // Not show pagination if not results
	}

	if (total === 0) {
		return null;
	}

	return (
		<Toolbar
			classes={{
				root: classes.toolbarRoot
			}}
		>
			<Typography className={classes.pageNotes}>
				{`showing ${perPage * page < total ? perPage * page : total} of ${total}`}
			</Typography>

			<Button
				color='secondary'
				key='prev'
				disabled={page <= 1}
				onClick={() => {
					setPage(page - 1);
					if (props.setCurPage) props.setCurPage(page - 1); // for export
				}}
			>
				<ChevronLeft />
			</Button>

			<Typography className={classes.pageNumbers}>{page + ' of ' + nbPages}</Typography>

			<Button
				color='secondary'
				key='next'
				disabled={page >= nbPages}
				onClick={() => {
					setPage(page + 1);
					if (props.setCurPage) props.setCurPage(page + 1); // for export
				}}
			>
				<ChevronRight />
			</Button>

			<div className={classes.paginationPerpage}>
				<Typography className={classes.rowsPerPage}>Rows per page:</Typography>

				<Button
					color='inherit'
					endIcon={<ArrowDropDownIcon />}
					onClick={perPageBtnClicked}
					classes={{
						root: classes.rowsPerPageBtnRoot
					}}
				>
					{perPagesArr[selectedIndex]}
				</Button>
				<Menu
					id='events_rooms_menu'
					anchorEl={anchorElPerPagesMenu}
					keepMounted
					open={Boolean(anchorElPerPagesMenu)}
					onClose={perPagesMenuClosed}
				>
					{perPagesArr.map((perPage: any, index: number) => (
						<MenuItem
							key={index}
							selected={index === selectedIndex}
							onClick={(event) => perPagesMenuItemClick(event, index)}
						>
							{perPage}
						</MenuItem>
					))}
				</Menu>
			</div>
		</Toolbar>
	);
};

export default CustomPagination;
