import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listContainer: {
			width: 'auto',
			marginLeft: 0,
			borderTop: '1px solid #E2E3E9',
			background: '#F2F4FB'
		},
		listRoot: {
			margin: '24px 40px 0px 40px'
		},
		listContent: {
			boxShadow: '0 0 0 white',
			background: '#F2F4FB'
		},
		table: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			background: '#FFFFFF',
			minWidth: '980px'
		},
		thead: {
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px'
		},
		tbody: {
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px'
		},
		headerCell: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#111111',
			padding: '20px 24px',
			width: '200px',
			'&:first-child': {
				width: '400px'
			},
			'&:nth-child(4)': {
				width: '100px'
			},
			'&:nth-child(5)': {
				width: '250px'
			},
			'&:nth-child(6)': {
				width: '250px'
			},
			'&:last-child': {
				width: '100px'
			}
		},
		tableCell: {
			paddingTop: '16px',
			paddingBottom: '16px',
			borderRight: '1px solid #EEEFF3',
			paddingLeft: '24px'
		},
		rowOdd: {
			backgroundColor: '#F8F9FA'
		},
		filtersContainer: {
			marginLeft: '40px'
		},
		fieldInBlue: {
			color: '#1695CB',
			fontWeight: 'bold',
			letterSpacing: '-0.5px',
			cursor: 'pointer'
		}
	})
);

export default useStyles;
