export const CODES_STATUS = [
	{
		id: 'all',
		description: 'All'
	},
	{
		id: 'active',
		description: 'Active'
	},
	{
		id: 'inactive',
		description: 'Inactive'
	}
];

export const CODES_EXPIRED = [
	{
		id: 'all',
		description: 'All'
	},
	{
		id: 'true',
		description: 'True'
	},
	{
		id: 'false',
		description: 'False'
	}
];
