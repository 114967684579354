import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, MenuItem, SvgIcon, Button } from '@material-ui/core';
import { Filter } from '../services/Filter';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { SearchInput, Filter as SearchFilter } from 'react-admin';

export const LocationsFilters = ({ setFilter, filter, view }: any, props: any) => {
	const classes = useStyles();
	const [statusSelected, setStatusSelected] = useState('all');
	const [status] = useState<any>([
		{ id: 'all', description: 'All' },
		{ id: 'active', description: 'Active' },
		{ id: 'inactive', description: 'Inactive' }
	]);
	const [filterChanged, setFilterChanged] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [search, searchInput] = useState<Object | null>({});

	useEffect(() => {
		if (filter?.status) {
			setStatusSelected(filter.status);
		}
		if (
			(filter?.status === undefined || filter?.status === 'all') &&
			(filter?.name === undefined || filter?.name === 'all')
		) {
			setFilterChanged(false);
		} else {
			setFilterChanged(true);
		}
	}, [filter]);

	const statusChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const status = event.target.value as string;
		if (status !== 'all') {
			setFilter({ ...filter, status });
		} else {
			setFilter({ ...filter, status: undefined });
		}
		setStatusSelected(status);
	};

	const resetFilterLocations = () => {
		setStatusSelected('all');
		setFilter({ parent: true, status: 'all' });
	};

	const onSearchChange = (e: any) => {
		const value = e?.target?.value || '';
		setSearchText(value);
		if (value && value.length > 2) {
			searchInput({
				search: { query: value, fields: ['name'] }
			});
		} else {
			searchInput(null);
		}
	};

	useEffect(() => {
		if (search !== null) {
			setFilter({ ...filter, ...search });
		} else {
			setFilter({ ...filter, search: undefined });
		}
	}, [search]);

	useEffect(() => {
		if (filter?.$text) {
			setSearchText(filter?.$text);
		}
	}, [searchText, filter?.$text]);

	return (
		<div className={classes.eventsToolbar}>
			<div className={classes.filterPosition}>
				<SearchFilter
					{...props}
					hideFilter={false}
					className={view === 'list' ? classes.filter : classes.filterDetail}
					filterValues={[]}
					setFilters={() => []}
				>
					<SearchInput
						variant='outlined'
						size='small'
						source='locations-id'
						alwaysOn
						placeholder='Search'
						className={classes.input}
						onChange={onSearchChange}
						options={{
							value: searchText
						}}
					/>
				</SearchFilter>
				<Filter
					id='status_label'
					label='Status'
					value={statusSelected}
					onChange={statusChanged}
					labelWidth={55}
				>
					{status.map((statusEle: any, index: number) => {
						return (
							<MenuItem
								key={`status_${index}`}
								value={statusEle.id}
								selected={statusSelected === statusEle.id}
							>
								{statusEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				{filterChanged && (
					<Button
						classes={{
							root: classes.btn,
							text: classes.btnText
						}}
						onClick={resetFilterLocations}
						endIcon={
							<SvgIcon component={CloseIcon} viewBox='0 0 24 24' className={classes.svgIcon} />
						}
					>
						Reset All Filters
					</Button>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		eventsToolbar: {
			display: 'flex',
			padding: '12px 0px 22px 0px',
			backgroundColor: '#FFFFFF'
		},
		filterPosition: {
			display: 'flex',
			'& MuiFormControl-root': {
				height: '48px'
			},
			'& .MuiOutlinedInput-root': {
				height: '100%'
			},
			'& .react-datepicker-popper': {
				zIndex: 2
			}
		},
		btn: {
			textTransform: 'none',
			marginLeft: '16px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			borderRadius: '8px',
			marginTop: '-27px'
		},
		btnText: {
			padding: '6px 14px 6px 8px'
		},
		svgIcon: {
			width: '13px',
			height: '13px'
		},
		filter: {
			backgroundColor: '#ffffff',
			'& .filter-field': {
				margin: '25px -16px 24px 38px',
				height: '43px',
				width: '250px'
			}
		},
		filterDetail: {
			backgroundColor: '#ffffff',
			'& .filter-field': {
				margin: '25px -16px 24px 0px',
				height: '43px',
				width: '250px'
			}
		},
		input: {
			'& input': {
				paddingTop: '15px',
				paddingBottom: '15px'
			}
		}
	})
);
