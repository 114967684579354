import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { format, getTime } from 'date-fns';
import CustomDatePickerHeader from './CustomHeader';
import CustomContainer from './CustomContainer';
import useStyles from './useStyle';
import CustomInput from './CustomInput';
import { DEFAULT_FORMAT_DATE, ALL, TODAY } from './constants';
import { CustomDatePickerProps } from './CustomDatePickerProps';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = (props: CustomDatePickerProps) => {
	const { ts, defaultValue } = props;
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [formattedDate, setFormattedDate] = useState(ALL);
	const today = new Date();

	const changeToDefaultValues = () => {
		setStartDate(null);
		setEndDate(null);
		setFormattedDate(props.defaultValue || ALL);
		if (props.setDatesPickerValue) {
			props.setDatesPickerValue(props.defaultValue || ALL);
		} else if (props.setFilterSearch) {
			if (props.filterSearch) {
				// restore Search Any Field results
				const newFilter = JSON.parse(props.filterSearch);
				delete newFilter[props.fieldKey as string];
				props.setFilterSearch(newFilter);
			} else {
				// for Activity of Consumer Profile
				props.setFilterSearch(null);
			}
		}
	};

	useEffect(() => {
		if (defaultValue === TODAY) {
			const today: any = new Date();
			setStartDate(today);
			setEndDate(today);
			setFormattedDate(format(today, DEFAULT_FORMAT_DATE));
		} else if (ts) {
			const { gte, lt } = props.ts;
			setStartDate(gte);
			setEndDate(lt);
			const newLt = new Date(lt);
			newLt.setDate(newLt.getDate() - 1);
			const formatEndDate = format(newLt, DEFAULT_FORMAT_DATE);
			const formatStartDate = format(gte, DEFAULT_FORMAT_DATE);
			if (formatEndDate !== formatStartDate) {
				setFormattedDate(`${formatStartDate}-${formatEndDate}`);
			} else {
				setFormattedDate(formatStartDate);
			}
		} else {
			changeToDefaultValues();
		}
	}, [ts, defaultValue]);

	const onChange = (dates: any) => {
		if (!Array.isArray(dates)) {
			changeToDefaultValues();
		} else {
			const [start, end] = dates;
			setStartDate(start);
			setEndDate(end);
			let formatDate = format(start, DEFAULT_FORMAT_DATE);
			let timestampStartDate = `${getTime(start)}`;
			if (end) {
				const formatEndDate = format(end, DEFAULT_FORMAT_DATE);
				if (formatDate !== formatEndDate) {
					formatDate = `${formatDate} - ${formatEndDate}`;
				}
				timestampStartDate = `${timestampStartDate} - ${getTime(end)}`;
				setOpen(false);
			}
			setFormattedDate(formatDate);
			if (props.setDatesPickerValue) {
				props.setDatesPickerValue(timestampStartDate);
			} else if (props.fieldKey && props.setFilterSearch && end) {
				const newEnd = new Date(getTime(end) + 1000 * 3600 * 24);
				if (props.filterSearch) {
					// restore Search Any Field results
					const newFilter = JSON.parse(props.filterSearch);
					newFilter[props.fieldKey as string] = {
						gte: start,
						lt: newEnd
					};
					props.setFilterSearch(newFilter);
				} else {
					// for Activity of Consumer Profile
					props.setFilterSearch({
						[props.fieldKey]: {
							gte: start,
							lt: newEnd
						}
					});
				}
			}
		}
	};

	return (
		<>
			<DatePicker
				className={classes.wrapper}
				calendarClassName={classes.calendar}
				renderCustomHeader={CustomDatePickerHeader}
				selected={startDate}
				dateFormat={DEFAULT_FORMAT_DATE}
				startDate={startDate}
				endDate={endDate}
				value={formattedDate}
				onChange={onChange}
				onInputClick={() => setOpen(true)}
				onClickOutside={() => setOpen(false)}
				open={open}
				selectsRange
				isClearable
				calendarContainer={CustomContainer}
				maxDate={today}
				customInput={<CustomInput label={props.label} labelWidth={props.labelWidth} />}
			/>
		</>
	);
};

export default CustomDatePicker;
