import keyBy from 'lodash/keyBy';
import { useState, useEffect } from 'react';
import { useQuery, TextField, Loading, DateField, List } from 'react-admin';
import { EmptyGrid } from '../common/EmptyGrid';
import { MyDatagrid } from '../common/myDataGrid/MyDatagrid';
import useStyles from './tableStyles';
import CustomPagination from '../common/PaginationCustom';

export default function CustomTransactionList(props: any) {
	const classes = useStyles();
	const [page] = useState(1);
	const [perPage] = useState(25);
	const [sort] = useState({ field: 'transactionDate', order: 'DESC' });
	const { data, loading, error } = useQuery({
		type: 'getList',
		resource: `valuestores/${props.valueStoreId !== '' ? props.valueStoreId : null}/transactions`,
		payload: {
			pagination: { page, perPage },
			sort,
			filter: props.filter
		}
	});
	const correctProps = {
		basePath: '/transactions',
		hasShow: true,
		resource: 'transactions',
		filter: props.filter
	};

	useEffect(() => {}, [props, data]);

	const Amount = (props: any) => {
		return <span>{props.record.amount}</span>;
	};

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <p>ERROR: {error}</p>;
	}
	if (data.length === 0) {
		return <EmptyGrid />;
	} else {
		return (
			<>
				<List
					{...correctProps}
					bulkActionButtons={false}
					empty={<EmptyGrid />}
					sort={sort}
					filter={{ ...props.filter }}
					pagination={<CustomPagination />}
					actions={false}
					perPage={10}
					classes={{
						root: classes.listRoot,
						content: classes.listContent
					}}
				>
					<MyDatagrid
						data={keyBy(data, 'id')}
						ids={data.map(({ id }: any) => id)}
						currentSort={sort}
						classes={{
							table: classes.table,
							thead: classes.thead,
							tbody: classes.tbody,
							headerCell: classes.headerCell,
							rowCell: classes.tableCell,
							rowOdd: classes.rowOdd
						}}
					>
						<DateField source='transactionDate' label='Transaction Date' showTime />
						<TextField source='service.name' label='Service' />
						<TextField source='location.name' label='Location' />
						<TextField source='aS400ID' label='AS400ID' />
						<TextField source='valueCode.humanReadableId' label='Human Readable ID' />
						<TextField source='transactionType' label='Transaction Type' />
						<TextField source='currencyType' label='Currency Type' />
						<Amount label='Amount' />
					</MyDatagrid>
				</List>
			</>
		);
	}
}
