import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, MenuItem, SvgIcon, Button } from '@material-ui/core';
import { Filter } from './Filter';
import CustomDatePicker from '../common/customDatePicker/CustomDatePicker';
import { LISTS_STATUS, LISTS_TOTALCODES } from './values';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { SearchInput, Filter as SearchFilter } from 'react-admin';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';

export const ValueCodeListsFilters = (
	{ setFilter, filter, locations, services }: any,
	props: any
) => {
	const classes = useStyles();

	const [statusSelected, setStatusSelected] = useState('all');
	const [serviceSelected, setServiceSelected] = useState('all');
	const [totalCodesSelected, setTotalCodesSelected] = useState('all');
	const [status] = useState<any>(LISTS_STATUS);
	const [totalCodes] = useState<any>(LISTS_TOTALCODES);
	const [filterChanged, setFilterChanged] = useState(false);

	const [searchText, setSearchText] = useState('');
	const [search, searchInput] = useState<Object | null>({});
	const [locationsToSelect, setLocationsToSelect] = useState<any>([]);

	useEffect(() => {
		if (filter?.status) {
			setStatusSelected(filter.status);
		}
		if (filter?.totalCodes) {
			setTotalCodesSelected(filter.totalCodes);
		}
		if (
			(filter?.status === undefined || filter?.status === 'all') &&
			(filter?.totalCodes === undefined || filter?.totalCodes === 'all') &&
			(filter?.locations === undefined || filter?.locations.length === 0) &&
			(filter?.service === undefined || filter?.service === 'all') &&
			filter?.dateCreated === undefined &&
			filter?.lastUpdated === undefined
		) {
			setFilterChanged(false);
		} else {
			setFilterChanged(true);
		}
	}, [filter]);
	const statusChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const status = event.target.value as string;
		if (status !== 'all') {
			setFilter({ ...filter, status });
		} else {
			setFilter({ ...filter, status: undefined });
		}
		setStatusSelected(status);
	};

	const totalCodesChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const totalCodes = event.target.value as string;
		if (totalCodes !== 'all') {
			setFilter({ ...filter, totalCodes });
		} else {
			setFilter({ ...filter, totalCodes: undefined });
		}
		setTotalCodesSelected(totalCodes);
	};

	const locationChanged = (currentNode: any, selectedNodes: any) => {
		const location = currentNode.value;
		const locationsArray = locationsToSelect.slice();
		locationsArray.forEach((loc: any) => {
			if (loc.value === location) {
				loc.checked = currentNode.checked;
			}
			loc.children.forEach((childLoc: any) => {
				if (childLoc.value === location) {
					childLoc.checked = currentNode.checked;
				}
			});
		});
		setLocationsToSelect(locationsArray);
		setFilter({ ...filter, locations: selectedNodes.map((loc: any) => loc.value) });
	};

	const serviceChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const service = event.target.value as string;
		if (service !== 'all') {
			setFilter({ ...filter, service });
		} else {
			setFilter({ ...filter, service: undefined });
		}
		setServiceSelected(service);
	};

	const refundsFilterOpened = () => {
		const datesPicker = document.getElementById('Dates_date_range_picker');
		if (datesPicker) {
			if (datesPicker.style.display !== 'none') {
				datesPicker.style.display = 'none';
			}
		}
	};

	const changedCreatedOnDates = (date: any) => {
		if (date !== null) {
			setFilter({ ...filter, ...date });
		} else {
			if (filter.dateCreated) {
				delete filter.dateCreated;
			}
			setFilter({ ...filter });
		}
	};

	const changedLastUpdatedDates = (date: any) => {
		if (date !== null) {
			setFilter({ ...filter, ...date });
		} else {
			if (filter.lastUpdated) {
				delete filter.lastUpdated;
			}
			setFilter({ ...filter });
		}
	};

	const resetFilter = () => {
		setStatusSelected('all');
		setLocationsToSelectFunc();
		setServiceSelected('all');
		setTotalCodesSelected('all');
		changedCreatedOnDates(null);
		setFilter({ status: 'all' });
	};

	const onFilterChange = (e: any) => {
		const value = e?.target?.value || '';
		setSearchText(value);
		if (value && value.length > 2) {
			searchInput({
				search: { query: value, fields: ['name'] }
			});
		} else {
			searchInput(null);
		}
	};

	useEffect(() => {
		if (search !== null) {
			setFilter({ ...filter, ...search });
		} else {
			setFilter({ ...filter, search: undefined });
		}
	}, [search]);

	useEffect(() => {
		if (filter?.$text) {
			setSearchText(filter?.$text);
		}
	}, [searchText, filter?.$text]);

	useEffect(() => {
		setLocationsToSelectFunc();
	}, [locations]);

	const setLocationsToSelectFunc = () => {
		let locationsArray: any[] = [];
		locationsArray = locations
			.filter((location: any) => location.parentId == null)
			.map((loc: any) => {
				return {
					label: loc.name,
					value: loc.id,
					checked: false,
					children:
						loc.childrenLocations === 0
							? []
							: locations
									.filter((childrenLocation: any) => childrenLocation.parentId === loc.id)
									.map((childLoc: any) => {
										return {
											label: childLoc.name,
											value: childLoc.id,
											checked: false
										};
									})
				};
			});
		setLocationsToSelect(locationsArray);
	};

	return (
		<div className={classes.eventsToolbar}>
			<div className={classes.filterPosition}>
				<SearchFilter
					{...props}
					hideFilter={false}
					className={classes.filter}
					filterValues={[]}
					setFilters={() => []}
				>
					<SearchInput
						variant='outlined'
						size='small'
						source='valueCodeList-id'
						alwaysOn
						placeholder='Search by ID or Name'
						className={classes.input}
						onChange={onFilterChange}
						options={{
							value: searchText
						}}
					/>
				</SearchFilter>
				<div className={classes.locationContainer}>
					<label className={classes.label}>Locations</label>
					<DropdownTreeSelect
						data={locationsToSelect}
						mode='multiSelect'
						className={classes.locationsSelect}
						onChange={locationChanged}
					/>
				</div>
				<Filter
					id='service_label'
					label='Service'
					value={serviceSelected}
					onOpen={refundsFilterOpened}
					onChange={serviceChanged}
					labelWidth={60}
				>
					<MenuItem key={`service_all`} value={'all'} selected={true}>
						All
					</MenuItem>
					{services.map((serviceEle: any, index: number) => {
						return (
							<MenuItem key={`status_${index}`} value={serviceEle.id} selected={false}>
								{serviceEle.name}
							</MenuItem>
						);
					})}
				</Filter>
				<div className={classes.datePicker}>
					<CustomDatePicker
						fieldKey='dateCreated'
						setFilterSearch={changedCreatedOnDates}
						ts={filter.dateCreated}
						labelWidth={105}
						label='Date Created'
					/>
				</div>
				<div className={classes.datePicker}>
					<CustomDatePicker
						fieldKey='lastUpdated'
						setFilterSearch={changedLastUpdatedDates}
						ts={filter.lastUpdated}
						labelWidth={105}
						label='Last Updated'
					/>
				</div>
				<Filter
					id='status_label'
					label='Status'
					value={statusSelected}
					onOpen={refundsFilterOpened}
					onChange={statusChanged}
					labelWidth={55}
				>
					{status.map((statusEle: any, index: number) => {
						return (
							<MenuItem
								key={`status_${index}`}
								value={statusEle.id}
								selected={statusSelected === statusEle.id}
							>
								{statusEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				<Filter
					id='totalCodes_label'
					label='Total Codes'
					value={totalCodesSelected}
					onOpen={refundsFilterOpened}
					onChange={totalCodesChanged}
					labelWidth={95}
				>
					{totalCodes.map((totalCodeEle: any, index: number) => {
						return (
							<MenuItem
								key={`totalCodes_${index}`}
								value={totalCodeEle.id}
								selected={totalCodesSelected === totalCodeEle.id}
							>
								{totalCodeEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				{filterChanged && (
					<Button
						classes={{
							root: classes.btn,
							text: classes.btnText
						}}
						onClick={resetFilter}
						endIcon={
							<SvgIcon component={CloseIcon} viewBox='0 0 24 24' className={classes.svgIcon} />
						}
					>
						Reset All Filters
					</Button>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		eventsToolbar: {
			display: 'flex',
			padding: '12px 0px 22px 0px',
			backgroundColor: '#FFFFFF'
		},
		filterPosition: {
			display: 'flex',
			marginLeft: '22px',
			'& MuiFormControl-root': {
				height: '48px'
			},
			'& .MuiOutlinedInput-root': {
				height: '100%'
			},
			'& .react-datepicker-popper': {
				zIndex: 2
			}
		},
		btn: {
			textTransform: 'none',
			marginLeft: '16px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			borderRadius: '8px',
			height: '48px',
			minWidth: '160px'
		},
		btnText: {
			padding: '6px 14px 6px 8px'
		},
		svgIcon: {
			width: '13px',
			height: '13px'
		},
		filter: {
			display: 'unset',
			backgroundColor: '#ffffff',
			'& .filter-field': {
				margin: '25px -16px 24px 25px',
				height: '43px',
				width: '230px'
			}
		},
		input: {
			'& input': {
				paddingTop: '15px',
				paddingBottom: '15px'
			}
		},
		datePicker: {
			marginLeft: '11px'
		},
		locationsSelect: {
			fontFamily: 'Manrope',
			marginLeft: '16px',
			'& a': {
				borderRadius: '8px',
				padding: '7px 16px !important',
				'& input': {
					borderBottom: 'none !important',
					fontSize: '16px',
					fontFamily: 'Manrope',
					fontWeight: 500,
					fontStyle: 'normal',
					lineHeight: '22px'
				}
			},
			'& .dropdown .dropdown-trigger.arrow.bottom:after': {
				fontSize: '17px',
				color: 'rgba(0, 0, 0, 0.54)',
				content: '"\\23F7"'
			},
			'& .dropdown .dropdown-trigger.arrow.top:after': {
				fontSize: '17px',
				color: 'rgba(0, 0, 0, 0.54)',
				content: '"\\23F6"'
			},
			'& .dropdown .dropdown-content li': {
				fontSize: '18px'
			}
		},
		label: {
			fontSize: '13px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			color: '#969696',
			position: 'absolute',
			left: '25px',
			top: '-7px',
			backgroundColor: 'white',
			zIndex: 2,
			padding: '0 3px'
		},
		locationContainer: {
			position: 'relative'
		}
	})
);
