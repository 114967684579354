import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import { HeaderList } from '../common/HeaderList';
import { SearchAnyField } from './SearchAnyField';
import { SearchResults } from './SearchResults';
import { FilterSearch } from '../common/myDataGrid/MyDatagridProps';

/**
 * Read only list of consumers
 * @param props
 * @constructor
 */
export const ValueCodesList = (props: any) => {
	const classes = useStyles();
	const history: any = useHistory();

	const [showResults, setShowResults] = useState(false);
	const [filterSearch, setFilterSearch] = useState<FilterSearch>({});
	const [total, setTotal] = useState(0);

	useEffect(() => {
		const showResultsFromHistory = history.location.state?.showResults || false;
		setShowResults(showResultsFromHistory);

		const filterSearchFromHistory = history.location.state?.filterSearch || {};
		setFilterSearch(filterSearchFromHistory);
	}, []);

	// for browser backward and forward
	useEffect(() => {
		if (window.location.hash === '#/consumers' && showResults) {
			setShowResults(false);
			setFilterSearch({});
		}

		if (window.location.hash.indexOf('consumers?') > -1 && !showResults) {
			setShowResults(true);
			const filterSearchFromLocalstorage = window.localStorage.getItem(
				'csc_valueCode_filterSearch'
			);
			if (filterSearchFromLocalstorage) {
				setFilterSearch(JSON.parse(filterSearchFromLocalstorage));
			}
		}
	}, [window.location.hash]);

	return (
		<>
			<HeaderList
				setFilterSearch={setFilterSearch}
				setShowResults={setShowResults}
				showResults={showResults}
				idSearch='branchName'
				total={total}
				{...props}
			>
				{showResults && (
					<Button
						onClick={() => {
							setShowResults(false);
							setFilterSearch({});
							const windowLocationHref = window.location.href;
							window.location.href = windowLocationHref.split('?')[0];
						}}
						startIcon={<ArrowBackIcon className={classes.backIcon} />}
						classes={{
							root: classes.searchResultsBtnRoot
						}}
					>
						{/* after pagination render, will change to Search Results (*) */}
						<div id='consumers_search_results' className={classes.searchResultsBtnTxt}>
							Search Results ({total.toLocaleString()})
						</div>
					</Button>
				)}
			</HeaderList>

			{!showResults ? (
				<SearchAnyField
					filterSearch={filterSearch}
					setFilterSearch={setFilterSearch}
					setShowResults={setShowResults}
					setTotal={setTotal}
				/>
			) : (
				<SearchResults
					{...props}
					filterSearch={filterSearch}
					setFilterSearch={setFilterSearch}
					setTotal={setTotal}
				/>
			)}
		</>
	);
};
