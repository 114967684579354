import React from 'react';
import { List, TextField, DateField, DatagridProps } from 'react-admin';
import useStyles from './styles';
import { EmptyGrid } from '../common/EmptyGrid';
import { MyDatagrid, MyShowButton } from '../common/myDataGrid/MyDatagrid';
import { FilterSearch } from '../common/myDataGrid/MyDatagridProps';
import { ColumnCustom } from '../common/ColumnCustom';

interface SearchResultCustomProps {
	filterSearch: FilterSearch;
	setFilterSearch: React.Dispatch<React.SetStateAction<FilterSearch>>;
	setTotal: React.Dispatch<React.SetStateAction<number>>;
}

export type SearchResultsProps = SearchResultCustomProps & DatagridProps;

/**
 * Wrapper for react-admin <List>: renders list of consumers matching search filters.
 * @param props
 * @constructor
 */
export const SearchConsumersResults = (props: SearchResultsProps) => {
	const classes = useStyles();
	const { filterSearch } = props;

	return (
		<div className={classes.searchResultsContainer}>
			{/* <FilterToolbarConsumer
        filter={filterSearch} setFilter={setFilterSearch}
      /> */}

			<div className={classes.listContainer}>
				<List
					classes={{
						root: classes.listRoot,
						content: classes.listContent
					}}
					{...props}
					actions={false}
					bulkActionButtons={false}
					perPage={10}
					filter={filterSearch}
					empty={<EmptyGrid />}
					pagination={false}
					sort={{ field: 'givenId', order: 'DESC' }}
				>
					<MyDatagrid
						hover={true}
						stickyHeader
						classes={{
							table: classes.table,
							thead: classes.thead,
							tbody: classes.tbody,
							headerCell: classes.headerCell
						}}
						filterSearch={filterSearch}
						hasRowClick={true}
						setTotalHeader={props.setTotal}
					>
						<TextField source='firstName' label='First Name' />
						<TextField source='lastName' label='Last Name' />
						<TextField source='email' label='Email' />
						<TextField source='phone' label='Phone' />
						<DateField source='createdOn' label='Date Created' showTime />
						<DateField source='lastUpdated' label='Last Updated' showTime />
						<ColumnCustom label='Status' />
						<MyShowButton />
					</MyDatagrid>
				</List>
			</div>
		</div>
	);
};
