/* eslint-disable prefer-promise-reject-errors */
import { AuthProvider } from 'react-admin';
import { Auth0Client } from '@auth0/auth0-spa-js';
import authConfig from './authConfig';
import axios from 'axios';

const auth0 = new Auth0Client({
	domain: authConfig.domain as string,
	client_id: authConfig.clientID as string,
	redirect_uri: authConfig.redirectURI,
	cacheLocation: 'localstorage',
	useRefreshTokens: false
});
const token = process.env.REACT_APP_TOKEN as string;

const setLocationsAndServices = async (token: string) => {
	const headers = {
		'Content-Type': 'application/json',
		Authorization: token
	};
	await axios({
		method: 'GET',
		url: authConfig.apiUrl + `/locations`,
		headers
	})
		.then(async (response) => {
			localStorage.setItem('cscvc_filterLocations', JSON.stringify(response.data));
		})
		.catch(() => {
			localStorage.setItem('cscvc_filterLocations', '[]');
		});
	await axios({
		method: 'GET',
		url: authConfig.apiUrl + `/services`,
		headers
	})
		.then(async (response) => {
			localStorage.setItem('cscvc_filterServices', JSON.stringify(response.data));
		})
		.catch(() => {
			localStorage.setItem('cscvc_filterServices', '[]');
		});
};

const authProvider: AuthProvider = {
	login: async (data: any) => {
		if (data.invitation !== null) {
			await auth0.loginWithPopup({
				invitation: data.invitation,
				organization: data.organization
			});
			return await auth0.getUser().then((user: any) => user);
		} else {
			return auth0.loginWithPopup({
				organization: data.organization
			});
		}
	},
	logout: async () => {
		localStorage.clear();
		// redirect to login page
		return auth0.isAuthenticated().then((isAuthenticated: any) => {
			if (isAuthenticated) {
				return auth0.logout({
					returnTo: authConfig.redirectURI,
					federated: false // have to be enabled to invalidate refresh token
				});
			}
		});
	},
	checkError: ({ status }: any) => {
		if (status === 403) {
			return Promise.reject();
		} else if (status === 401) {
			throw new Error('Unauthorized');
		}
		return Promise.resolve();
	},
	checkAuth: async () => {
		return auth0.isAuthenticated().then(async (isAuthenticated: any) => {
			localStorage.setItem(
				'cscvc_loginEmail',
				await auth0.getUser().then((user: any) => user.email)
			);
			if (isAuthenticated) {
				localStorage.setItem(
					'cscvc_loginEmail',
					await auth0.getUser().then((user: any) => user.email)
				);
				return Promise.resolve();
			} else return Promise.reject();
		});
	},
	getPermissions: async () => {
		const roles = localStorage.getItem('cscvc_roles');
		let email = localStorage.getItem('cscvc_loginEmail');
		if (email === null) {
			const key = localStorage.key(0);
			const item = key ? localStorage.getItem(key) : null;
			const value = item ? JSON.parse(item) : null;
			email = value ? value.body?.decodedToken?.user.email : null;
		}
		if (email !== null) {
			if (roles === undefined || roles === null) {
				try {
					const headers = {
						'Content-Type': 'application/json',
						Authorization: token
					};
					await axios({
						method: 'GET',
						url: authConfig.apiUrl + `/users/${email}`,
						headers
					}).then(async (response) => {
						localStorage.setItem('cscvc_token', response.data.role.token);
						localStorage.setItem('cscvc_loginFullName', response.data.name);
						localStorage.setItem('cscvc_roles', JSON.stringify(response.data.role.permissionsJSON));
						await setLocationsAndServices(response.data.role.token);
						return await Promise.resolve(JSON.stringify(response.data.role.permissionsJSON));
					});
				} catch (error) {
					return await Promise.reject();
				}
			} else {
				return await Promise.resolve(roles);
			}
		} else {
			return await Promise.reject();
		}
	},
	getIdentity: () => {
		return Promise.resolve({
			id: '',
			fullName: ''
		});
	}
};

export default authProvider;
