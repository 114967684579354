import React from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import MoreIcon from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import logo from '../../assets/CSCVCLogo.svg';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			left: 0,
			top: 0,
			width: '100%',
			background: '#FFFFFF',
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			height: '64px'
		},
		logo: {
			position: 'absolute',
			left: '16px',
			top: '14px',
			width: '279.18px',
			height: '42px'
		},
		grow: {
			flexGrow: 1
		},
		loginProfile: {
			display: 'none',
			[theme.breakpoints.up('md')]: {
				display: 'flex'
			}
		},
		sectionMobile: {
			display: 'flex',
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		},
		role: {
			display: 'flex',
			alignItems: 'center',
			marginLeft: '15px',
			fontWeight: 500,
			fontSize: '18px',
			lineHeight: '25px',
			color: '#111111'
		},
		logoutMenu: {
			padding: '18px 14px 17px 14px',
			borderRadius: '8px',
			boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.12)',
			background: '#FFFFFF'
		}
	})
);

/**
 * Custom (page top) navigation component that overrides the default react-admin nav bar.
 * https://marmelab.com/react-admin/doc/2.8/Theming.html
 * @param logout
 * @constructor
 */
export default function CSCAppBar({ logout }: any) {
	const classes = useStyles();
	//   const { permissions } = usePermissions();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const loginEmail = localStorage.getItem('cscvc_loginEmail');

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const renderMenu = (
		<Popover
			classes={{
				paper: classes.logoutMenu
			}}
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			{logout}
		</Popover>
	);

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem
				disabled
				style={{
					color: '#707070',
					marginTop: '0',
					marginBottom: '0',
					paddingTop: '0',
					paddingBottom: '0',
					lineHeight: '12px'
				}}
			>
				<PermIdentityIcon style={{ marginRight: '15px' }} />
				<p>Admin</p>
			</MenuItem>
			{logout}
		</Menu>
	);

	return (
		<div id='my_appbar'>
			<AppBar
				color='default'
				classes={{
					root: classes.root
				}}
			>
				<Toolbar>
					<img src={logo} alt='logo' className={classes.logo} />
					<div className={classes.grow} />
					<div className={classes.loginProfile}>
						<Typography className={classes.role} variant='body1' noWrap>
							{loginEmail || 'User Email'}
						</Typography>
						<IconButton size='small' onClick={handleProfileMenuOpen} color='inherit'>
							<ArrowDropDownIcon />
						</IconButton>
					</div>

					<div className={classes.sectionMobile}>
						<IconButton
							aria-label='show more'
							aria-controls={mobileMenuId}
							aria-haspopup='true'
							onClick={handleMobileMenuOpen}
							color='inherit'
						>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{renderMenu}
		</div>
	);
}
