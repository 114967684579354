import React, { useState, useEffect, useCallback } from 'react';
import { useLogin, useNotify, Notification, defaultTheme, useMutation } from 'react-admin';
import { Grid, Typography, Button, TextField } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import uiColor from '../utils/utilsColors';
import { getWindowHeight } from '../utils/util';
import { ThemeProvider } from '@material-ui/styles';
import logo from '../assets/CSCVCLogo.svg';
import laundry from '../assets/laundry.svg';
import { isEmpty } from 'lodash';

const LoginForm = () => {
	const login = useLogin();
	const notify = useNotify();
	const [mutate] = useMutation();
	const [deskHeight, setDeskHeight] = useState(0);
	const [invitation, setInvitation] = useState('');
	const [organization, setOrganization] = useState('');
	const [email, setEmail] = useState('');
	const [validOrg, setValidOrg] = useState(true);
	const handleSize = useCallback(() => {
		setDeskHeight(getWindowHeight());
	}, []);

	useEffect(() => {
		setDeskHeight(getWindowHeight());
		window.addEventListener('resize', handleSize);

		return () => {
			window.removeEventListener('resize', handleSize);
		};
	}, []);

	useEffect(() => {
		setInvitation(getUrlParam('invitation') as string);
		setOrganization(getUrlParam('organization') as string);
	}, []);

	const handleLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		let organization = null;
		if (
			email.substring(email.lastIndexOf('@') + 1) ===
			(process.env.REACT_APP_AUTH0_SCIO_DOMAIN as string)
		)
			organization = process.env.REACT_APP_AUTH0_SCIO_ORGID as string;
		if (
			email.substring(email.lastIndexOf('@') + 1) ===
			(process.env.REACT_APP_AUTH0_MURALADVISORS_DOMAIN as string)
		)
			organization = process.env.REACT_APP_AUTH0_MURALADVISORS_ORGID as string;
		if (
			email.substring(email.lastIndexOf('@') + 1) ===
			(process.env.REACT_APP_AUTH0_CSCSW_DOMAIN as string)
		)
			organization = process.env.REACT_APP_AUTH0_CSCSW_ORGID as string;

		if (organization !== null) {
			setValidOrg(true);
			login({ invitation: null, organization }).catch((obj) => notify(obj.message));
		} else setValidOrg(false);
	};

	const handleInvitation = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		login({ invitation, organization })
			.then((data) => updateInvitedUser(data))
			.catch((obj) => notify(obj.message));
	};

	const getUrlParam = (name: any) => {
		const url = window.location.href;
		name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
		const regexS = '[\\?&]' + name + '=([^&#]*)';
		const regex = new RegExp(regexS);
		const results = regex.exec(url);
		return results == null ? null : results[1];
	};

	const emailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const updateInvitedUser = (userData: any) => {
		mutate(
			{
				type: 'update',
				resource: 'users',
				payload: {
					id: userData.email,
					data: {
						name:
							userData.given_name && userData.family_name
								? userData.given_name + ' ' + userData.family_name
								: userData.name,
						username: userData.nickname,
						inviteAccepted: true
					}
				}
			},
			{
				onFailure: (error) => {
					notify(`Couln't update new user`, 'warning');
					console.log('Error: ' + error);
				}
			}
		);
	};

	return (
		<ThemeProvider theme={createTheme(defaultTheme)}>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
				style={{
					backgroundColor: uiColor.login_page_bk,
					height: deskHeight
				}}
				spacing={0}
			>
				<Grid
					style={{
						textAlign: 'center',
						position: 'relative',
						top: '-60px'
					}}
				>
					<div>
						<img
							src={logo}
							alt='logo_csc'
							style={{
								marginBottom: '30px'
							}}
						/>
					</div>
					<div
						style={{
							border: '1px solid lightgrey',
							borderRadius: '10px',
							width: '500px',
							height: '450px',
							textAlign: 'center'
						}}
					>
						<img
							src={laundry}
							alt='laundry'
							style={{
								marginTop: '60px'
							}}
						/>
						{invitation !== null && organization !== null ? (
							<>
								<Typography
									variant='h6'
									style={{
										width: '350px',
										marginLeft: '75px',
										marginTop: '30px',
										fontWeight: 'bold'
									}}
								>
									You have been invited to the CSC Value Cards Admin Portal.
								</Typography>
								<Button
									variant='contained'
									color='secondary'
									onClick={handleInvitation}
									style={{
										marginTop: '30px'
									}}
								>
									Accept Invitation
								</Button>
							</>
						) : (
							<>
								<Typography
									variant='h6'
									style={{
										width: '350px',
										marginLeft: '75px',
										marginTop: '30px',
										fontWeight: 'bold'
									}}
								>
									For CSC branch managers, admins, developers, and others.
								</Typography>
								<div>
									<TextField
										error={!!(!isEmpty(email.trim()) && !validOrg)}
										id={'outlined-helperText'}
										variant={'outlined'}
										label='Email'
										size='small'
										onChange={emailChanged}
										value={email}
										type='email'
										helperText={!validOrg ? 'Please use your corporate email to login.' : ''}
										style={{
											marginTop: '30px'
										}}
									/>
								</div>
								<div>
									<Button
										variant='contained'
										color='secondary'
										onClick={handleLogin}
										disabled={!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)}
										style={{
											marginTop: '30px'
										}}
									>
										Sign in
									</Button>
								</div>
							</>
						)}
					</div>
				</Grid>
			</Grid>
			<Notification />
		</ThemeProvider>
	);
};

export default LoginForm;
