import * as React from 'react';
import { useState, useEffect } from 'react';
import {
	Show,
	useNotify,
	TextField,
	DateField,
	ReferenceManyField,
	useShowController,
	FunctionField
} from 'react-admin';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import { makeStyles, createStyles, Divider, Container, Grid, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import uiColor from '../../utils/utilsColors';
import { HeaderShow } from './HeaderShow';
import { AddCurrencyTypeDialog } from './Dialogs/AddCurrencyTypeDialog';
import { EditCurrencyTypeDialog } from './Dialogs/EditCurrencyTypeDialog';
import { AddWebhookDialog } from './Dialogs/AddWebhookDialog';
import { EditWebhookDialog } from './Dialogs/EditWebhookDialog';
import { MyDatagrid, MyShowButton } from '../common/myDataGrid/MyDatagrid';
import { ColumnCustom } from '../common/ColumnCustom';
import { hasViewById } from '../../utils/permissions';

/**
 * Read only list of branches
 * https://marmelab.com/react-admin/doc/2.8/Show.html
 * @param props
 * @constructor
 */
export const ServiceShow = (props: any, permissions: any) => {
	const { record } = useShowController(props);
	const classes = useStyles();
	const history = useHistory();
	const [curSort] = useState({});
	const [total] = useState(0);
	const [search, searchInput] = useState('');
	const notify = useNotify();
	const [currencyType, setCurrencyType] = useState({});
	const [openAddCurrencyTypeDialog, setOpenAddCurrencyTypeDialog] = useState(false);
	const [openEditCurrencyTypeDialog, setOpenEditCurrencyTypeDialog] = useState(false);
	const [webhook, setWebhook] = useState({});
	const [openAddWebhookDialog, setOpenAddWebhookDialog] = useState(false);
	const [openEditWebhookDialog, setOpenEditWebhookDialog] = useState(false);

	const addCurrencyTypeClosed = () => {
		setOpenAddCurrencyTypeDialog(false);
	};
	const addCurrencyTypeClicked = () => {
		setOpenAddCurrencyTypeDialog(true);
	};

	const editCurrencyTypeClosed = () => {
		setOpenEditCurrencyTypeDialog(false);
	};

	const addWebhookClosed = () => {
		setOpenAddWebhookDialog(false);
	};
	const addWebhookClicked = () => {
		setOpenAddWebhookDialog(true);
	};

	const editWebhookClosed = () => {
		setOpenEditWebhookDialog(false);
	};

	useEffect(() => {
		if (hasViewById(null, 'services', props.id) === false) {
			notify(`Unauthorized`, 'warning');
			history.push(`${props.basePath}`);
		}
	}, [props.id]);

	return (
		<Show
			{...props}
			classes={{
				root: classes.showRoot,
				noActions: classes.showNoActions,
				card: classes.showCard
			}}
		>
			<BoxedShowLayout className={classes.showLayout}>
				<RaBox>
					<HeaderShow
						searchInput={searchInput}
						idSearch='id'
						disableExport={true}
						curSort={curSort}
						total={total}
					/>
					<Container className={classes.container}>
						<Grid container>
							<Grid item xs={6}>
								<span className={classes.menuTitle}>Service Information</span>
								<Divider className={classes.dividerDetailShow} />
								<Grid container>
									<Grid item xs={4}>
										<span className={classes.label}>Service Name</span>
									</Grid>
									<Grid item xs={8} className={classes.gridInfo}>
										<TextField source='name' className={classes.bold} />
									</Grid>
									<Grid item xs={4}>
										<span className={classes.label}>Service ID</span>
									</Grid>
									<Grid item xs={8} className={classes.gridInfo}>
										<TextField source='id' className={classes.bold} />
									</Grid>
									{record?.consumerUrl != null ? (
										<>
											<Grid item xs={4}>
												<span className={classes.label}>Consumer URL</span>
											</Grid>
											<Grid item xs={8} className={classes.gridInfo}>
												<TextField source='consumerUrl' className={classes.bold} />
											</Grid>
										</>
									) : (
										<span></span>
									)}
									<Grid item xs={4}>
										<span className={classes.label}>Date Created</span>
									</Grid>
									<Grid item xs={8} className={classes.gridInfo}>
										<DateField
											label='Date Created'
											source='createdOn'
											showTime
											className={classes.bold}
										/>
									</Grid>
									<Grid item xs={4}>
										<span className={classes.label}>Last Updated</span>
									</Grid>
									<Grid item xs={8}>
										<DateField
											label='Last Updated'
											source='lastUpdated'
											showTime
											className={classes.bold}
										/>
									</Grid>
									<Grid item xs={12} className={classes.secondTitleGrid}>
										<span className={classes.secondTitle}>Currency Type</span>
										<Divider className={classes.dividerDetailShow} />
									</Grid>
									<div className={classes.listContainer}>
										<ReferenceManyField
											reference='currencytypes'
											target='parent'
											filter={{ monetary: false, serviceId: record?.id }}
											addLabel={false}
										>
											<MyDatagrid
												resource='currencytypes'
												hover={true}
												classes={{
													table: classes.table,
													thead: classes.thead,
													tbody: classes.tbody,
													headerCell: classes.headerCell,
													rowOdd: classes.rowOdd
												}}
												filter={{ key: 'label', value: `${search}` }}
												size='medium'
												hasRowClick={true}
												setCurrencyType={setCurrencyType}
												setOpenEditCurrencyTypeDialog={setOpenEditCurrencyTypeDialog}
											>
												<TextField label='Currency Type' source='name' />
												<TextField label='Service ID' source='serviceId' />
												<TextField label='Description' source='description' />
												<DateField label='Date Created' source='createdOn' showTime />
												<DateField label='Last Updated' source='lastUpdated' showTime />
												<FunctionField
													label='Type'
													render={(record: any) => {
														return record.monetary === true ? 'Monetary' : 'Non-monetary';
													}}
												/>
												<ColumnCustom label='Status' />
												<MyShowButton />
											</MyDatagrid>
										</ReferenceManyField>
									</div>
									<Button
										variant='contained'
										size='small'
										startIcon={<AddIcon />}
										onClick={addCurrencyTypeClicked}
										classes={{
											root: classes.createBtnRoot,
											label: classes.createBtnTxt
										}}
									>
										Add Currency Type
									</Button>
									<Grid item xs={12} className={classes.secondTitleGrid}>
										<span className={classes.secondTitle}>Webhooks</span>
										<Divider className={classes.dividerDetailShow} />
									</Grid>
									<div className={classes.listContainer}>
										<ReferenceManyField
											reference='webhooks'
											target='serviceId'
											filter={{ serviceId: record?.id }}
											addLabel={false}
										>
											<MyDatagrid
												resource='webhooks'
												hover={true}
												classes={{
													table: classes.table,
													thead: classes.thead,
													tbody: classes.tbody,
													headerCell: classes.headerCell,
													rowOdd: classes.rowOdd
												}}
												filter={{ key: 'label', value: `${search}` }}
												size='medium'
												hasRowClick={true}
												setWebhook={setWebhook}
												setOpenEditWebhookDialog={setOpenEditWebhookDialog}
											>
												<TextField label='Name' source='name' />
												<TextField label='Description' source='description' />
												<TextField label='URL' source='url' className={classes.urlText} />
												<TextField
													label='Data Type'
													source='dataType'
													style={{ textTransform: 'capitalize' }}
												/>
												<TextField
													label='HTTP Verb'
													source='httpVerb'
													style={{ textTransform: 'uppercase' }}
												/>
												<TextField
													label='Action Filter'
													source='actionFilter'
													style={{ textTransform: 'capitalize' }}
												/>
												<TextField label='Result Processing' source='resultProcessing' />
												<TextField label='Sequence Order' source='sequenceOrder' />
												<ColumnCustom label='Status' />
												<MyShowButton />
											</MyDatagrid>
										</ReferenceManyField>
									</div>
									<Button
										variant='contained'
										size='small'
										startIcon={<AddIcon />}
										onClick={addWebhookClicked}
										classes={{
											root: classes.createBtnRoot,
											label: classes.createBtnTxt
										}}
									>
										Add Webhook
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Container>
					<AddCurrencyTypeDialog
						openAddCurrencyType={openAddCurrencyTypeDialog}
						addCurrencyTypeClosed={addCurrencyTypeClosed}
						serviceId={record?.id}
					/>
					<EditCurrencyTypeDialog
						openEditCurrencyType={openEditCurrencyTypeDialog}
						editCurrencyTypeClosed={editCurrencyTypeClosed}
						currencyType={currencyType}
					/>
					<AddWebhookDialog
						openAddWebhook={openAddWebhookDialog}
						addWebhookClosed={addWebhookClosed}
						serviceId={record?.id}
					/>
					<EditWebhookDialog
						openEditWebhook={openEditWebhookDialog}
						editWebhookClosed={editWebhookClosed}
						webhook={webhook}
					/>
				</RaBox>
			</BoxedShowLayout>
		</Show>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		layout: {
			marginTop: '0px'
		},
		roomList: {
			background: 'white'
		},
		showRoot: {},
		showNoActions: {
			marginTop: '20px'
		},
		showCard: {
			boxShadow: '0 0 0'
		},
		showLayout: {
			padding: 0,
			'&:first-child': {
				paddingTop: '0px'
			},
			'&:last-child': {
				paddingBottom: '0px'
			}
		},
		table: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			background: '#FFFFFF',
			minWidth: '1200px'
		},
		thead: {
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px'
		},
		tbody: {
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px',
			border: '1px solid #EEEFF3'
		},
		headerCell: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#111111',
			paddingTop: '20px',
			paddingBottom: '20px',
			paddingLeft: '16px',
			'&:first-child': {
				width: '150px'
			},
			'&:nth-child(2)': {
				width: '200px'
			},
			'&:nth-child(3)': {
				width: '300px'
			},
			'&:nth-child(4)': {
				width: '100px'
			},
			'&:last-child': {
				width: '5px'
			}
		},
		tableRow: {
			'&$hover:hover': {
				background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
				cursor: 'pointer'
			}
		},
		hover: {},
		tableCell: {
			paddingTop: '16px',
			paddingBottom: '16px',
			borderRight: '1px solid #EEEFF3',
			paddingLeft: '24px'
		},
		tableCellNoBorder: {
			paddingTop: '16px',
			paddingBottom: '16px',
			paddingLeft: '24px'
		},
		divider: {
			borderBottom: '1px solid #E0E0E0',
			paddingBottom: '15px',
			marginLeft: '10px',
			color: 'grey',
			background: 'none',
			marginRight: '10px'
		},
		menuTitle: {
			fontWeight: 700,
			fontSize: '18px',
			fontFamily: 'Manrope',
			marginBottom: '10px',
			marginTop: '10px'
		},
		secondTitle: {
			fontWeight: 700,
			fontSize: '18px',
			fontFamily: 'Manrope'
		},
		secondTitleGrid: {
			marginTop: '60px'
		},
		active: {
			color: uiColor.sidebar_active
		},
		dividerDetailShow: {
			marginBottom: '20px',
			color: 'grey'
		},
		container: {
			paddingTop: '15px'
		},
		label: {
			fontFamily: 'Manrope'
		},
		bold: {
			fontWeight: 700
		},
		gridInfo: {
			paddingBottom: '20px'
		},
		listContainer: {
			maxWidth: '1070px',
			minWidth: '500px',
			marginLeft: 0,
			background: '#F2F4FB'
		},
		rowOdd: {
			backgroundColor: '#F8F9FA'
		},
		service: {
			width: '10px'
		},
		createBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '8px, 16px, 8px, 12px',
			borderRadius: '8px',
			width: '240px',
			height: '40px',
			background: '#FFFFFF',
			marginTop: '20px',
			marginBottom: '20px'
		},
		createBtnTxt: {
			color: '#5F5F5F',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		urlText: {
			wordBreak: 'break-word'
		}
	})
);
