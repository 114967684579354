import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, MenuItem, SvgIcon, Button } from '@material-ui/core';
import { Filter } from './Filter';
import CustomDatePicker from '../common/customDatePicker/CustomDatePicker';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';

export const ServicesFilters = ({ setFilter, filter }: any) => {
	const classes = useStyles();

	const [serviceSelected, setServiceSelected] = useState('all');
	const [services, setServices] = useState<any>([]);
	const [statusSelected, setStatusSelected] = useState('all');
	const [status] = useState<any>([
		{ id: 'all', description: 'All' },
		{ id: 'active', description: 'Active' },
		{ id: 'inactive', description: 'Inactive' }
	]);
	const [filterChanged, setFilterChanged] = useState(false);

	useEffect(() => {
		const services = JSON.parse(localStorage.getItem('cscvc_filterServices') as string);
		services.unshift({ id: 'all', name: 'All' });
		setServices(services);
	}, []);

	useEffect(() => {
		if (filter?.status) {
			setStatusSelected(filter.status);
		}

		if (filter?.serviceId) {
			setServiceSelected(filter.serviceId);
		}

		if (
			filter?.status === 'all' &&
			filter?.service === undefined &&
			filter?.datesRange === undefined
		) {
			setFilterChanged(false);
		} else {
			setFilterChanged(true);
		}
	}, [filter]);

	const serviceChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const service = event.target.value as string;
		if (service !== 'all') {
			setFilter({ ...filter, service });
		} else {
			setFilter({ ...filter, service: undefined });
		}
		setServiceSelected(service);
		setFilterChanged(false);
	};

	const statusChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const status = event.target.value as string;
		if (status !== 'all') {
			setFilter({ ...filter, status });
		} else {
			setFilter({ ...filter, status: undefined });
		}
		setFilterChanged(false);
	};

	const servicesFilterOpened = () => {
		const datesPicker = document.getElementById('Dates_date_range_picker');
		if (datesPicker) {
			if (datesPicker.style.display !== 'none') {
				datesPicker.style.display = 'none';
			}
		}
	};

	const changedDates = (date: any) => {
		if (date !== null) {
			setFilter({ ...filter, ...date });
		} else {
			if (filter.datesRange) {
				delete filter.datesRange;
			}
			setFilter({ ...filter });
		}
	};

	const resetFilter = () => {
		setServiceSelected('all');
		setStatusSelected('all');
		changedDates(null);
		setFilter({ status: 'all' });
	};

	return (
		<div className={classes.eventsToolbar}>
			<div className={classes.filterPosition}>
				<Filter
					id='service_label'
					label='Service'
					value={serviceSelected}
					onOpen={servicesFilterOpened}
					onChange={serviceChanged}
					labelWidth={65}
				>
					{services.map((service: any, index: number) => {
						return (
							<MenuItem
								key={`services_${index}`}
								value={service.id}
								selected={serviceSelected === service.id}
							>
								{service.name}
							</MenuItem>
						);
					})}
				</Filter>
				<div className={classes.datePicker}>
					<CustomDatePicker
						fieldKey='datesRange'
						setFilterSearch={changedDates}
						ts={filter.datesRange}
						labelWidth={60}
					/>
				</div>
				<Filter
					id='status_label'
					label='Status'
					value={statusSelected}
					onOpen={servicesFilterOpened}
					onChange={statusChanged}
					labelWidth={55}
				>
					{status.map((statusEle: any, index: number) => {
						return (
							<MenuItem
								key={`status_${index}`}
								value={statusEle.id}
								selected={statusSelected === statusEle.id}
							>
								{statusEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				{filterChanged && (
					<Button
						classes={{
							root: classes.btn,
							text: classes.btnText
						}}
						onClick={resetFilter}
						endIcon={
							<SvgIcon component={CloseIcon} viewBox='0 0 24 24' className={classes.svgIcon} />
						}
					>
						Reset All Filters
					</Button>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		eventsToolbar: {
			display: 'flex',
			padding: '12px 0px 22px 0px',
			backgroundColor: '#FFFFFF'
		},
		filterPosition: {
			display: 'flex',
			marginLeft: '22px',
			'& MuiFormControl-root': {
				height: '48px'
			},
			'& .MuiOutlinedInput-root': {
				height: '100%'
			},
			'& .react-datepicker-popper': {
				zIndex: 2
			}
		},
		btn: {
			textTransform: 'none',
			marginLeft: '16px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			borderRadius: '8px',
			minWidth: '160px'
		},
		btnText: {
			padding: '6px 14px 6px 8px'
		},
		svgIcon: {
			width: '13px',
			height: '13px'
		},
		datePicker: {
			marginLeft: '11px'
		}
	})
);
