import * as React from 'react';
import { forwardRef, useState, useEffect } from 'react';
import {
	useRefresh,
	useNotify,
	useMutation,
	SimpleShowLayout,
	TextField as TextFieldReact,
	useDataProvider,
	fetchUtils,
	HttpError
} from 'react-admin';
import { RaBox } from 'ra-compact-ui';
import { isEmpty } from 'lodash';

import {
	Typography,
	Button,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	TextField,
	MenuItem,
	IconButton,
	DialogContent,
	Grid
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { TransitionProps } from '@material-ui/core/transitions';
import useStyles from '../styles';
import { EditLocationProps } from './DialogsProps';
import { Filter } from '../../services/Filter';
import { FoundLocationDialog } from './FoundLocationDialog';
const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export const EditLocationDialog = ({
	openAddLocation,
	addLocationClosed,
	serviceId,
	locationId
}: EditLocationProps) => {
	const classes = useStyles();
	const [locationName, setLocationName] = useState('');
	const refresh = useRefresh();
	const notify = useNotify();
	const [mutate] = useMutation();
	const dataProvider = useDataProvider();
	const [services, setServices] = useState<any>([]);
	const [searchText, setSearchText] = useState('');
	const [serviceSelected, setServiceSelected] = useState('');
	const [showSearch, setShowSearch] = React.useState(false);
	const [showName, setShowName] = React.useState(true);
	const [openFoundLocationDialog, setOpenFoundLocationDialog] = useState(false);
	const [foundLocation, setFoundLocation] = useState<any>(null);
	const [showFoundLocation, setShowFoundLocation] = React.useState(false);
	const httpClient = fetchUtils.fetchJson;
	const apiUrl = process.env.REACT_APP_API_URL as string;
	const cscgoServiceId = process.env.REACT_APP_CSCGOSERVICEID as string;
	const token = localStorage.getItem('cscvc_token') as string;

	const retrieveLocation = () => {
		dataProvider.getOne('locations', { id: locationId }).then(({ data }) => {
			setLocationName(data.name);
		});
	};

	const retrieveServices = () => {
		dataProvider
			.getList('services', {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then(({ data }) => {
				data.unshift({ id: 'None', name: 'None' });
				setServices(data);
			});
	};

	const updateLocalStorage = () => {
		dataProvider
			.getList('locations', {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then((response) => {
				localStorage.setItem('cscvc_filterLocations', JSON.stringify(response.data));
			})
			.catch(() => {
				localStorage.setItem('cscvc_filterLocations', '[]');
			});
	};

	useEffect(() => {
		retrieveLocation();
	}, [locationId]);

	useEffect(() => {
		if (services.length === 0) retrieveServices();
		if (serviceId != null) setServiceSelected(serviceId);
		if (serviceId === null) setServiceSelected('None');

		if (serviceId === cscgoServiceId) {
			setShowName(false);
			setShowSearch(true);
		} else {
			setShowName(true);
			setShowSearch(false);
		}
	}, [services, serviceId, cscgoServiceId]);

	const onEditLocationClicked = () => {
		if (foundLocation != null && serviceSelected !== 'None') {
			mutate(
				{
					type: 'update',
					resource: 'locations',
					payload: {
						id: locationId,
						data: {
							Name: foundLocation.label || locationName,
							ServiceId: serviceSelected,
							AS400ID: foundLocation.as400Number,
							ServiceLocationId: foundLocation.locationId
						}
					}
				},
				{
					onSuccess: () => {
						updateLocalStorage();
						addLocationClosed();
						refresh();
						notify(`Location edited: ${locationName}.`);
					},
					onFailure: (error) => {
						notify(`Location not edited`, 'warning');
						console.log('Error: ' + error);
					}
				}
			);
		} else if (foundLocation != null && serviceSelected === 'None') {
			mutate(
				{
					type: 'update',
					resource: 'locations',
					payload: {
						id: locationId,
						data: {
							Name: foundLocation.label || locationName,
							AS400ID: foundLocation.as400Number,
							ServiceLocationId: foundLocation.locationId
						}
					}
				},
				{
					onSuccess: () => {
						updateLocalStorage();
						addLocationClosed();
						refresh();
						notify(`Location edited: ${locationName}.`);
					},
					onFailure: (error) => {
						notify(`Location not edited`, 'warning');
						console.log('Error: ' + error);
					}
				}
			);
		} else if (locationName !== '' && serviceSelected !== 'None') {
			mutate(
				{
					type: 'update',
					resource: 'locations',
					payload: {
						id: locationId,
						data: {
							Name: locationName,
							ServiceId: serviceSelected
						}
					}
				},
				{
					onSuccess: () => {
						updateLocalStorage();
						addLocationClosed();
						refresh();
						notify(`Location edited: ${locationName}.`);
					},
					onFailure: (error) => {
						notify(`Location not edited`, 'warning');
						console.log('Error: ' + error);
					}
				}
			);
		} else if (locationName !== '' && serviceSelected === 'None') {
			mutate(
				{
					type: 'update',
					resource: 'locations',
					payload: {
						id: locationId,
						data: {
							Name: locationName
						}
					}
				},
				{
					onSuccess: () => {
						updateLocalStorage();
						addLocationClosed();
						refresh();
						notify(`Location edited: ${locationName}.`);
					},
					onFailure: (error) => {
						notify(`Location not edited`, 'warning');
						console.log('Error: ' + error);
					}
				}
			);
		}
	};

	const handleSearch = (e: any) => {
		dataProvider
			.getOne('services/cscgo/getLocation', { id: searchText })
			.then(({ data }) => {
				const headers = new Headers({ Accept: 'application/json', Authorization: token });
				const email = localStorage.getItem('cscvc_loginEmail');
				if (email) headers.set('User', btoa(email));
				httpClient(
					`${apiUrl}/locations/${data.id}?serviceLocationId=true&serviceId=${serviceSelected}`,
					{
						headers
					}
				).catch((e: HttpError) => {
					setOpenFoundLocationDialog(true);
					setFoundLocation(data);
				});
			})
			.catch(() => {
				notify('Location not found', 'warning');
			});
	};

	const handleSearchChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const text = event.target.value as string;
		setSearchText(text);
	};

	const serviceChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const service = event.target.value as string;
		if (service === cscgoServiceId) {
			setShowName(false);
			setShowSearch(true);
		} else {
			setShowSearch(false);
			setShowFoundLocation(false);
			setShowName(true);
		}
		setServiceSelected(service);
	};

	const foundLocationDialogClosed = () => {
		setOpenFoundLocationDialog(false);
	};

	const dialogFoundLocationClicked = () => {
		setShowFoundLocation(true);
	};

	const handleNameChange = (event: any) => {
		setLocationName(event.target.value);
	};

	const onCancelClicked = (event: any) => {
		addLocationClosed();
		setShowSearch(false);
		setShowFoundLocation(false);
		setShowName(true);
		setLocationName('');
		setServiceSelected(serviceId);
		setSearchText('');
	};

	return (
		<Dialog
			fullScreen
			open={openAddLocation}
			onClose={addLocationClosed}
			TransitionComponent={Transition}
		>
			<AppBar className={classes.addRoomDialogAppBar}>
				<Toolbar>
					<IconButton aria-label='back' onClick={onCancelClicked}>
						<ArrowBackIcon className={classes.backIcon} />
					</IconButton>
					<Typography className={classes.addRoomDialogTitle}>Edit Location</Typography>
					<Button
						onClick={onCancelClicked}
						size='small'
						variant='contained'
						color='default'
						classes={{
							root: classes.cancelBtnRoot,
							label: classes.cancelBtnTxt
						}}
					>
						Cancel
					</Button>
					<Button
						disabled={
							(isEmpty(locationName.trim()) && !showSearch) || (!showFoundLocation && showSearch)
						}
						onClick={onEditLocationClicked}
						size='small'
						color='secondary'
						variant='contained'
						classes={{
							root: classes.yesBtnRoot,
							label: classes.yesBtnTxt,
							disabled: classes.addRoomBtnDisabled
						}}
					>
						Edit location
					</Button>
				</Toolbar>
			</AppBar>
			<DialogContent classes={{ root: classes.dialogContent }}>
				<RaBox>
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<div className={classes.subtitle}>Service</div>
							<div className={classes.addSubLocationDialogItem}>
								<Filter
									id='service_label'
									label='Service'
									value={serviceSelected}
									onChange={serviceChanged}
									labelWidth={65}
								>
									{services.map((service: any, index: number) => {
										return (
											<MenuItem
												key={`services_${index}`}
												value={service.id}
												selected={serviceSelected === service.id}
											>
												{service.name}
											</MenuItem>
										);
									})}
								</Filter>
							</div>
						</Grid>
						{!showFoundLocation ? <Grid item xs={6}></Grid> : <span></span>}
						{showFoundLocation ? (
							<Grid item xs={6} className={classes.gridFoundLocation}>
								<SimpleShowLayout record={foundLocation}>
									<TextFieldReact
										source='label'
										label='Location Name'
										className={classes.textField}
									/>
									<TextFieldReact
										source='locationId'
										label='Service Location ID'
										className={classes.textField}
									/>
								</SimpleShowLayout>
							</Grid>
						) : (
							<span></span>
						)}
						{showSearch ? (
							<Grid item xs={6}>
								<div className={classes.subtitle}>Search for AS400</div>
								<div className={classes.addSubLocationDialogItem}>
									<TextField
										value={searchText}
										onChange={handleSearchChange}
										label='Search'
										variant='outlined'
										className={classes.addSubLocationDialogSearch}
										onKeyPress={(e: any) => {
											if (e.key === 'Enter') {
												handleSearch(e);
											}
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton onClick={handleSearch}>
														<SearchIcon />
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</div>
							</Grid>
						) : (
							<span></span>
						)}
						{showName ? (
							<Grid item xs={6}>
								<div className={classes.subtitle}>Name</div>
								<div className={classes.addSubLocationDialogItem}>
									<TextField
										id='outlined-helperText'
										variant='outlined'
										label='Name'
										size='small'
										type='search'
										value={locationName}
										onChange={handleNameChange}
										className={classes.addSubLocationDialogSearch}
										inputProps={{
											style: {
												height: '26px'
											}
										}}
									/>
								</div>
							</Grid>
						) : (
							<span></span>
						)}
						{showFoundLocation ? (
							<Grid item xs={6}>
								<SimpleShowLayout record={foundLocation}>
									<TextFieldReact
										source='address.address1'
										label='Address'
										className={classes.textField}
									/>
									<div className={classes.foundLocationcontainer}>
										<span className={classes.textFieldAddressLabel}>State</span>
										<span className={classes.textFieldAddressCityLabel}>City</span>
										<span className={classes.textFieldAddressLabel}>Zip</span>
									</div>
									<div className={classes.foundLocationcontainer}>
										<span className={classes.textFieldAddress}>
											{foundLocation ? foundLocation.address.state : ''}
										</span>
										<span className={classes.textFieldAddressCity}>
											{foundLocation ? foundLocation.address.city : ''}
										</span>
										<span className={classes.textFieldAddress}>
											{foundLocation ? foundLocation.address.zipcode : ''}
										</span>
									</div>
								</SimpleShowLayout>
							</Grid>
						) : (
							<Grid item xs={6}></Grid>
						)}
					</Grid>
				</RaBox>
			</DialogContent>
			<FoundLocationDialog
				openFoundLocation={openFoundLocationDialog}
				foundLocationClosed={foundLocationDialogClosed}
				foundLocationClicked={dialogFoundLocationClicked}
				foundLocation={foundLocation}
			/>
		</Dialog>
	);
};
