import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import createMuiTheme from '@material-ui/core/styles/createTheme';
import createPalette from '@material-ui/core/styles/createPalette';

import uiColor from './utilsColors';

const palette = createPalette(merge({}, defaultTheme.palette, {}));

const rawTheme = {
	palette,
	typography: {
		fontSize: 16, // Should be a number in pixels
		lineHeight: 22,
		fontFamily: 'Manrope',
		fontStyle: 'normal',
		fontWeight: 'bold',
		letterSpacing: '-0.5px'
	},
	overrides: {
		RaMenuItemLink: {
			root: {
				marginBottom: '24px',
				color: uiColor.sidebar_item,
				'&:hover': {
					backgroundColor: uiColor.sidebar_hover
				}
			},
			icon: {
				// icon active color will be changed according to item active color
				color: 'inherit',
				marginRight: '10px'
			}
		},
		MuiCssBaseline: {
			'@global': {
				html: {
					WebkitFontSmoothing: 'auto'
				}
			}
		},
		MuiFormControl: {
			root: {
				flex: 1
			},
			marginDense: {
				// for Locations list of one branch
				marginTop: 0,
				marginBottom: 0
			}
		},
		MuiOutlinedInput: {
			root: {
				borderColor: '#E0E0E0',
				boxSizing: 'border-box',
				borderRadius: '8px'
			},
			inputMarginDense: {
				paddingTop: '11px',
				paddingBottom: '9px'
			},
			input: {
				padding: '20px 9px 11px 9px',
				fontFamily: 'Manrope',
				fontStyle: 'normal',
				fontWeight: 500,
				fontSize: '16px',
				lineHeight: '22px',
				color: '#111111',
				mixBlendMode: 'normal'
			}
		},
		MuiSelect: {
			icon: {
				width: '24px',
				height: '24px'
			},
			iconOutlined: {
				right: '11.56px'
			}
		},
		MuiTab: {
			textColorInherit: {
				color: '#969696'
			}
		},
		RaLabeled: {
			value: {
				// for Location list table of one branch
				paddingTop: 0
			}
		},
		RaLayout: {
			root: {
				backgroundColor: '#FFFFFF',
				background: '#FFFFFF'
			},
			content: {
				// for branch show content
				padding: 0,
				paddingTop: 0,
				position: 'relative',
				background: '#F2F4FB',
				'@media (min-width: 0px)': {
					paddingLeft: 0
				}
			}
		},
		RaLogout: {
			icon: {
				display: 'none'
			},
			menuItem: {
				background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
				borderRadius: '8px',
				width: '144px',
				height: '27px',
				fontWeight: 500,
				fontSize: '16px',
				lineHeight: '22px',
				color: '#111111'
			}
		},
		RaSidebar: {
			drawerPaper: {
				width: '64px'
			}
		},
		RaDatagrid: {
			headerCell: {
				'&:first-child': {
					borderTopLeftRadius: '8px'
				},
				'&:last-child': {
					borderTopRightRadius: '8px'
				},
				zIndex: 0
			}
		}
	}
};

export const theme = createMuiTheme(merge({}, defaultTheme, rawTheme));
