import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
	createStyles({
		loadingContainer: {
			width: '100%'
		},
		showBtn: {
			visibility: 'hidden',
			'$hover:hover &': {
				visibility: 'visible'
			}
		},
		hover: {},
		tableRow: {
			'&$hover:hover': {
				background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
				cursor: 'pointer',
				'& button': {
					visibility: 'visible'
				}
			},
			'&:nth-child(even)': {
				background: '#F8F9FA'
			}
		},
		tableCellValueCodeLists: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#111111',
			padding: '10px 24px',
			'&:first-child': {
				width: '100px'
			},
			'&:nth-child(2)': {
				width: '400px'
			},
			'&:nth-child(3)': {
				width: '200px'
			},
			'&:nth-child(4)': {
				width: '100px'
			},
			'&:nth-child(5)': {
				width: '100px'
			},
			'&:nth-child(6)': {
				width: '100px'
			},
			'&:nth-child(7)': {
				width: '100px',
				textAlign: 'left'
			},
			'&:last-child': {
				width: '50px',
				textAlign: 'right'
			}
		},
		tableRowActivity: {
			'&$hover:hover': {
				background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)'
			}
		},
		tableCellActivity: {
			fontWeight: 500,
			padding: '20px 24px',
			textAlign: 'left'
		},
		emptyResultsContainer: {
			marginTop: '134px',
			marginBottom: '535px'
		},
		emptyResultsTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '24px',
			lineHeight: '33px',
			textAlign: 'center',
			letterSpacing: '-0.5px',
			color: '#111111'
		},
		customDataGrid: {
			display: 'block',
			overflowY: 'scroll',
			width: '100%'
		},
		lastRow: {
			textAlign: 'left',
			'&:last-child': {
				textAlign: 'left'
			}
		},
		rowOdd: {
			backgroundColor: '#F8F9FA'
		}
	})
);

export default useStyles;
