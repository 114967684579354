import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, MenuItem, SvgIcon, Button } from '@material-ui/core';
import { Filter } from './Filter';
import CustomDatePicker from '../common/customDatePicker/CustomDatePicker';
import { TRANSACTION_TYPE, CURRENCY_TYPE, DATA_SOURCE } from './values';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { SearchInput, Filter as SearchFilter } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';

export const TransactionsFilters = (
	{
		setFilter,
		filter,
		locations,
		services,
		onExports,
		valueStoreFilter,
		valueCodes,
		showExportBtn,
		valueStores
	}: any,
	props: any
) => {
	const classes = useStyles();
	const [transactionTypeSelected, setTransactionTypeSelected] = useState('all');
	const [currencyTypeSelected, setCurrencyTypeSelected] = useState('all');
	const [dataSourceSelected, setDataSourceSelected] = useState('current');
	const [serviceSelected, setServiceSelected] = useState('all');
	const [valueCodeSelected, setValueCodeSelected] = useState('all');
	const [transactionType] = useState<any>(TRANSACTION_TYPE);
	const [currencyType] = useState<any>(CURRENCY_TYPE);
	const [dataSource] = useState<any>(DATA_SOURCE);
	const [filterChanged, setFilterChanged] = useState(false);

	const [searchText, setSearchText] = useState('');
	const [search, searchInput] = useState<Object | null>({});
	const [locationsToSelect, setLocationsToSelect] = useState<any>([]);

	useEffect(() => {
		if (
			(filter?.transactionType === undefined || filter?.transactionType === 'all') &&
			(filter?.currencyType === undefined || filter?.currencyType === 'all') &&
			(filter?.locations === undefined || filter?.locations.length === 0) &&
			(filter?.service === undefined || filter?.service === 'all') &&
			(filter?.dataSource === undefined || filter?.dataSource === 'current') &&
			(filter?.valueCodeId === undefined || filter?.valueCodeId === 'all') &&
			filter?.datesRange === undefined
		) {
			setFilterChanged(false);
		} else {
			setFilterChanged(true);
		}
	}, [filter]);

	const transactionTypeChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const transactionType = event.target.value as string;
		if (transactionType !== 'all') {
			setFilter({ ...filter, transactionType });
		} else {
			setFilter({ ...filter, transactionType: undefined });
		}
		setTransactionTypeSelected(transactionType);
	};

	const currencyTypeChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const currencyType = event.target.value as string;
		if (currencyType !== 'all') {
			setFilter({ ...filter, currencyType });
		} else {
			setFilter({ ...filter, currencyType: undefined });
		}
		setCurrencyTypeSelected(currencyType);
	};

	const locationChanged = (currentNode: any, selectedNodes: any) => {
		const location = currentNode.value;
		const locationsArray = locationsToSelect.slice();
		locationsArray.forEach((loc: any) => {
			if (loc.value === location) {
				loc.checked = currentNode.checked;
			}
			loc.children.forEach((childLoc: any) => {
				if (childLoc.value === location) {
					childLoc.checked = currentNode.checked;
				}
				if (childLoc.value !== location && loc.checked) {
					selectedNodes.push({ value: childLoc.value });
					childLoc.checked = currentNode.checked;
				}
				if (childLoc.value !== location && !loc.checked) {
					childLoc.checked = false;
					loc.checked = false;
				}
				if (childLoc.value === location && !currentNode.checked && loc.checked) {
					selectedNodes.push({ value: loc.value });
				}
				if (childLoc.value === location && currentNode.checked) {
					selectedNodes.push({ value: childLoc.value });
				}
			});
		});
		setLocationsToSelect(locationsArray);
		setFilter({ ...filter, locations: selectedNodes.map((loc: any) => loc.value) });
	};

	const serviceChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const service = event.target.value as string;
		if (service !== 'all') {
			setFilter({ ...filter, service });
		} else {
			setFilter({ ...filter, service: undefined });
		}
		setServiceSelected(service);
	};

	const valueCodeChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const valueCode = event.target.value as string;
		if (valueCode !== 'all') {
			setFilter({ ...filter, valueCodeId: valueCode });
		} else {
			setFilter({ ...filter, valueCodeId: undefined });
		}
		setValueCodeSelected(valueCode);
	};

	const dataSourceChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const dataSource = event.target.value as string;
		if (dataSource !== 'current') {
			setFilter({ ...filter, dataSource });
		} else {
			setFilter({ ...filter, dataSource: undefined });
		}
		setDataSourceSelected(dataSource);
	};

	const changedDates = (date: any) => {
		if (date !== null) {
			setFilter({ ...filter, ...date });
		} else {
			if (filter.datesRange) {
				delete filter.datesRange;
			}
			setFilter({ ...filter });
		}
	};

	const resetFilter = () => {
		setTransactionTypeSelected('all');
		setLocationsToSelectFunc();
		setCurrencyTypeSelected('all');
		setServiceSelected('all');
		setValueCodeSelected('all');
		setDataSourceSelected('current');
		changedDates(null);
		if (valueStores) setFilter({ status: 'all', valueStores });
		else setFilter({ status: 'all' });
	};

	const onFilterChange = (e: any) => {
		const value = e?.target?.value || '';
		setSearchText(value);
		if (value && value.length > 2) {
			searchInput({
				search: { query: value, fields: ['humanReadableId'] }
			});
		} else {
			searchInput(null);
		}
	};

	useEffect(() => {
		if (search !== null) {
			setFilter({ ...filter, ...search });
		} else {
			setFilter({ ...filter, search: undefined });
		}
	}, [search]);

	useEffect(() => {
		if (filter?.$text) {
			setSearchText(filter?.$text);
		}
	}, [searchText, filter?.$text]);

	useEffect(() => {
		setLocationsToSelectFunc();
	}, [locations]);

	const setLocationsToSelectFunc = () => {
		let locationsArray: any[] = [];
		locationsArray = locations
			.filter((location: any) => location.parentId == null)
			.map((loc: any) => {
				return {
					label: loc.name,
					value: loc.id,
					checked: false,
					children:
						loc.childrenLocations === 0
							? []
							: locations
									.filter((childrenLocation: any) => childrenLocation.parentId === loc.id)
									.map((childLoc: any) => {
										return {
											label: childLoc.name,
											value: childLoc.id,
											checked: false
										};
									})
				};
			});
		setLocationsToSelect(locationsArray);
	};

	return (
		<div className={classes.eventsToolbar}>
			<div className={classes.filterPosition}>
				<SearchFilter
					{...props}
					hideFilter={false}
					className={classes.filter}
					filterValues={[]}
					setFilters={() => []}
				>
					<SearchInput
						variant='outlined'
						size='small'
						source='transactionId'
						alwaysOn
						placeholder='Search by human readable ID'
						className={classes.input}
						onChange={onFilterChange}
						options={{
							value: searchText
						}}
					/>
				</SearchFilter>
				<div className={classes.datePicker}>
					<CustomDatePicker
						fieldKey='datesRange'
						setFilterSearch={changedDates}
						ts={filter.datesRange}
						labelWidth={50}
					/>
				</div>
				<div className={classes.locationContainer}>
					<label className={classes.label}>Locations</label>
					<DropdownTreeSelect
						data={locationsToSelect}
						mode='multiSelect'
						className={classes.locationsSelect}
						onChange={locationChanged}
					/>
				</div>
				<Filter
					id='service_label'
					label='Service'
					value={serviceSelected}
					onChange={serviceChanged}
					labelWidth={60}
				>
					<MenuItem key={`service_all`} value={'all'} selected={true}>
						All
					</MenuItem>
					{services.map((serviceEle: any, index: number) => {
						return (
							<MenuItem key={`status_${index}`} value={serviceEle.id} selected={false}>
								{serviceEle.name}
							</MenuItem>
						);
					})}
				</Filter>
				{valueStoreFilter && (
					<Filter
						id='valuecode_label'
						label='Value Code'
						value={valueCodeSelected}
						onChange={valueCodeChanged}
						labelWidth={85}
					>
						<MenuItem key={`valuecode_all`} value={'all'} selected={true}>
							All
						</MenuItem>
						{valueCodes.map((valuecode: any, index: number) => {
							return (
								<MenuItem key={`valuecode_${index}`} value={valuecode.id} selected={false}>
									{valuecode.humanReadableId}
								</MenuItem>
							);
						})}
					</Filter>
				)}
				<Filter
					id='transactionType_label'
					label='Transaction Type'
					value={transactionTypeSelected}
					onChange={transactionTypeChanged}
					labelWidth={135}
				>
					{transactionType.map((transactionTypeEle: any, index: number) => {
						return (
							<MenuItem
								key={`status_${index}`}
								value={transactionTypeEle.id}
								selected={transactionTypeSelected === transactionTypeEle.id}
							>
								{transactionTypeEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				<Filter
					id='currencyType_label'
					label='Currency Type'
					value={currencyTypeSelected}
					onChange={currencyTypeChanged}
					labelWidth={115}
				>
					{currencyType.map((currencyTypeEle: any, index: number) => {
						return (
							<MenuItem
								key={`status_${index}`}
								value={currencyTypeEle.id}
								selected={transactionTypeSelected === currencyTypeEle.id}
							>
								{currencyTypeEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				{valueStores && (
					<Filter
						id='valuecode_label'
						label='Value Code'
						value={valueCodeSelected}
						onChange={valueCodeChanged}
						labelWidth={85}
					>
						<MenuItem key={`valuecode_all`} value={'all'} selected={true}>
							All
						</MenuItem>
						{valueCodes.map((valuecode: any, index: number) => {
							return (
								<MenuItem key={`valuecode_${index}`} value={valuecode.id} selected={false}>
									{valuecode.humanReadableId}
								</MenuItem>
							);
						})}
					</Filter>
				)}
				<Filter
					id='dataSource_label'
					label='Data Source'
					value={dataSourceSelected}
					onChange={dataSourceChanged}
					labelWidth={100}
				>
					{dataSource.map((dataSourceEle: any, index: number) => {
						return (
							<MenuItem
								key={`dataSource_${index}`}
								value={dataSourceEle.id}
								selected={dataSourceSelected === dataSourceEle.id}
							>
								{dataSourceEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				{showExportBtn && (
					<div className={classes.exportBtnContainer}>
						<Button
							variant='contained'
							size='small'
							startIcon={<GetAppIcon />}
							onClick={onExports}
							classes={{
								root: classes.exportBtnRoot,
								label: classes.exportBtnTxt
							}}
						>
							Export
						</Button>
					</div>
				)}
				{filterChanged && (
					<Button
						classes={{
							root: classes.btn,
							text: classes.btnText
						}}
						onClick={resetFilter}
						endIcon={
							<SvgIcon component={CloseIcon} viewBox='0 0 24 24' className={classes.svgIcon} />
						}
					>
						Reset All Filters
					</Button>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		eventsToolbar: {
			padding: '12px 0px 22px 0px',
			backgroundColor: '#FFFFFF'
		},
		filterPosition: {
			display: 'flex',
			marginLeft: '-16px',
			'& MuiFormControl-root': {
				height: '48px'
			},
			'& .MuiOutlinedInput-root': {
				height: '100%'
			},
			'& .react-datepicker-popper': {
				zIndex: 2
			}
		},
		btn: {
			textTransform: 'none',
			marginLeft: '16px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			borderRadius: '8px',
			height: '48px',
			minWidth: '160px'
		},
		btnText: {
			padding: '6px 14px 6px 8px'
		},
		svgIcon: {
			width: '13px',
			height: '13px'
		},
		filter: {
			display: 'unset',
			backgroundColor: '#ffffff',
			'& .filter-field': {
				margin: '25px -16px 24px 25px',
				height: '43px',
				width: '200px'
			}
		},
		input: {
			'& input': {
				paddingTop: '15px',
				paddingBottom: '15px'
			}
		},
		exportBtnContainer: {
			position: 'absolute',
			right: '30px',
			marginTop: '55px'
		},
		exportBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '1px 0px',
			borderRadius: '8px',
			width: '129px',
			height: '36px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		exportBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		datePicker: {
			marginLeft: '11px'
		},
		locationsSelect: {
			fontFamily: 'Manrope',
			marginLeft: '16px',
			'& a': {
				borderRadius: '8px',
				padding: '7px 16px !important',
				'& input': {
					borderBottom: 'none !important',
					fontSize: '16px',
					fontFamily: 'Manrope',
					fontWeight: 500,
					fontStyle: 'normal',
					lineHeight: '22px'
				}
			},
			'& .dropdown .dropdown-trigger.arrow.bottom:after': {
				fontSize: '17px',
				color: 'rgba(0, 0, 0, 0.54)',
				content: '"\\23F7"'
			},
			'& .dropdown .dropdown-trigger.arrow.top:after': {
				fontSize: '17px',
				color: 'rgba(0, 0, 0, 0.54)',
				content: '"\\23F6"'
			},
			'& .dropdown .dropdown-content li': {
				fontSize: '18px'
			},
			'& .dropdown': {
				width: '260px'
			},
			'& .dropdown .dropdown-content': {
				width: '260px'
			}
		},
		label: {
			fontSize: '13px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			color: '#969696',
			position: 'absolute',
			left: '25px',
			top: '-7px',
			backgroundColor: 'white',
			zIndex: 2,
			padding: '0 3px'
		},
		locationContainer: {
			position: 'relative'
		}
	})
);
