import { makeStyles, createStyles, Theme } from '@material-ui/core';
import uiColor from '../../utils/utilsColors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        roomList: {
            background: 'white',
            marginTop: '16px'
        },
        headerBar: {
            padding: '24px 40px 0 40px',
            minWidth: '1376px'
        },
        navBar: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        navBarLeft: {
            position: 'relative',
            top: '-7px',
            left: '-1px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '-0.5px',
            color: '#969696'
        },
        navBack: {
            '&:hover': {
                cursor: 'pointer'
            }
        },
        navIcon: {
            position: 'relative',
            top: '5px',
            margin: '0px 5px 0px 6px',
            color: '#BDBDBD'
        },
        navLocationName: {
            color: '#5F5F5F'
        },
        locationBar: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '22px'
        },
        locationName: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '44px',
            lineHeight: '113%',
            letterSpacing: '-1px',
            fontWeight: 800,
            color: '#232F64'
        },
        locationInfo: {
            position: 'relative',
            top: '6px',
            marginRight: '144px',
            height: '75px',
            display: 'flex',
            fontFamily: 'Manrope',
            fontStyle: 'normal'
        },
        locationId: {
            fontSize: '18px',
            fontWeight: 'bold',
            lineHeight: '25px',
            marginRight: '37px',
            color: '#232F64',
            width: '227px',
            height: '25px',
            textAlign: 'right',
            letterSpacing: '-0.5px'
        },
        locationInfoDivider: {
            color: '#E0E0E0',
            width: '1px',
            height: '48px'
        },
        locationAddress: {
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '25px',
            color: '#5F5F5F',
            height: '75px',
            marginLeft: '30px'
        },
        addRoomBtnRoot: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            padding: '1px 0px',
            borderRadius: '8px',
            width: '154px',
            height: '36px',
            background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
        },
        addRoomBtnTxt: {
            color: '#FFFFFF',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 'bold',
            letterSpacing: '0.5px'
        },
        addRoomDialogAppBar: {
            position: 'relative',
            backgroundColor: '#FAFAFA',
            minWidth: '1440px',
            boxShadow: '0 0 0',
            borderBottom: '1px solid #E2E3E9'
        },
        addRoomDialogTitle: {
            flex: 1,
            paddingLeft: '8px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '33px',
            letterSpacing: '-0.75px',
            color: '#232F64'
        },
        subtitle: {
            margin: '33px 40px',
            paddingBottom: '10px',
            alignItems: 'center',
            color: '#111111',
            fontWeight: 700,
            fontSize: '18px',
            fontFamily: 'Manrope',
            lineHeight: '25px',
            borderBottom: '1px solid #E2E3E9'
        },
        addRoomBtnDisabled: {
            opacity: 0.3
        },
        addRoomDialogItem: {
            margin: '33px 40px',
            width: '600px',
            display: 'flex',
            alignItems: 'center'
        },
        addValueCodeListItemLabel: {
            width: '40%',
            fontWeight: 500,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111'
        },
        labelWithHelpText: {
            display: 'block',
            fontWeight: 500,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111'
        },
        addValueCodeListItemLabelBold: {
            fontWeight: 600,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111'
        },
        listContainer: {
            marginTop: '15px',
            padding: '0px'
        },
        tabsRoot: {
            borderBottom: '1px solid lightgray',
            padding: '0 40px'
        },
        tabsIndicator: {
            background: '#1695CB'
        },
        tabRoot: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            letterSpacing: '0.75px',
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '25px',
            color: '#969696',
            padding: 0,
            minWidth: '73px',
            marginRight: '32px'
        },
        tabSelected: {
            color: '#1695CB'
        },
        tabPanel: {
            background: '#F2F4FB'
        },
        rooms: {
            margin: '-8px 16px 0px 16px'
        },
        roomsToolbar: {
            position: 'relative'
        },
        roomsBtnDropdownContainer: {
            display: 'inline-block'
        },
        roomsBtnRoot: {
            position: 'relative',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '28px',
            lineHeight: '35px',
            letterSpacing: '-1px',
            color: '#232F64',
            borderRadius: '8px',
            padding: '5px 0px',
            '&:hover': {
                cursor: 'pointer',
                color: '#111111'
            }
        },
        roomsBtnName: {
            fontWeight: 800
        },
        roomsBtnId: {
            fontWeight: 'normal'
        },
        roomsBtnDropdown: {
            width: '24px',
            height: '24px',
            position: 'relative',
            top: '6px'
        },
        popoverPaper: {
            background: '#FFFFFF',
            boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.12)',
            borderRadius: '8px',
            marginLeft: '5px'
        },
        menuItemRoot: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '3px 14px',
            padding: '0px 13px 0px 13px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '40px',
            color: '#111111',
            '&:first-child': {
                marginTop: '13px'
            },
            '&:last-child': {
                marginBottom: '14px'
            },
            '&:hover': {
                borderRadius: '8px',
                background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)'
            }
        },
        menuItemSelected: {
            background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
            borderRadius: '8px'
        },
        svgIcon: {
            position: 'relative',
            top: '3px',
            width: '24px',
            height: '24px'
        },
        editNameBtnRoot: {
            position: 'relative',
            top: '-4px',
            marginLeft: '10px',
            borderRadius: '8px'
        },
        editNameBtnTxt: {
            letterSpacing: '0.5px',
            color: '#969696',
            fontWeight: 'bold'
        },
        machineAtRoomToolbar: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px'
        },
        statusInformation: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            textAlign: 'right',
            letterSpacing: '-0.5px',
            color: '#5F5F5F',
            fontWeight: 'bold'
        },
        labelProblem: {
            height: '23px',
            backgroundColor: uiColor.icon_offline,
            color: uiColor.icon_txt,
            fontFamily: 'Manrope',
            fontSize: 18,
            lineHeight: '25px',
            textAlign: 'center',
            fontWeight: 700,
            borderRadius: '3px',
            marginLeft: '10px',
            letterSpacing: '-0.5px',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem'
        },
        onlineIndicator: {
            height: '8px',
            width: '8px',
            backgroundColor: uiColor.icon_online,
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0px 7px 0px 10px',
            position: 'relative',
            top: '-2px'
        },
        onlineTxt: {
            color: uiColor.icon_online,
            fontWeight: 500
        },
        offlineIndicator: {
            height: '8px',
            width: '8px',
            backgroundColor: uiColor.icon_offline,
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0px 7px 0px 10px',
            position: 'relative',
            top: '-2px'
        },
        offlineTxt: {
            color: uiColor.icon_offline,
            fontWeight: 500
        },
        dividerBeforeMachines: {
            width: '1px',
            height: '32px',
            color: '#E2E3E9',
            marginLeft: '20px'
        },
        machinesInfoLabel: {
            paddingLeft: '21px',
            display: 'flex',
            flexDirection: 'column'
        },
        machinesInfo: {
            marginLeft: '5px',
            marginRight: 0,
            color: '#232F64'
        },
        clearAllCoinBoxesBtnRoot: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            border: '1px solid white',
            width: '213px',
            height: '36px',
            background: 'white'
        },
        clearAllCoinBoxesBtnLabel: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            letterSpacing: '0.5px',
            color: '#1695CB',
            fontSize: '16px',
            lineHeight: '22px'
        },
        clearAllCoinDialogPaper: {
            background: '#FFFFFF',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px'
        },
        clearAllCoinDialogContentTxt: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            lineHeight: '30px',
            letterSpacing: '-0.75px',
            color: '#000000',
            fontSize: '22px',
            fontWeight: 'bold',
            textAlign: 'center',
            margin: '32px 14px 33px 14px'
        },
        clearAllCoinDialogActions: {
            backgroundColor: '#EEEFF3',
            justifyContent: 'center',
            padding: '24px 40px'
        },
        cancelBtnRoot: {
            background: '#FFFFFF',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            width: '114px',
            height: '36px'
        },
        addCuurencyTypeBtnRoot: {
            background: '#FFFFFF',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            width: '233px',
            height: '36px'
        },
        cancelBtnTxt: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.5px',
            color: '#5F5F5F'
        },
        yesBtnRoot: {
            marginLeft: '16px',
            background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            width: '150px',
            height: '40px'
        },
        nextBtnRoot: {
            marginLeft: '16px',
            background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            width: '92px',
            height: '40px'
        },
        yesBtnTxt: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.5px',
            color: '#FFFFFF'
        },
        closeBtnRoot: {
            background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            width: '214px',
            height: '36px'
        },
        moreBtnRoot: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            border: '1px solid white',
            width: '36px',
            height: '36px',
            background: 'white',
            marginLeft: '16px',
            color: '#1695CB'
        },
        machinesContainer: {
            marginTop: '6px'
        },
        machinesCardRoot: {
            borderRadius: '8px',
            background: '#FFFFFF',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            padding: '0 40px'
        },
        machinesCardRounded: {
            borderRadius: '8px',
            '&:first-child': {
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px'
            },
            '&:last-child': {
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px'
            }
        },
        machinesCardTitleRoot: {
            borderBottom: '1px solid #E0E0E0',
            padding: 0
        },
        heading: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '22px',
            lineHeight: '30px',
            fontWeight: 'bold',
            letterSpacing: '-0.75px',
            color: '#232F64'
        },
        machinesCardDetails: {
            margin: '24px 0 40px 0',
            padding: 0
        },
        machineCardOnline: {
            borderRadius: '8px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
            border: '1px solid #EEEFF3'
        },
        machineCardOffline: {
            borderRadius: '8px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
            background: '#E4E5EB'
        },
        machineConnectedOn: {
            position: 'relative',
            top: '12px',
            color: '#1695CB'
        },
        machineConnectedOff: {
            position: 'relative',
            top: '12px',
            color: '#F86D6D'
        },
        machineConnectedIconOn: {
            height: '8px',
            width: '8px',
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0px 10px',
            backgroundColor: '#1695CB'
        },
        machineConnectedIconOff: {
            height: '8px',
            width: '8px',
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0px 10px',
            backgroundColor: '#F86D6D'
        },
        machineConnectedTxt: {
            marginRight: '10px',
            position: 'relative',
            top: '1px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '22px'
        },
        machineNumber: {
            fontSize: '24px',
            fontWeight: 800,
            lineHeight: '33px',
            color: '#232F64',
            letterSpacing: '-0.75px'
        },
        machineID: {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '22px',
            color: '#5F5F5F',
            position: 'relative',
            top: '-3px',
            left: '10px'
        },
        gridList: {
            width: '100%',
            height: '100%'
        },
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper
        },
        dividerSpace: {
            fontWeight: 'bold',
            paddingRight: '20px'
        },
        selectRoot: {
            fontWeight: 'bold',
            fontSize: '20px',
            color: uiColor.sidebar_bk
        },
        selectIcon: {
            color: uiColor.sidebar_bk
        },
        cardHeader: {
            padding: '5px 16px'
        },
        cardHeaderOnline: {
            padding: '5px 16px',
            borderBottom: '1px solid lightgray'
        },
        cardHeaderOffline: {
            padding: '5px 16px',
            borderBottom: '1px solid white'
        },
        machineInfoItem: {
            position: 'relative',
            width: '100%',
            display: 'flex',
            fontSize: '16px',
            marginBottom: '5px'
        },
        machineInfoTitle: {
            color: '#5F5F5F',
            width: '40%'
        },
        machineStatus: {
            padding: '2px 5px',
            color: 'white',
            borderRadius: '2px',
            fontWeight: 'bold'
        },
        notDisplay: {
            display: 'none'
        },
        machineRunning: {
            padding: '2px 5px',
            color: 'white',
            borderRadius: '2px',
            fontWeight: 'bold',
            background: '#1695CB'
        },
        machineIdle: {
            padding: '2px 5px',
            color: 'white',
            borderRadius: '2px',
            fontWeight: 'bold',
            background: '#969696'
        },
        machineStartMode: {
            padding: '2px 5px',
            color: 'white',
            borderRadius: '2px',
            fontWeight: 'bold',
            background: '#3BA359'
        },
        machineTimeRemain: {
            fontSize: '14px',
            marginLeft: '5px'
        },
        machineError: {
            fontSize: '14px',
            marginLeft: '5px'
        },
        machineCoins: {
            fontWeight: 'bold',
            color: 'black'
        },
        machineCoinsMax: {
            fontWeight: 'bold',
            color: '#F86D6D'
        },
        machineInfoContent: {
            fontWeight: 'bold',
            color: 'black'
        },
        machineMoreBtn: {
            position: 'absolute',
            right: '0px',
            top: '-5px',
            color: '#969696',
            fontSize: '12px',
            fontWeight: 'bold'
        },
        NavigateNextIcon: {
            position: 'relative',
            left: '-8px',
            top: '-1px',
            width: '23px',
            height: '23px',
            color: '#969696'
        },
        hardwareInfoTitle: {
            color: '#5F5F5F',
            width: '45%'
        },
        pricingTabRoot: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            letterSpacing: '0.75px',
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '25px',
            padding: 0,
            minWidth: '90px',
            marginRight: '40px'
        },
        tabColorInherit: {
            color: '#969696'
        },
        pricingTabsRoot: {
            padding: '0 24px'
        },
        pricingTabsIndicator: {
            backgroundColor: '#F2F4FB'
        },
        pricingMachines: {
            position: 'relative'
        },
        pricingWalletFees: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center'
        },
        cardRoot: {
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px'
        },
        machinesPricingCardTitle: {
            fontWeight: 'bold',
            fontSize: '22px',
            lineHeight: '30px',
            letterSpacing: '-0.75px',
            color: '#232F64'
        },
        walletFeesCardTitle: {
            fontWeight: 'bold',
            fontSize: '22px',
            lineHeight: '30px',
            letterSpacing: '-0.75px',
            color: '#232F64'
        },
        cardHeaderRoot: {
            padding: '32px 40px 15px 40px',
            margin: 0
        },
        walletFeesCardHeaderRoot: {
            padding: '32px 40px 15px 40px',
            margin: 0
        },
        cardContentRoot: {
            padding: '0 40px',
            margin: 0
        },
        listRoot: {
            paddingTop: 0,
            PaddingBottom: 0
        },
        listItemRoot: {
            padding: 0,
            margin: 0,
            alignItems: 'flex-start'
        },
        listItemIconRoot: {
            paddingTop: '16px',
            width: '40px',
            height: '40px'
        },
        pricingFeesIconRoot: {
            paddingTop: '17px',
            width: '40px',
            height: '40px'
        },
        listItemTextRoot: {
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: 0,
            marginBottom: 0
        },
        pricingItem: {
            width: '100%',
            display: 'flex',
            paddingTop: '24px',
            paddingBottom: '24px'
        },
        pricingItemEdit: {
            width: '100%',
            display: 'flex',
            paddingTop: '8px',
            paddingBottom: '17px'
        },
        pricingPrimary: {
            lineHeight: '25px',
            fontWeight: 'bold',
            fontSize: '18px',
            color: '#111111',
            width: '40%'
        },
        pricingPrimaryEdit: {
            marginTop: '15px',
            lineHeight: '25px',
            fontWeight: 'bold',
            fontSize: '18px',
            color: '#111111',
            width: '40%'
        },
        pricingSecondary: {
            fontSize: '18px',
            lineHeight: '28px',
            fontWeight: 500,
            color: '#111111',
            width: '30%'
        },
        pricingSecondaryEdit: {
            fontSize: '18px',
            lineHeight: '54px',
            fontWeight: 500,
            color: '#111111',
            width: '30%'
        },
        pricingPrice: {
            color: '#3BA359',
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '28px',
            width: '30%',
            textAlign: 'right'
        },
        editMachinesPricesDialogPaper: {
            width: '600px',
            borderRadius: '8px'
        },
        editMachinesPricesRootWithoutMargin: {
            marginTop: '5px',
            paddingTop: 0,
            boxSizing: 'border-box',
            borderRadius: '8px',
            height: '42px',
            width: '122px'
        },
        editMachinesPricesRoot: {
            marginTop: '5px',
            paddingTop: 0,
            marginBottom: '7px',
            boxSizing: 'border-box',
            borderRadius: '8px',
            height: '42px',
            width: '122px',
            paddingRight: '16px'
        },
        pricingPlus: {
            color: '#111111',
            marginRight: '12px',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '54px'
        },
        inputAdornmentStart: {
            fontWeight: 'bold',
            color: '#969696',
            mixBlendMode: 'normal'
        },
        inputAdornmentEnd: {
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: '16px',
            color: '#969696',
            mixBlendMode: 'normal'
        },
        editMachinesPricesInput: {
            padding: '8px 0px',
            width: '70px',
            fontWeight: 500,
            color: '#111111',
            mixBlendMode: 'normal'
        },
        addTimeIncrementInput: {
            padding: '8px 0px 8px 16px'
        },
        walletFeesCardRoot: {
            marginTop: '0px',
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            width: '632px',
            height: '582px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)'
        },
        pricingFeesItem: {
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 'bold',
            paddingTop: '25px',
            paddingBottom: '51px'
        },
        events: {
            margin: '-8px 16px 0 16px'
        },
        eventsToolbar: {
            display: 'flex',
            padding: '12px 0px 22px 0px',
            position: 'relative',
            left: '-16px'
        },
        datesPickerContainer: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        datesPicker: {
            marginRight: '15px',
            width: '220px'
        },
        eventsFilterItem: {
            marginRight: '16px',
            minWidth: '193px',
            height: '42px'
        },
        label: {
            fontSize: '16px',
            lineHeight: '22px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            color: '#969696'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff'
        },
        transactionFeesLabel: {
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#111111',
            letterSpacing: '-0.5px'
        },
        transactionFeesSwitchThumb: {
            backgroundColor: '#5F5F5F',
            width: '20px',
            height: '20px',
            borderRadius: '10px',
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)'
        },
        transactionFeesSwitchTrack: {
            width: '34px',
            height: '14px',
            backgroundColor: '#5F5F5F',
            opacity: 0.38,
            borderRadius: '7px'
        },
        machinesCard: {
            borderRadius: '8px',
            background: '#FFFFFF',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)'
        },
        locationPropertiesContainer: {
            padding: '17px 16px',
            width: '400px',
            position: 'relative'
        },
        filterItem: {
            marginRight: '16px',
            minWidth: '193px',
            height: '42px',
            '& label.MuiFormLabel-root.Mui-focused': {
                color: '#1695CB',
                fontWeight: 'bold'
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#1695CB'
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#1695CB'
                }
            }
        },
        inputItem: {
            minWidth: '330px',
            marginBottom: '24px',
            '& label.MuiFormLabel-root.Mui-focused': {
                color: '#1695CB',
                fontWeight: 'bold'
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#1695CB'
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#1695CB'
                }
            }
        },
        filterLabel: {
            fontSize: '16px',
            lineHeight: '22px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            color: '#969696'
        },
        inputLabel: {
            fontSize: '16px',
            lineHeight: '22px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 500,
            color: '#969696'
        },
        labelFocused: {
            color: '#1695CB',
            fontWeight: 'bold'
        },
        inputSelectRoot: {
            padding: '13px 16px'
        },
        inputSelectOutlined: {
            borderRadius: '8px'
        },
        inputSelectSelected: {
            '&:focus': {
                background: '#F2F4FB'
            }
        },
        locationPropertyToolbar: {
            paddingLeft: '5px'
        },
        locationPropertyDivider: {
            width: '1px',
            height: '460px',
            background: '#E0E0E0',
            position: 'absolute',
            top: '20px',
            left: '440px'
        },
        locationPropertyIdentification: {
            position: 'absolute',
            top: '20px',
            left: '506px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            minWidth: '420px'
        },
        IdentificationPrimary: {
            fontWeight: 800,
            fontSize: '28px',
            lineHeight: '35px',
            letterSpacing: '-1px',
            color: '#232F64'
        },
        IdentificationSecondary: {
            fontWeight: 'bold',
            fontSize: '22px',
            lineHeight: '30px',
            letterSpacing: '-0.75px',
            color: '#111111',
            marginTop: '24px'
        },
        IdentificationContent: {
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '25px',
            color: '#5F5F5F',
            marginTop: '4px'
        },
        errorMsg: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: 'red',
            margin: '10px 19px',
            textAlign: 'center'
        },
        backIcon: {
            color: '#969696',
            cursor: 'pointer'
        },
        dividerContainer: {
            display: 'flex',
            flex: 6
        },
        divider: {
            borderLeft: '1px solid #E0E0E0',
            paddingLeft: '15px',
            color: 'grey',
            background: 'none',
            height: '33px'
        },
        request: {
            fontSize: '22px',
            color: '#232F64',
            marginLeft: '10px',
            fontWeight: 500
        },
        labelTextContainer: {
            position: 'relative',
            width: '240px'
        },
        helpText: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: 500,
            color: '#969696'
        },
        amountLabel: {
            position: 'absolute',
            left: '0',
            fontWeight: 500,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111'
        },
        selectService: {
            width: '60%'
        },
        subtitleValueCode: {
            alignItems: 'center',
            color: '#111111',
            fontWeight: 700,
            fontSize: '18px',
            fontFamily: 'Manrope',
            lineHeight: '25px',
            width: '250px'
        },
        codeInformationContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            margin: '33px 126px 0 126px',
            flexDirection: 'row'
        },
        valueCodesItemLabel: {
            fontWeight: 500,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111',
            width: '250px'
        },
        valueCodesItemText: {
            fontWeight: 700,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111',
            width: '400px'
        },
        statusButton: {
            marginLeft: 'auto'
        },
        status: {
            color: '#2D7D44',
            backgroundColor: '#E9F7ED',
            fontWeight: 500,
            padding: '3px 10px'
        },
        active: {
            color: '#2D7D44',
            backgroundColor: '#E9F7ED',
            fontWeight: 500,
            padding: '3px 10px'
        },
        inactive: {
            color: '#5F5F5F',
            backgroundColor: '#EDEDED',
            fontWeight: 500,
            padding: '3px 10px'
        },
        statusBtnRoot: {
            background: '#FFFFFF',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
            width: '110px',
            height: '30px',
            cursor: 'default'
        },
        statusBtnTxt: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.5px',
            color: '#5F5F5F',
            textTransform: 'capitalize'
        },
        arrowBtnRoot: {
            background: '#FFFFFF',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
            width: '20px',
            height: '36px',
            display: 'inline',
            padding: '7px'
        },
        activeIndicator: {
            height: '8px',
            width: '8px',
            background: '#3BA359',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '7px',
            marginBottom: '1px'
        },
        inactiveIndicator: {
            height: '8px',
            width: '8px',
            background: '#EB5757',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '7px',
            marginBottom: '1px'
        },
        listContainerTransactions: {
            width: 'auto',
            marginLeft: 0,
            borderTop: '1px solid #E2E3E9',
            background: '#F2F4FB'
        },
        listRootTransactions: {
            margin: '24px 40px 0px 40px'
        },
        listContent: {
            boxShadow: '0 0 0 white',
            background: '#F2F4FB'
        },
        table: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            background: '#FFFFFF',
            minWidth: '580px'
        },
        thead: {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px'
        },
        tbody: {
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px'
        },
        headerCell: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '-0.5px',
            color: '#111111',
            padding: '20px 24px',
            width: '200px',
            '&:first-child': {
                width: '400px'
            }
        },
        gridWrapper: {
            display: 'flex',
            margin: '0px 40px'
        },
        divider2: {
            background: '#D0D5E8',
            height: '20px',
            marginLeft: '26px',
            marginRight: '26px',
            paddingBottom: '5px'
        },
        viewValueCodeDialogTitle: {
            paddingLeft: '8px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '33px',
            letterSpacing: '-0.75px',
            color: '#232F64'
        },
        viewValueCodeDialogSubtitle: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '22px',
            lineHeight: '30px',
            letterSpacing: '-0.5px',
            color: '#232F64'
        },
        transactionsPanel: {
            padding: '0'
        },
        valueCodesItemTextBlue: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '25px',
            width: '400px',
            color: '#1695CB',
            fontWeight: 'bold',
            letterSpacing: '-0.5px',
            cursor: 'pointer'
        },
        exportBtnContainer: {
            marginLeft: 'auto'
        },
        exportBtnRoot: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            padding: '1px 0px',
            borderRadius: '8px',
            width: '129px',
            height: '36px',
            background: '#FFFFFF'
        },
        exportBtnTxt: {
            color: '#1695CB',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 'bold',
            letterSpacing: '0.5px'
        },
        myMenu: {
            flexGrow: 1,
            borderRight: '1px solid #E0E0E0',
            minHeight: 'calc(100vh - 65px)',
            background: 'white',
            paddingTop: '20px'
        },
        steps: {
            flexGrow: 8,
            background: '#FAFAFA'
        },
        stepActive: {
            height: '40px',
            width: '40px',
            backgroundColor: uiColor.secondary,
            borderRadius: '50%',
            display: 'inline-block',
            color: 'white',
            fontWeight: 500,
            fontSize: '18px',
            textAlign: 'center',
            lineHeight: 2,
            marginRight: '10px',
            cursor: 'pointer',
            fontFamily: 'Manrope',
            marginLeft: '20px',
            background: 'none',
            border: 'none'
        },
        stepInactive: {
            height: '40px',
            width: '40px',
            backgroundColor: uiColor.hover,
            borderRadius: '50%',
            display: 'inline-block',
            color: uiColor.inactive,
            fontWeight: 500,
            fontSize: '18px',
            textAlign: 'center',
            lineHeight: 2,
            marginRight: '10px',
            cursor: 'pointer',
            fontFamily: 'Manrope',
            marginLeft: '20px',
            background: 'none',
            border: 'none'
        },
        showLayout: {
            padding: 0,
            display: 'flex',
            flexDirection: 'row'
        },
        stepButtonActive: {
            background: 'none',
            border: 'none',
            padding: '0',
            cursor: 'pointer',
            color: uiColor.secondary,
            fontWeight: 600,
            fontFamily: 'Manrope',
            fontSize: '16px'
        },
        stepButtonInactive: {
            background: 'none',
            border: 'none',
            padding: '0',
            cursor: 'pointer',
            color: uiColor.inactive,
            fontWeight: 600,
            fontFamily: 'Manrope',
            fontSize: '16px'
        },
        stepDividerActive: {
            borderLeft: '3px solid #1695CB',
            height: '24px',
            margin: '10px 0 10px 40px'
        },
        stepDividerInactive: {
            borderLeft: '3px solid #969696',
            height: '24px',
            margin: '10px 0 10px 40px'
        },
        hide: {
            display: 'none'
        },
        deleteBtnRoot: {
            background: '#FFFFFF',
            borderRadius: '8px',
            width: '114px',
            height: '36px',
            border: 'none',
            position: 'absolute',
            right: '40px',
            bottom: '120px'
        },
        deleteBtnTxt: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.5px',
            color: '#EB5757'
        },
        deleteContainer: {
            position: 'relative'
        },
        locationsSelect: {
            fontFamily: 'Manrope',
            '& a': {
                borderRadius: '8px',
                padding: '7px 16px !important',
                maxHeight: '36px !important',
                '& input': {
                    borderBottom: 'none !important',
                    fontSize: '16px',
                    fontFamily: 'Manrope',
                    fontWeight: 500,
                    fontStyle: 'normal',
                    lineHeight: '22px',
                    width: '293px',
                    backgroundColor: '#FAFAFA'
                }
            },
            '& .dropdown .dropdown-trigger.arrow.bottom:after': {
                fontSize: '17px',
                color: 'rgba(0, 0, 0, 0.54)',
                content: '"\\23F7"'
            },
            '& .dropdown .dropdown-trigger.arrow.top:after': {
                fontSize: '17px',
                color: 'rgba(0, 0, 0, 0.54)',
                content: '"\\23F6"'
            },
            '& .dropdown .dropdown-content li': {
                fontSize: '18px'
            },
            '& .dropdown .dropdown-content': {
                zIndex: 2
            },
            '& .dropdown': {
                maxWidth: '360px'
            }
        },
        calendar: {
            backgroundColor: '#ffffff',
            border: 'none'
        }
    })
);

export default useStyles;
