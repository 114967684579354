import React from 'react';
import { List, TextField, DateField } from 'react-admin';
import { useStyles } from './valueCodeListsStyles';
import { MyShowButton, MyDatagrid } from '../common/myDataGrid/MyDatagrid';
import { EmptyGrid } from '../common/EmptyGrid';
import { ColumnCustom } from '../common/ColumnCustom';
import CustomPagination from '../common/PaginationCustom';

export const ValueCodeLists = (props: any) => {
	const classes = useStyles();
	const [search] = React.useState('');

	return (
		<>
			<div className={classes.listContainer}>
				<List
					{...props}
					bulkActionButtons={false}
					empty={<EmptyGrid />}
					sort={{ field: 'name', order: 'ASC' }}
					filter={{ ...props.filter }}
					actions={null}
					pagination={<CustomPagination />}
					perPage={10}
					syncWithLocation
					classes={{
						root: classes.listRoot,
						content: classes.listContent
					}}
				>
					<MyDatagrid
						hover={true}
						resource='valuecodelists'
						classes={{
							table: classes.table,
							thead: classes.thead,
							tbody: classes.tbody,
							headerCell: classes.headerCell,
							rowOdd: classes.rowOdd
						}}
						hasRowClick={true}
						filter={{ key: 'label', value: `${search}` }}
					>
						<TextField label='List ID' source='id' />
						<TextField label='List Name' source='name' />
						<TextField label='Location' source='location.name' className={classes.location} />
						<TextField label='Service' source='service.name' />
						<DateField label='Date Created' source='createdOn' showTime />
						<DateField label='Last Updated' source='lastUpdated' showTime />
						<ColumnCustom label='Status' />
						<TextField label='Total Codes' source='totalCodes' />
						<MyShowButton />
					</MyDatagrid>
				</List>
			</div>
		</>
	);
};

export default ValueCodeLists;
