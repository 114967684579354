import React from 'react';
import { Select, InputLabel, FormControl, makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

export const Filter = (props: any) => {
	const classes = useStyles();

	const getFilterWidth = (label: string) => {
		if (label === 'Reason') {
			return classes.reasonWidth;
		}

		if (label === 'Payment Type') {
			return classes.paymentTypeWidth;
		}

		return classes.filterWidth;
	};

	return (
		<FormControl
			{...props}
			variant='outlined'
			size='small'
			className={clsx(classes.eventsFilterItem, getFilterWidth(props.label))}
		>
			<InputLabel id={props.id} classes={{ formControl: classes.label }}>
				{props.label}
			</InputLabel>
			<Select
				labelWidth={props.labelWidth || 70}
				labelId={props.id}
				value={props.value}
				onOpen={props.onOpen}
				onChange={props.onChange}
				classes={{
					root: classes.inputSelectRoot,
					outlined: classes.inputSelectOutlined,
					select: classes.inputSelectSelected
				}}
			>
				{props.children}
			</Select>
		</FormControl>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		eventsFilterItem: {
			height: '48px',
			'& label.MuiFormLabel-root.Mui-focused': {
				color: '#1695CB'
			},
			'& .MuiOutlinedInput-root': {
				'&:hover fieldset': {
					borderColor: '#1695CB'
				},
				'&.Mui-focused fieldset': {
					borderColor: '#1695CB'
				}
			}
		},
		reasonWidth: {
			minWidth: '277px'
		},
		filterWidth: {
			minWidth: '165px'
		},
		paymentTypeWidth: {
			minWidth: '185px'
		},
		label: {
			fontSize: '18px',
			lineHeight: '22px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'normal',
			color: '#969696'
		},
		inputSelectRoot: {
			padding: '12px 16px'
		},
		inputSelectOutlined: {
			borderRadius: '8px',
			'&.MuiSelect-outlined': {
				paddingRight: '48px'
			}
		},
		inputSelectSelected: {
			'&:focus': {
				background: '#F2F4FB'
			}
		}
	})
);
