import React from 'react';
import { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomContainerProps } from './CustomDatePickerProps';
import useStyles from './useStyle';

const CustomContainer = (props: CustomContainerProps) => {
	const classes = useStyles();
	return (
		<div className={classes.customContainer}>
			<CalendarContainer className={props.className}>{props.children}</CalendarContainer>
		</div>
	);
};

export default CustomContainer;
