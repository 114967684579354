import React, { useState, useEffect } from 'react';
import { useNotify, useDataProvider } from 'react-admin';
import { Button, Container, Box, Typography, Grid } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import { useStyles } from './permissionsStyles';
import { stringFirstCharUpperCase } from '../../utils/util';
import { EditRoleDialog } from './Dialogs/EditRoleDialog';
import { hasEdit } from '../../utils/permissions';

export const RoleDetailShow = (props: any) => {
	const classes = useStyles();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const { record } = props;
	const [serviceName, setServiceName] = useState('');
	const [locationName, setLocationName] = useState('');
	const [valueCodeListName, setValueCodeListName] = useState('');
	const [serviceId, setServiceId] = useState('');
	const [locationId, setLocationId] = useState('');
	const [valueCodeListId, setValueCodeListId] = useState('');
	const [roleName, setRoleName] = useState<any>('');
	const [roleId, setRoleId] = useState<any>('');
	const [isApplication, setIsApplication] = useState<any>('');
	const [token, setToken] = useState<any>('');
	const [auth0Scope, setAuth0Scope] = useState<any>('');
	// eslint-disable-next-line no-undef
	const [permissions, setPermissions] = useState<permissions[]>([]);
	const [openEditRoleDialog, setOpenEditRoleDialog] = useState(false);

	const retrieveService = (id: any) => {
		setServiceId(id);
		dataProvider.getOne('services', { id: id }).then(({ data }) => {
			setServiceName(data.name);
		});
	};

	const retrieveValueCodeList = (id: any) => {
		setValueCodeListId(id);
		dataProvider.getOne('valuecodelists', { id: id }).then(({ data }) => {
			setValueCodeListName(data.name);
		});
	};

	const retrieveLocation = (id: any) => {
		setLocationId(id);
		dataProvider.getOne('locations', { id: id }).then(({ data }) => {
			setLocationName(data.name);
		});
	};

	useEffect(() => {
		if (record && record.id) {
			dataProvider
				.getOne('roles', { id: record.id })
				.then(({ data }) => {
					setRoleName(data.name);
					setRoleId(data.id);
					if (data.permissionsJSON !== undefined) {
						data.permissionsJSON.sort((a: any, b: any) => a.type.localeCompare(b.type));
					}
					setPermissions(data.permissionsJSON);
					setToken(data.token);
					setAuth0Scope(data.auth0Scope);
					setIsApplication(data.isApplication);
				})
				.catch(() => {
					notify('Role not found', 'warning');
				});
		}
	}, [record]);

	const permissionsText = (permission: any) => {
		return (
			<div>
				{permission.permissions.create === true ? (
					<div className={classes.permissionsText}>
						<Check style={{ fontSize: 18, color: '#3BA359', marginRight: 15 }} />
						<span className={classes.valueCodesItemText}>Create</span>
					</div>
				) : (
					<div></div>
				)}
				{permission.permissions.view === true ? (
					<div className={classes.permissionsText}>
						<Check style={{ fontSize: 18, color: '#3BA359', marginRight: 15 }} />
						<span className={classes.valueCodesItemText}>View</span>
					</div>
				) : (
					<div></div>
				)}
				{permission.permissions.edit === true ? (
					<div className={classes.permissionsText}>
						<Check style={{ fontSize: 18, color: '#3BA359', marginRight: 15 }} />
						<span className={classes.valueCodesItemText}>Edit</span>
					</div>
				) : (
					<div></div>
				)}
				{permission.permissions.delete === true ? (
					<div className={classes.permissionsText}>
						<Check style={{ fontSize: 18, color: '#3BA359', marginRight: 15 }} />
						<span className={classes.valueCodesItemText}>Delete</span>
					</div>
				) : (
					<div></div>
				)}
			</div>
		);
	};

	const editRoleClicked = () => {
		setOpenEditRoleDialog(true);
	};

	const editRoleDialogClosed = () => {
		setOpenEditRoleDialog(false);
	};

	const permissionId = (permission: any) => {
		if (
			(permission.type === 'valuecodelists' || permission.type === 'valueCodeLists') &&
			permission.id &&
			permission.id !== valueCodeListId
		) {
			retrieveValueCodeList(permission.id);
		}
		if (permission.type === 'services' && permission.id && permission.id !== serviceId) {
			retrieveService(permission.id);
		}
		if (permission.type === 'locations' && permission.id && permission.id !== locationId) {
			retrieveLocation(permission.id);
		}
		return permission.type === 'services' && permission.id ? (
			<div className={classes.tokenText}>
				<span className={classes.serviceItemLabel}>Service</span>
				<span className={classes.valueCodesItemText}>{serviceName}</span>
			</div>
		) : permission.id &&
		  (permission.type === 'valuecodelists' || permission.type === 'valueCodeLists') ? (
			<div className={classes.tokenText}>
				<span className={classes.tokenItemLabel}>Value Code List</span>
				<span className={classes.valueCodesItemText}>{valueCodeListName}</span>
			</div>
		) : permission.id && permission.type === 'locations' ? (
			<div className={classes.tokenText}>
				<span className={classes.locationItemLabel}>Location</span>
				<span className={classes.valueCodesItemText}>{locationName}</span>
			</div>
		) : (
			''
		);
	};

	return (
		<Container>
			<Grid container className={classes.gridContainer}>
				<Grid item xs={6} className={classes.detailLabelGrid}>
					<Typography className={classes.searchAnyFieldTitle}>{roleName}</Typography>
					{roleName !== '' && hasEdit(null, 'users') ? (
						<div className={classes.editRoleButton}>
							<Button
								variant='contained'
								size='small'
								onClick={editRoleClicked}
								classes={{
									root: classes.createBtnRoot,
									label: classes.createBtnTxt
								}}
							>
								Edit Role
							</Button>
						</div>
					) : (
						<span></span>
					)}
					{record && record.id ? (
						<div>
							<div className={classes.permissionsText}>
								<span className={classes.appItemText}>Application</span>
								{isApplication ? (
									<Check style={{ fontSize: 18, color: '#3BA359', marginLeft: 15 }} />
								) : (
									<Clear style={{ fontSize: 18, color: '#c90606', marginLeft: 15 }} />
								)}
							</div>
							{isApplication ? (
								<div className={classes.tokenText}>
									<span className={classes.tokenItemLabel}>Token</span>
									<span className={classes.tokenItemText}>{token}</span>
								</div>
							) : (
								<div className={classes.tokenText}>
									<span className={classes.tokenItemLabel}>Auth0 Scope</span>
									<span className={classes.tokenItemText}>{auth0Scope}</span>
								</div>
							)}
						</div>
					) : (
						<></>
					)}
				</Grid>
			</Grid>
			{permissions.map((permission: any, index: number) => {
				return permission.permissions.view === true ||
					permission.permissions.edit === true ||
					permission.permissions.create === true ||
					permission.permissions.delete === true ? (
					<div
						className={
							index === permissions.length - 1
								? classes.searchAnyFieldBoxLast
								: classes.searchAnyFieldBox
						}
					>
						<Box flex={12} className={classes.searchFieldBox}>
							<Box display='flex'>
								<Box flex={12}>
									<div className={classes.permissionsText}>
										<span className={classes.valueCodesItemLabel}>
											{permission.type === 'valueCodeLists'
												? 'Value Code Lists'
												: permission.type === 'valuecodelists'
												? 'Value Code Lists'
												: permission.type === 'valueCodes'
												? 'Value Codes'
												: permission.type === 'valuecodes'
												? 'Value Codes'
												: permission.type === 'valueStores'
												? 'Value Stores'
												: permission.type === 'valuestores'
												? 'Value Stores'
												: stringFirstCharUpperCase(permission.type)}
										</span>
									</div>
									{permissionId(permission)}
									{permissionsText(permission)}
								</Box>
							</Box>
						</Box>
					</div>
				) : (
					<span></span>
				);
			})}
			{roleName !== '' && hasEdit(null, 'users') ? (
				<EditRoleDialog
					openEditRole={openEditRoleDialog}
					editRoleClosed={editRoleDialogClosed}
					roleId={roleId}
					roleName={roleName}
					permissions={permissions}
					isApplication={isApplication}
					token={token}
					auth0Scope={auth0Scope}
				/>
			) : (
				<></>
			)}
		</Container>
	);
};
