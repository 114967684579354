import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, MenuItem, SvgIcon, Button, Menu } from '@material-ui/core';
import { Filter } from './Filter';
import CustomDatePicker from '../common/customDatePicker/CustomDatePicker';
import { CODES_STATUS, CODES_EXPIRED } from './values';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import {
	SearchInput,
	fetchUtils,
	useRefresh,
	useNotify,
	HttpError,
	Filter as SearchFilter
} from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

export const ValueCodesFilters = ({ setFilter, filter, onExports }: any, props: any) => {
	const httpClient = fetchUtils.fetchJson;
	const classes = useStyles();
	const refresh = useRefresh();
	const notify = useNotify();
	const [statusSelected, setStatusSelected] = useState('all');
	const [expiredSelected, setExpiredSelected] = useState('false');
	const [status] = useState<any>(CODES_STATUS);
	const [expired] = useState<any>(CODES_EXPIRED);
	const [filterChanged, setFilterChanged] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [searchText, setSearchText] = useState('');
	const [search, searchInput] = useState<Object | null>({});
	const token = localStorage.getItem('cscvc_token') as string;
	const apiUrl = process.env.REACT_APP_API_URL as string;

	useEffect(() => {
		if (
			(filter?.status === undefined || filter?.status === 'all') &&
			filter?.expired === undefined &&
			filter?.createdOnRange === undefined &&
			filter?.lastUpdatedRange === undefined &&
			filter?.expirationDateRange === undefined
		) {
			setFilterChanged(false);
		} else {
			setFilterChanged(true);
		}
	}, [filter]);

	const statusChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const status = event.target.value as string;
		if (status !== 'all') {
			setFilter({ ...filter, status });
		} else {
			setFilter({ ...filter, status: undefined });
		}
		setStatusSelected(status);
	};

	const expiredChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const expired = event.target.value as string;
		setFilter({ ...filter, expired });
		setExpiredSelected(expired);
	};

	const changedCreatedOnDates = (date: any) => {
		if (date !== null) {
			setFilter({ ...filter, ...date });
		} else {
			if (filter.createdOnRange) {
				delete filter.createdOnRange;
			}
			setFilter({ ...filter });
		}
	};

	const changedLastUpdatedDates = (date: any) => {
		if (date !== null) {
			setFilter({ ...filter, ...date });
		} else {
			if (filter.lastUpdatedRange) {
				delete filter.lastUpdatedRange;
			}
			setFilter({ ...filter });
		}
	};

	const changedExpirationDates = (date: any) => {
		if (date !== null) {
			setFilter({ ...filter, ...date });
		} else {
			if (filter.expirationDateRange) {
				delete filter.expirationDateRange;
			}
			setFilter({ ...filter });
		}
	};

	const resetFilter = () => {
		setStatusSelected('all');
		setExpiredSelected('false');
		changedCreatedOnDates(null);
		changedLastUpdatedDates(null);
		changedExpirationDates(null);
		setFilter({ ValueCodeListId: filter.ValueCodeListId });
	};

	const onFilterChange = (e: any) => {
		const value = e?.target?.value || '';
		setSearchText(value);
		if (value && value.length > 2) {
			searchInput({
				search: { query: value, fields: ['name'] }
			});
		} else {
			searchInput(null);
		}
	};

	const refundsFilterOpened = () => {
		const datesPicker = document.getElementById('Dates_date_range_picker');
		if (datesPicker) {
			if (datesPicker.style.display !== 'none') {
				datesPicker.style.display = 'none';
			}
		}
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseExport = () => {
		setAnchorEl(null);
	};

	const handleClickExportOptions = (event: any) => {
		onExports(event.currentTarget.dataset);
		setAnchorEl(null);
	};

	const renderExportOnptions = () => {
		return (
			<Menu
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleCloseExport}
			>
				<MenuItem onClick={handleClickExportOptions} data-my-value='default'>
					Default
				</MenuItem>
				<MenuItem onClick={handleClickExportOptions} data-my-value='goToTags'>
					Go to Tags
				</MenuItem>
				<MenuItem onClick={handleClickExportOptions} data-my-value='cxj'>
					CXJRFIDFactory
				</MenuItem>
			</Menu>
		);
	};

	const uploadFile = (e: any) => {
		const form = new FormData();
		form.append('file', e.target.files[0]);
		const headers = new Headers({ Authorization: token });
		const email = localStorage.getItem('cscvc_loginEmail');
		if (email) headers.set('User', btoa(email));
		httpClient(`${apiUrl}/valuecodes`, {
			method: 'PUT',
			headers,
			body: form
		})
			.then(({ json }) => {
				refresh();
				notify(`${json[1]} matching records imported of ${json[0]}.`);
			})
			.catch((e: HttpError) => {
				notify('Value codes did not update', 'warning');
			});
	};

	useEffect(() => {
		if (search !== null) {
			setFilter({ ...filter, ...search });
		} else {
			setFilter({ ...filter, search: undefined });
		}
	}, [search]);

	useEffect(() => {
		if (filter?.$text) {
			setSearchText(filter?.$text);
		}
	}, [searchText, filter?.$text]);

	return (
		<div className={classes.eventsToolbar}>
			<div className={classes.filterPosition}>
				<SearchFilter
					{...props}
					hideFilter={false}
					className={classes.filter}
					filterValues={[]}
					setFilters={() => []}
				>
					<SearchInput
						variant='outlined'
						size='small'
						source='valueCodeId'
						alwaysOn
						placeholder='Search by human readable ID'
						className={classes.input}
						onChange={onFilterChange}
						options={{
							value: searchText
						}}
					/>
				</SearchFilter>
				<div className={classes.datePicker}>
					<CustomDatePicker
						fieldKey='createdOnRange'
						setFilterSearch={changedCreatedOnDates}
						ts={filter.createdOnRange}
						label={'Date Created'}
						labelWidth={105}
					/>
				</div>
				<div className={classes.datePicker}>
					<CustomDatePicker
						fieldKey='lastUpdatedRange'
						setFilterSearch={changedLastUpdatedDates}
						ts={filter.lastUpdatedRange}
						label={'Last Updated'}
						labelWidth={105}
					/>
				</div>
				<div className={classes.datePicker}>
					<CustomDatePicker
						fieldKey='expirationDateRange'
						setFilterSearch={changedExpirationDates}
						ts={filter.expirationDateRange}
						label={'Expiration Date'}
						labelWidth={122}
					/>
				</div>
				<Filter
					id='status_label'
					label='Status'
					value={statusSelected}
					onOpen={refundsFilterOpened}
					onChange={statusChanged}
					labelWidth={55}
				>
					{status.map((statusEle: any, index: number) => {
						return (
							<MenuItem
								key={`status_${index}`}
								value={statusEle.id}
								selected={statusSelected === statusEle.id}
							>
								{statusEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				<Filter
					id='expired_label'
					label='Expired'
					value={expiredSelected}
					onChange={expiredChanged}
					labelWidth={60}
				>
					{expired.map((expiredEle: any, index: number) => {
						return (
							<MenuItem
								key={`expired_${index}`}
								value={expiredEle.id}
								selected={expiredSelected === expiredEle.id}
							>
								{expiredEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				{filterChanged && (
					<Button
						classes={{
							root: classes.btn,
							text: classes.btnText
						}}
						onClick={resetFilter}
						endIcon={
							<SvgIcon component={CloseIcon} viewBox='0 0 24 24' className={classes.svgIcon} />
						}
					>
						Reset All Filters
					</Button>
				)}
				<div className={classes.exportBtnContainer}>
					<Button
						variant='contained'
						size='small'
						startIcon={<GetAppIcon />}
						onClick={handleClick}
						classes={{
							root: classes.exportBtnRoot,
							label: classes.exportBtnTxt
						}}
					>
						Export
					</Button>
				</div>
				<div className={classes.importBtnContainer}>
					<input
						style={{ display: 'none' }}
						id='contained-button-file'
						type='file'
						onChange={uploadFile}
						accept='.xlsx'
					/>
					<label htmlFor='contained-button-file'>
						<Button
							variant='contained'
							size='small'
							startIcon={<PublishIcon />}
							component='span'
							classes={{
								root: classes.exportBtnRoot,
								label: classes.exportBtnTxt
							}}
						>
							Import
						</Button>
					</label>
				</div>
				{renderExportOnptions()}
			</div>
		</div>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		eventsToolbar: {
			display: 'flex',
			padding: '12px 0px 22px 0px'
		},
		filterPosition: {
			display: 'flex',
			marginLeft: '-16px',
			'& MuiFormControl-root': {
				height: '48px'
			},
			'& .MuiOutlinedInput-root': {
				height: '100%'
			},
			'& .react-datepicker-popper': {
				zIndex: 2
			}
		},
		btn: {
			textTransform: 'none',
			marginLeft: '16px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			borderRadius: '8px',
			height: '48px',
			width: '180px'
		},
		btnText: {
			padding: '6px 14px 6px 8px'
		},
		svgIcon: {
			width: '13px',
			height: '13px'
		},
		filter: {
			'& .filter-field': {
				margin: '25px -16px 24px 25px',
				height: '43px',
				width: '250px'
			}
		},
		input: {
			'& input': {
				paddingTop: '15px',
				paddingBottom: '15px'
			}
		},
		datePicker: {
			marginLeft: '11px'
		},
		exportBtnContainer: {
			marginLeft: '16px',
			marginTop: '5px'
		},
		importBtnContainer: {
			marginLeft: '16px',
			marginTop: '5px'
		},
		exportBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '1px 0px',
			borderRadius: '8px',
			width: '129px',
			height: '36px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		exportBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		}
	})
);
