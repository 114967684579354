/* eslint-disable no-useless-escape */
import { format } from 'date-fns';

export function getWindowHeight() {
	let winHeight = 0;

	if (window.innerHeight) {
		winHeight = window.innerHeight;
	} else if (document.body && document.body.clientHeight) {
		winHeight = document.body.clientHeight;
	}

	if (document.documentElement && document.documentElement.clientHeight) {
		winHeight = document.documentElement.clientHeight;
	}

	if (winHeight < 600) {
		winHeight = 600;
	}

	return winHeight;
}

export function getDocumentHeight() {
	let documentHeight = 600;

	if (document.body && document.body.clientHeight) {
		documentHeight = document.body.clientHeight;
	} else if (document.documentElement && document.documentElement.clientHeight) {
		documentHeight = document.documentElement.clientHeight;
	}

	if (documentHeight < 600) {
		documentHeight = 600;
	}

	return documentHeight;
}

export function stringFirstCharUpperCase(str: string) {
	if (str) {
		return str.substring(0, 1).toUpperCase() + str.substring(1);
	} else {
		return '';
	}
}

export function formatTS(cellData: string) {
	// Try to format assuming no timezone was sent back from server. If error, format as given
	try {
		return format(new Date(cellData + 'Z'), 'Pp');
	} catch {
		return format(new Date(cellData), 'Pp');
	}
}

export function getLastItemPath(path: any) {
	if (path.replace(`/\//`, '/').lastIndexOf('/') === 0) {
		return;
	}
	return path.replace(`/\//`, '/').split('/')[2];
}
