import keyBy from 'lodash/keyBy';
import { useEffect, useState } from 'react';
import { useQuery, TextField, Loading, DateField, List } from 'react-admin';
import { EmptyGrid } from '../common/EmptyGrid';
import { useStyles } from '../valueCodeLists/valueCodesFromListStyles';
import { ColumnCustom } from '../common/ColumnCustom';
import { MyDatagrid, MyShowButton } from '../common/myDataGrid/MyDatagrid';
import CustomPagination from '../common/PaginationCustom';

export default function ValueCodesFromList(props: any) {
	const classes = useStyles();
	const [page] = useState(1);
	const [perPage] = useState(10);
	const [sort] = useState({ field: 'createdOn', order: 'DESC' });
	const { data, loading, error } = useQuery({
		type: 'getList',
		resource: 'valuecodes',
		payload: {
			pagination: { page, perPage },
			sort,
			filter: props.filter
		}
	});
	const correctProps = {
		basePath: '/valueCodes',
		hasShow: true,
		resource: 'valuecodes',
		filter: props.filter
	};

	useEffect(() => {
		props.setValueCodes(data);
	}, [props, data]);

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <p>ERROR: {error}</p>;
	}
	if (!loading && data.length === 0) {
		return <EmptyGrid />;
	} else {
		return (
			<>
				<List
					{...correctProps}
					bulkActionButtons={false}
					empty={<EmptyGrid />}
					sort={sort}
					filter={{ ...props.filter }}
					pagination={<CustomPagination />}
					actions={false}
					perPage={10}
					classes={{
						root: classes.listRoot,
						content: classes.listContent
					}}
				>
					<MyDatagrid
						resource='valuecodes'
						data={keyBy(data, 'id')}
						ids={data.map(({ id }: any) => id)}
						currentSort={sort}
						hasRowClick={true}
						classes={{
							table: classes.table,
							thead: classes.thead,
							tbody: classes.tbody,
							headerCell: classes.headerCell,
							rowOdd: classes.rowOdd
						}}
					>
						<TextField
							source='humanReadableId'
							label='Human Readable ID'
							className={classes.humanReadableId}
						/>
						<TextField source='nfcuid' label='NFCUID' />
						<DateField source='createdOn' label='Date Created' showTime />
						<DateField label='Last Updated' source='lastUpdated' showTime />
						<DateField source='expirationDate' label='Expiration Date' />
						<ColumnCustom label='Status' />
						<MyShowButton />
					</MyDatagrid>
				</List>
			</>
		);
	}
}
