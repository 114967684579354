import * as React from 'react';
import { forwardRef, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useRefresh, useNotify, useMutation, useRedirect, useDataProvider } from 'react-admin';

import {
	Typography,
	Button,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	TextField,
	DialogActions,
	IconButton
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import useStyles from '../styles';
import { EditServiceDialogProps } from './DialogsProps';
import CloseIcon from '@material-ui/icons/Close';

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export const EditServiceDialog = ({
	openEditServiceDialog,
	editServiceDialogClosed,
	serviceId
}: EditServiceDialogProps) => {
	const classes = useStyles();
	const redirect = useRedirect();
	const dataProvider = useDataProvider();
	const [editServiceName, setEditServiceName] = useState('');
	const [editConsumerUrl, setEditConsumerUrl] = useState('');
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();

	const retrieveService = () => {
		dataProvider.getOne('services', { id: serviceId }).then(({ data }) => {
			setEditServiceName(data.name);
			setEditConsumerUrl(data.consumerUrl);
		});
	};

	const updateServicesLocalStorage = () => {
		dataProvider
			.getList('services', {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then((response) => {
				localStorage.setItem('cscvc_filterServices', JSON.stringify(response.data));
			})
			.catch(() => {
				localStorage.setItem('cscvc_filterServices', '[]');
			});
	};

	useEffect(() => {
		retrieveService();
	}, [serviceId]);

	const onEditServiceClicked = () => {
		mutate(
			{
				type: 'update',
				resource: 'services',
				payload: {
					id: serviceId,
					data: {
						Name: editServiceName,
						ConsumerUrl: editConsumerUrl
					}
				}
			},
			{
				onSuccess: () => {
					updateServicesLocalStorage();
					editServiceDialogClosed();
					refresh();
					redirect(`/services/${serviceId}/show`);
					notify(`Service updated: ${editServiceName}`);
				},
				onFailure: (error) => {
					notify(`Service not edited`, 'warning');
					console.log('Error: ' + error);
				}
			}
		);
	};

	const handleChange = (event: any) => {
		setEditServiceName(event.target.value);
	};

	const handleChangeConsumerUrl = (event: any) => {
		setEditConsumerUrl(event.target.value);
	};

	const onCancelClicked = (event: any) => {
		retrieveService();
		editServiceDialogClosed();
	};

	return (
		<Dialog
			open={openEditServiceDialog}
			onClose={editServiceDialogClosed}
			TransitionComponent={Transition}
		>
			<AppBar className={classes.addServiceDialogAppBar}>
				<Toolbar>
					<Typography className={classes.addServiceDialogTitle}>Edit Service</Typography>
					<IconButton aria-label='close' className={classes.closeBtn} onClick={onCancelClicked}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<div>
				<div className={classes.addRoomDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Service Name'
						size='small'
						type='search'
						value={editServiceName}
						onChange={handleChange}
					/>
				</div>
				<div className={classes.addRoomDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Consumer URL'
						size='small'
						type='search'
						value={editConsumerUrl}
						onChange={handleChangeConsumerUrl}
					/>
				</div>
			</div>
			<DialogActions>
				<Button
					onClick={onCancelClicked}
					size='small'
					variant='contained'
					color='default'
					classes={{
						root: classes.cancelBtnRoot,
						label: classes.cancelBtnTxt
					}}
				>
					Cancel
				</Button>
				<Button
					disabled={isEmpty(editServiceName.trim())}
					onClick={onEditServiceClicked}
					size='small'
					color='secondary'
					variant='contained'
					classes={{
						root: classes.yesBtnRoot,
						label: classes.yesBtnTxt,
						disabled: classes.addRoomBtnDisabled
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
