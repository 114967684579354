/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useState } from 'react';
import { Show, MenuItemLink } from 'react-admin';
import { makeStyles, createStyles, Divider } from '@material-ui/core';
import uiColor from '../../utils/utilsColors';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import { HeaderShow } from './HeaderShow';
import { LocationDetailShow } from './LocationDetailShow';
import { ValueCodeLists } from './ValueCodeLists';

/**
 * Read only list of branches
 * https://marmelab.com/react-admin/doc/2.8/Show.html
 * @param props
 * @constructor
 */
export const LocationShow = (props: any) => {
	const classes = useStyles();
	const [curSort, setCurSort] = useState({});
	const [total, setTotal] = useState(0);
	const [search, searchInput] = useState('');
	const [showValueCodeLists, setShowValueCodeLists] = React.useState(false);
	const [showDetail, setshowDetail] = React.useState(true);

	const onMenuClickLists = () => {
		setshowDetail(false);
		setShowValueCodeLists(true);
	};

	const onMenuClickDetails = () => {
		setshowDetail(true);
		setShowValueCodeLists(false);
	};

	return (
		<Show
			{...props}
			classes={{
				root: classes.showRoot,
				noActions: classes.showNoActions,
				card: classes.showCard
			}}
		>
			<BoxedShowLayout className={classes.showLayout}>
				<RaBox>
					<HeaderShow
						searchInput={searchInput}
						idSearch='id'
						disableExport={true}
						curSort={curSort}
						total={total}
					/>
				</RaBox>
				<RaBox display='flex' flexWrap='wrap' flexGrow={4}>
					<div id='my_menu' className={classes.myMenu}>
						<span className={classes.menuTitle}>Profile</span>
						<Divider orientation='horizontal' className={classes.divider} />
						<MenuItemLink
							to={`/locations/${props.id}/show`}
							primaryText='Location Details'
							onClick={onMenuClickDetails}
							className={classes.menuLink}
							activeClassName={showDetail ? classes.active : ''}
						/>
						<MenuItemLink
							to={`/locations/${props.id}/show`}
							primaryText='Value Code Lists'
							onClick={onMenuClickLists}
							className={classes.menuLink}
							activeClassName={showValueCodeLists ? classes.active : ''}
						/>
					</div>
					{showDetail ? <LocationDetailShow></LocationDetailShow> : <span></span>}
					{showValueCodeLists ? <ValueCodeLists></ValueCodeLists> : <span></span>}
				</RaBox>
			</BoxedShowLayout>
		</Show>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		layout: {
			marginTop: '0px'
		},
		roomList: {
			background: 'white'
		},
		showRoot: {},
		showNoActions: {
			marginTop: '20px'
		},
		showCard: {
			boxShadow: '0 0 0'
		},
		showLayout: {
			padding: 0,
			'&:first-child': {
				paddingTop: '0px'
			},
			'&:last-child': {
				paddingBottom: '0px'
			}
		},
		gridWrapper: {
			display: 'flex',
			margin: '0px 40px'
		},
		table: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			background: '#FFFFFF',
			minWidth: '1191px'
		},
		thead: {
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px'
		},
		tbody: {
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px',
			border: '1px solid #EEEFF3'
		},
		headerCell: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#111111',
			paddingTop: '12px',
			paddingBottom: '15px',
			paddingLeft: '24px',
			'&:first-child': {
				width: '20px',
				paddingLeft: '0',
				paddingRight: '0'
			},
			'&:nth-child(2)': {
				width: '350px'
			},
			'&:last-child': {
				width: '50px'
			}
		},
		locationName: {
			fontWeight: 'bold',
			color: uiColor.secondary,
			letterSpacing: '-0.5px'
		},
		tableRow: {
			'&$hover:hover': {
				background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
				cursor: 'pointer'
			}
		},
		hover: {},
		tableCell: {
			paddingTop: '16px',
			paddingBottom: '16px',
			borderRight: '1px solid #EEEFF3',
			paddingLeft: '24px'
		},
		tableCellNoBorder: {
			paddingTop: '16px',
			paddingBottom: '16px',
			paddingLeft: '24px'
		},
		showBtn: {
			visibility: 'hidden',
			'$hover:hover &': {
				visibility: 'visible'
			}
		},
		normalField: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#111111'
		},
		addRoomBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '1px 0px',
			borderRadius: '8px',
			width: '154px',
			height: '36px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		addRoomBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		tabsRoot: {
			borderBottom: '1px solid lightgray',
			padding: '0 40px'
		},
		tabsIndicator: {
			background: '#1695CB'
		},
		tabRoot: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			letterSpacing: '0.75px',
			fontWeight: 'bold',
			fontSize: '18px',
			lineHeight: '25px',
			color: '#969696',
			padding: 0,
			minWidth: '73px',
			marginRight: '32px'
		},
		tabSelected: {
			color: '#1695CB'
		},
		myMenu: {
			backgroundColor: uiColor.background_list,
			borderRight: '1px solid #E2E3E9',
			minHeight: '100vh',
			width: '170px',
			paddingTop: '10px'
		},
		menuLink: {
			color: uiColor.inactive,
			fontSize: '18px',
			fontWeight: 'bold'
		},
		divider: {
			borderBottom: '1px solid #E0E0E0',
			paddingBottom: '15px',
			marginLeft: '10px',
			color: 'grey',
			background: 'none',
			marginRight: '10px'
		},
		menuTitle: {
			fontSize: '18px',
			fontWeight: 'bold',
			marginLeft: '10px'
		},
		active: {
			color: uiColor.sidebar_active
		}
	})
);
