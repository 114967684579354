/* eslint-disable no-unused-vars */
import * as React from 'react';
import { forwardRef, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useRefresh, useNotify, useMutation } from 'react-admin';
import { useHistory } from 'react-router-dom';
import {
	Typography,
	Button,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	TextField,
	DialogActions,
	IconButton,
	MenuItem
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../styles';
import { EditWebhookProps } from './DialogsProps';
import { ActiveInactiveWebhookDialog } from './ActiveInactiveWebhookDialog';
import { Filter } from '../../services/Filter';
import { DATA_TYPE, ACTION_FILTER, HTTP_VERB, RESULT_PROCESSING } from '../values';

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export const EditWebhookDialog = ({
	openEditWebhook,
	editWebhookClosed,
	webhook
}: EditWebhookProps) => {
	const classes = useStyles();
	const refresh = useRefresh();
	const notify = useNotify();
	const [mutate] = useMutation();
	const [editWebhookName, setWebhookName] = useState('');
	const [editWebhookDescription, setWebhookDescription] = useState('');
	const [editWebhookUrl, setWebhookUrl] = useState('');
	const [editWebhookDataType, setWebhookDataType] = useState('');
	const [editWebhookActionFilter, setWebhookActionFilter] = useState('');
	const [editWebhookHeaders, setWebhookHeaders] = useState('');
	const [editWebhookHttpVerb, setWebhookHttpVerb] = useState('');
	const [editWebhookBody, setEditWebhookBody] = useState('');
	const [addWebhookSequenceOrder, setWebhookSequenceOrder] = useState();
	const [editWebhookResultP, setWebhookResultP] = useState('');
	const [openActiveInactiveDialog, setOpenActiveInactiveDialog] = useState(false);
	const [dataTypeSelected, setDataTypeSelected] = useState('');
	const [actionFilterSelected, setActionFilterSelected] = useState('');
	const [httpVerbSelected, setHttpVerbSelected] = useState('');
	const [resultPSelected, setResultPSelected] = useState('');
	const [dataTypes, setDataTypes] = useState<any>(DATA_TYPE);
	const [actionFilters, setActionFilters] = useState<any>(ACTION_FILTER);
	const [httpVerbs, setHttpVerbs] = useState<any>(HTTP_VERB);
	const [resultsP, setResultsP] = useState<any>(RESULT_PROCESSING);

	useEffect(() => {
		setWebhookUrl('');
		setWebhookDataType('');
		setDataTypeSelected('');
		setWebhookActionFilter('');
		setActionFilterSelected('');
		setWebhookHttpVerb('');
		setHttpVerbSelected('');
		setWebhookResultP('');
		setResultPSelected('');
		setEditWebhookBody('');
		setWebhookHeaders('');
		if (webhook?.name) setWebhookName(webhook.name);
		if (webhook?.description) setWebhookDescription(webhook.description);
		if (webhook?.url) setWebhookUrl(webhook.url);
		if (webhook?.dataType) {
			setWebhookDataType(webhook.dataType);
			setDataTypeSelected(webhook.dataType);
		}
		if (webhook?.actionFilter) {
			setWebhookActionFilter(webhook.actionFilter);
			setActionFilterSelected(webhook.actionFilter);
		}
		if (webhook?.headers) setWebhookHeaders(webhook.headers);
		if (webhook?.httpVerb) {
			setWebhookHttpVerb(webhook.httpVerb);
			setHttpVerbSelected(webhook.httpVerb.toLowerCase());
		}
		if (webhook?.body) setEditWebhookBody(webhook.body);
		if (webhook?.resultProcessing) {
			setWebhookResultP(webhook.resultProcessing);
			setResultPSelected(webhook.resultProcessing);
		}
		if (webhook?.sequenceOrder) setWebhookSequenceOrder(webhook.sequenceOrder);
	}, [webhook]);

	const onEditWebhookClicked = () => {
		mutate(
			{
				type: 'update',
				resource: 'webhooks',
				payload: {
					id: webhook?.id,
					data: {
						Name: editWebhookName,
						Description: editWebhookDescription,
						Url: editWebhookUrl,
						DataType: dataTypeSelected,
						ActionFilter: actionFilterSelected,
						HttpVerb: httpVerbSelected,
						Headers: editWebhookHeaders !== '' ? editWebhookHeaders : null,
						Body: editWebhookBody !== '' ? editWebhookBody : null,
						serviceId: webhook?.serviceId,
						Monetary: false,
						ResultProcessing: resultPSelected !== '' ? resultPSelected : null,
						SequenceOrder: addWebhookSequenceOrder
					}
				}
			},
			{
				onSuccess: ({ data }) => {
					editWebhookClosed();
					refresh();
					notify(`Webhook edited: ${editWebhookName}.`);
				},
				onFailure: (error) => {
					notify(`${error}`, 'warning');
					console.log('Error: ' + error);
				}
			}
		);
	};

	const handleChangeName = (event: any) => {
		setWebhookName(event.target.value);
	};
	const handleChangeDescription = (event: any) => {
		setWebhookDescription(event.target.value);
	};
	const handleChangeUrl = (event: any) => {
		setWebhookUrl(event.target.value);
	};
	const handleChangeHeaders = (event: any) => {
		setWebhookHeaders(event.target.value);
	};
	const handleChangeBody = (event: any) => {
		setEditWebhookBody(event.target.value);
	};
	const handleChangeSequenceOrder = (event: any) => {
		setWebhookSequenceOrder(event.target.value);
	};

	const onCancelClicked = (event: any) => {
		setWebhookName(webhook?.name);
		setWebhookDescription(webhook?.description);
		setWebhookUrl('');
		setWebhookDataType('');
		setDataTypeSelected('');
		setWebhookActionFilter('');
		setActionFilterSelected('');
		setWebhookHttpVerb('');
		setHttpVerbSelected('');
		setWebhookResultP('');
		setResultPSelected('');
		setEditWebhookBody('');
		setWebhookHeaders('');
		editWebhookClosed();
	};

	const onActiveInactiveClicked = (event: any) => {
		mutate(
			{
				type: 'delete',
				resource: 'webhooks',
				payload: {
					id: webhook?.id
				}
			},
			{
				onSuccess: () => {
					editWebhookClosed();
					refresh();
					notify(
						`Webhook: ${editWebhookName} ${
							webhook?.active === false ? 'activated.' : 'deactivated.'
						}`
					);
				},
				onFailure: (error) => {
					notify(
						`Webhook: ${editWebhookName} ${
							webhook?.active === false ? 'not activated.' : 'not deactivated.'
						}`
					);
					console.log('Error: ' + error);
				}
			}
		);
	};

	const activeInactiveDialogClosed = () => {
		setOpenActiveInactiveDialog(false);
	};

	const onClickActivateDeactivate = () => {
		setOpenActiveInactiveDialog(true);
	};

	const dataTypeChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const dataType = event.target.value as string;
		setDataTypeSelected(dataType);
	};

	const actionFilterChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const actionFilter = event.target.value as string;
		setActionFilterSelected(actionFilter);
	};

	const httpVerbChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const httpVerb = event.target.value as string;
		setHttpVerbSelected(httpVerb);
	};

	const resultPChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const resultP = event.target.value as string;
		setResultPSelected(resultP);
	};

	return (
		<Dialog open={openEditWebhook} onClose={editWebhookClosed} TransitionComponent={Transition}>
			<AppBar className={classes.addServiceDialogAppBar}>
				<Toolbar>
					<Typography className={classes.addServiceDialogTitle}>Edit Webhook</Typography>
					<IconButton aria-label='close' className={classes.closeBtn} onClick={onCancelClicked}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Name'
						size='small'
						type='search'
						value={editWebhookName}
						onChange={handleChangeName}
					/>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Description'
						size='small'
						type='search'
						multiline
						value={editWebhookDescription}
						onChange={handleChangeDescription}
					/>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='URL'
						size='small'
						type='search'
						value={editWebhookUrl}
						onChange={handleChangeUrl}
					/>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<Filter
						id='dataType_label'
						label='Data Type'
						value={dataTypeSelected}
						onChange={dataTypeChanged}
						labelWidth={65}
					>
						{dataTypes.map((dataType: any, index: number) => {
							return (
								<MenuItem
									key={`dataTypes_${index}`}
									value={dataType.id}
									selected={dataTypeSelected === dataType.id}
								>
									{dataType.description}
								</MenuItem>
							);
						})}
					</Filter>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<Filter
						id='actionFilter_label'
						label='Action Filter'
						value={actionFilterSelected}
						onChange={actionFilterChanged}
						labelWidth={65}
					>
						{actionFilters.map((actionFilter: any, index: number) => {
							return (
								<MenuItem
									key={`actionFilter_${index}`}
									value={actionFilter.id}
									selected={actionFilterSelected === actionFilter.id}
								>
									{actionFilter.description}
								</MenuItem>
							);
						})}
					</Filter>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='HTTP Headers'
						size='medium'
						type='search'
						value={editWebhookHeaders}
						onChange={handleChangeHeaders}
						multiline
						placeholder='{"test1":"test1", "test2": "test2"}'
					/>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<Filter
						id='httpVerb_label'
						label='HTTP Verb'
						value={httpVerbSelected}
						onChange={httpVerbChanged}
						labelWidth={65}
					>
						{httpVerbs.map((httpVerb: any, index: number) => {
							return (
								<MenuItem
									key={`httpVerb_${index}`}
									value={httpVerb.id}
									selected={httpVerbSelected === httpVerb.id}
								>
									{httpVerb.description}
								</MenuItem>
							);
						})}
					</Filter>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='HTTP Body'
						size='medium'
						type='search'
						value={editWebhookBody}
						onChange={handleChangeBody}
						multiline
						placeholder='{"test1":"test1", "test2": "test2"}'
					/>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<Filter
						id='resultP_label'
						label='Result Processing'
						value={resultPSelected}
						onChange={resultPChanged}
						labelWidth={65}
					>
						{resultsP.map((resultP: any, index: number) => {
							return (
								<MenuItem
									key={`resultP_${index}`}
									value={resultP.description}
									selected={resultPSelected === resultP.id}
								>
									{resultP.description}
								</MenuItem>
							);
						})}
					</Filter>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Sequence Order'
						size='medium'
						type='number'
						InputProps={{ inputProps: { min: 1 } }}
						value={addWebhookSequenceOrder}
						onChange={handleChangeSequenceOrder}
					/>
				</div>
			</div>
			<DialogActions>
				<Button
					onClick={onClickActivateDeactivate}
					size='small'
					variant='contained'
					color='default'
					classes={{
						root: classes.cancelBtnRoot,
						label: classes.cancelBtnTxt
					}}
				>
					{webhook?.active === true ? 'Deactivate' : 'Activate'}
				</Button>
				<Button
					disabled={isEmpty(editWebhookName.trim())}
					onClick={onEditWebhookClicked}
					size='small'
					color='secondary'
					variant='contained'
					classes={{
						root: classes.yesBtnRoot,
						label: classes.yesBtnTxt,
						disabled: classes.addRoomBtnDisabled
					}}
				>
					Save
				</Button>
			</DialogActions>
			<ActiveInactiveWebhookDialog
				activeInactiveClosed={activeInactiveDialogClosed}
				openActiveInactive={openActiveInactiveDialog}
				activeInactiveClicked={onActiveInactiveClicked}
				active={!webhook?.active}
			/>
		</Dialog>
	);
};
