import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		roomList: {
			background: 'white',
			marginTop: '16px'
		},
		navBar: {
			display: 'flex',
			justifyContent: 'space-between'
		},
		navBarLeft: {
			position: 'relative',
			top: '-7px',
			left: '-1px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#969696'
		},
		addRoomBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '1px 0px',
			borderRadius: '8px',
			width: '154px',
			height: '36px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		addRoomBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		addServiceDialogAppBar: {
			position: 'relative',
			backgroundColor: '#EEEFF3',
			boxShadow: '0 0 0',
			borderBottom: '1px solid #E2E3E9'
		},
		addServiceDialogTitle: {
			flex: 1,
			paddingLeft: '8px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 800,
			fontSize: '24px',
			lineHeight: '33px',
			letterSpacing: '-0.75px',
			color: '#232F64'
		},
		addRoomBtnDisabled: {
			opacity: 0.3
		},
		addRoomDialogItem: {
			margin: '25px 35px',
			width: '450px',
			display: 'flex',
			alignItems: 'center'
		},
		cancelBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			width: '152px',
			height: '36px',
			marginBottom: '25px'
		},
		cancelBtnTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '0.5px',
			color: '#5F5F5F'
		},
		yesBtnRoot: {
			marginLeft: '16px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)',
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			width: '152px',
			height: '36px',
			marginRight: '25px',
			marginBottom: '25px'
		},
		yesBtnTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '0.5px',
			color: '#FFFFFF'
		},
		closeBtnRoot: {
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)',
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			width: '214px',
			height: '36px'
		},
		closeBtn: {
			color: '#969696'
		},
		addCurrencyTypeDialogItem: {
			margin: '25px 35px 5px 35px',
			width: '450px',
			display: 'flex',
			alignItems: 'center'
		}
	})
);

export default useStyles;
