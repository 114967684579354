export const DATA_TYPE = [
	{
		id: 'valueCode',
		description: 'Value Code'
	},
	{
		id: 'transaction',
		description: 'Transaction'
	},
	{
		id: 'currencyType',
		description: 'Currency Type'
	},
	{
		id: 'location',
		description: 'Location'
	},
	{
		id: 'valueCodeList',
		description: 'Value Code List'
	},
	{
		id: 'webhook',
		description: 'Webhook'
	}
];

export const ACTION_FILTER = [
	{
		id: 'PreCreated',
		description: 'Pre Created'
	},
	{
		id: 'PreUpdated',
		description: 'Pre Updated'
	},
	{
		id: 'PreDeleted',
		description: 'Pre Deleted'
	},
	{
		id: 'PostCreated',
		description: 'Post Created'
	},
	{
		id: 'PostUpdated',
		description: 'Post Updated'
	},
	{
		id: 'PostGotten',
		description: 'Post Gotten'
	},
	{
		id: 'PostDeleted',
		description: 'Post Deleted'
	}
];

export const HTTP_VERB = [
	{
		id: 'post',
		description: 'POST'
	},
	{
		id: 'put',
		description: 'PUT'
	},
	{
		id: 'get',
		description: 'GET'
	},
	{
		id: 'delete',
		description: 'DELETE'
	}
];

export const RESULT_PROCESSING = [
	{
		id: 'updateMetadata',
		description: 'Update Metadata'
	},
	{
		id: 'none',
		description: 'None'
	},
	{
		id: '200expected',
		description: '200 Expected'
	}
];
