import * as React from 'react';
import { forwardRef, useState, useEffect } from 'react';
import { useRefresh, useNotify, useMutation, useDataProvider } from 'react-admin';
import { RaBox } from 'ra-compact-ui';
import { isEmpty } from 'lodash';
import {
	Typography,
	Button,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	TextField,
	MenuItem,
	IconButton,
	DialogContent
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TransitionProps } from '@material-ui/core/transitions';
import { useStyles } from '../permissionsStyles';
import { AddUserProps } from './DialogsProps';
import { Filter } from '../Filter';
const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export const AddUserDialog = ({ openAddUser, addUserClosed }: AddUserProps) => {
	const classes = useStyles();
	const refresh = useRefresh();
	const notify = useNotify();
	const [mutate] = useMutation();
	const dataProvider = useDataProvider();
	const [userEmail, setUserEmail] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [searchText, setSearchText] = useState('');
	const [roles, setRoles] = useState<any>([]);
	const [roleSelected, setRoleSelected] = useState('');

	const retrieveRoles = () => {
		dataProvider
			.getList('roles', {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then(({ data }) => {
				setRoles(data);
			});
	};

	useEffect(() => {
		if (roles.length === 0) retrieveRoles();
	}, [roles, roleSelected]);

	const onAddUserClicked = () => {
		mutate(
			{
				type: 'create',
				resource: 'users/invite',
				payload: {
					data: {
						// UserName: userName,
						// Name: userFirstName,
						Email: userEmail,
						RoleId: roleSelected
					}
				}
			},
			{
				onSuccess: ({ data }) => {
					addUserClosed();
					refresh();
					notify(`User invited: ${userEmail}.`);
				},
				onFailure: (error) => {
					notify(`User not added`, 'warning');
					console.log('Error: ' + error);
				}
			}
		);
	};

	const roleChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const role = event.target.value as string;
		setRoleSelected(role);
	};

	const handleUserEmailChange = (event: any) => {
		setUserEmail(event.target.value);
	};

	const onCancelClicked = (event: any) => {
		addUserClosed();
		// setUserName('');
		// setName('');
		setUserEmail('');
		setSearchText('');
		setRoleSelected('');
	};

	return (
		<Dialog fullScreen open={openAddUser} onClose={addUserClosed} TransitionComponent={Transition}>
			<AppBar className={classes.addUserDialogAppBar}>
				<Toolbar>
					<IconButton aria-label='back' onClick={onCancelClicked}>
						<ArrowBackIcon className={classes.backIcon} />
					</IconButton>
					<Typography className={classes.addUserDialogTitle}>New User</Typography>
					<Button
						onClick={onCancelClicked}
						size='small'
						variant='contained'
						color='default'
						classes={{
							root: classes.cancelBtnRoot,
							label: classes.cancelBtnTxt
						}}
					>
						Cancel
					</Button>
					<Button
						disabled={
							isEmpty(userEmail.trim()) ||
							isEmpty(roleSelected.trim()) ||
							!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userEmail)
						}
						onClick={onAddUserClicked}
						size='small'
						color='secondary'
						variant='contained'
						classes={{
							root: classes.yesBtnRoot,
							label: classes.yesBtnTxt,
							disabled: classes.addUserBtnDisabled
						}}
					>
						Invite User
					</Button>
				</Toolbar>
			</AppBar>
			<DialogContent classes={{ root: classes.dialogContent }}>
				<RaBox>
					<div>
						<div>
							<div className={classes.addUserDialogItem}>
								<span className={classes.addUserItemLabel}>Email</span>
								<TextField
									id='outlined-helperText'
									variant='outlined'
									label='Email'
									size='small'
									onChange={handleUserEmailChange}
									value={userEmail}
									inputProps={{
										style: {
											height: '26px'
										}
									}}
									required
									type='email'
								/>
							</div>
							<div className={classes.addUserDialogItem}>
								<span className={classes.addUserItemLabel}>Role</span>
								<Filter
									id='role_label'
									label='Role'
									value={roleSelected}
									onChange={roleChanged}
									labelWidth={65}
									required
								>
									{roles.map((role: any, index: number) => {
										return (
											<MenuItem
												key={`roles${index}`}
												value={role.id}
												selected={roleSelected === role.id}
											>
												{role.name}
											</MenuItem>
										);
									})}
								</Filter>
							</div>
						</div>
					</div>
				</RaBox>
			</DialogContent>
		</Dialog>
	);
};
