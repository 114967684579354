import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, MenuItem, SvgIcon, Button } from '@material-ui/core';
import { Filter } from '../services/Filter';
import { SearchInput, Filter as SearchFilter } from 'react-admin';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';

export const UsersFilters = ({ setFilter, filter, view, roles }: any, props: any) => {
	const classes = useStyles();

	const [searchText, setSearchText] = useState('');
	const [search, searchInput] = useState<Object | null>({});
	const [roleSelected, setRoleSelected] = useState('all');
	const [statusSelected, setStatusSelected] = useState('all');
	const [inviteAcceptedSelected, setInviteAcceptedSelected] = useState('all');
	const [status] = useState<any>([
		{ id: 'all', description: 'All' },
		{ id: 'active', description: 'Active' },
		{ id: 'inactive', description: 'Inactive' }
	]);
	const [inviteAccepted] = useState<any>([
		{ id: 'all', description: 'All' },
		{ id: 'true', description: 'True' },
		{ id: 'false', description: 'False' }
	]);
	const [filterChanged, setFilterChanged] = useState(false);

	useEffect(() => {
		if (filter?.status) {
			setStatusSelected(filter.status);
		}
		if (filter?.inviteAccepted) {
			setInviteAcceptedSelected(filter.inviteAccepted);
		}
		if (
			filter?.status === 'all' &&
			filter?.inviteAccepted === 'all' &&
			(filter?.location === undefined || filter?.location === 'all') &&
			(filter?.role === undefined || filter?.role === 'all') &&
			filter?.datesRange === undefined
		) {
			setFilterChanged(false);
		} else {
			setFilterChanged(true);
		}
	}, [filter]);

	const onSearchChange = (e: any) => {
		const value = e?.target?.value || '';
		setSearchText(value);
		if (value) {
			searchInput({
				search: { query: value, fields: ['firstName'] }
			});
		} else {
			searchInput(null);
		}
	};

	const roleChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const role = event.target.value as string;
		if (role !== 'all') {
			setFilter({ ...filter, role });
		} else {
			setFilter({ ...filter, role: undefined });
		}
		setRoleSelected(role);
	};

	const statusChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const status = event.target.value as string;
		if (status !== 'all') {
			setFilter({ ...filter, status });
		} else {
			setFilter({ ...filter, status: undefined });
		}
		setFilterChanged(false);
	};

	const inviteAcceptedCahnged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const inviteAccepted = event.target.value as string;
		if (inviteAccepted !== 'all') {
			setFilter({ ...filter, inviteAccepted });
		} else {
			setFilter({ ...filter, inviteAccepted: undefined });
		}
		setFilterChanged(false);
	};

	const changedDates = (date: any) => {
		if (date !== null) {
			setFilter({ ...filter, ...date });
		} else {
			if (filter.datesRange) {
				delete filter.datesRange;
			}
			setFilter({ ...filter });
		}
	};

	const resetFilter = () => {
		setRoleSelected('all');
		setStatusSelected('all');
		setInviteAcceptedSelected('all');
		changedDates(null);
		setFilter({ status: 'all', inviteAccepted: 'all' });
		setSearchText('');
	};

	useEffect(() => {
		if (search !== null) {
			setFilter({ ...filter, ...search });
		} else {
			setFilter({ ...filter, search: undefined });
		}
	}, [search]);

	useEffect(() => {
		if (filter?.$text) {
			setSearchText(filter?.$text);
		}
	}, [searchText, filter?.$text]);

	return (
		<div className={classes.eventsToolbar}>
			<div className={classes.filterPosition}>
				<SearchFilter
					{...props}
					hideFilter={false}
					className={view === 'list' ? classes.filter : classes.filterDetail}
					filterValues={[]}
					setFilters={() => []}
				>
					<SearchInput
						variant='outlined'
						size='small'
						source='locations-id'
						alwaysOn
						placeholder='Search'
						className={classes.input}
						onChange={onSearchChange}
						options={{
							value: searchText
						}}
					/>
				</SearchFilter>
				<Filter
					id='role_label'
					label='Role'
					value={roleSelected}
					onChange={roleChanged}
					labelWidth={40}
				>
					<MenuItem key={`role_all`} value={'all'} selected={true}>
						All
					</MenuItem>
					{roles.map((roleEle: any, index: number) => {
						return (
							<MenuItem key={`status_${index}`} value={roleEle.id} selected={false}>
								{roleEle.name}
							</MenuItem>
						);
					})}
				</Filter>
				<Filter
					id='status_label'
					label='Status'
					value={statusSelected}
					onChange={statusChanged}
					labelWidth={55}
				>
					{status.map((statusEle: any, index: number) => {
						return (
							<MenuItem
								key={`status_${index}`}
								value={statusEle.id}
								selected={statusSelected === statusEle.id}
							>
								{statusEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				<Filter
					id='inviteAccepted_label'
					label='Invite Accepted'
					value={inviteAcceptedSelected}
					onChange={inviteAcceptedCahnged}
					labelWidth={125}
				>
					{inviteAccepted.map((inviteAcceptedEle: any, index: number) => {
						return (
							<MenuItem
								key={`inviteAccepted_${index}`}
								value={inviteAcceptedEle.id}
								selected={inviteAcceptedSelected === inviteAcceptedEle.id}
							>
								{inviteAcceptedEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				{filterChanged && (
					<Button
						classes={{
							root: classes.btn,
							text: classes.btnText
						}}
						onClick={resetFilter}
						endIcon={
							<SvgIcon component={CloseIcon} viewBox='0 0 24 24' className={classes.svgIcon} />
						}
					>
						Reset All Filters
					</Button>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		eventsToolbar: {
			display: 'flex',
			padding: '12px 0px 22px 0px',
			backgroundColor: '#FFFFFF'
		},
		filterPosition: {
			display: 'flex',
			'& MuiFormControl-root': {
				height: '48px'
			},
			'& .MuiOutlinedInput-root': {
				height: '100%'
			},
			'& .react-datepicker-popper': {
				zIndex: 2
			}
		},
		btn: {
			textTransform: 'none',
			marginLeft: '16px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			borderRadius: '8px',
			minWidth: '160px',
			height: '48px'
		},
		btnText: {
			padding: '6px 14px 6px 8px'
		},
		svgIcon: {
			width: '13px',
			height: '13px'
		},
		datePicker: {
			marginLeft: '11px'
		},
		filter: {
			backgroundColor: '#ffffff',
			'& .filter-field': {
				margin: '25px -16px 24px 38px',
				height: '43px',
				width: '250px'
			}
		},
		filterDetail: {
			backgroundColor: '#ffffff',
			'& .filter-field': {
				margin: '25px -16px 24px 0px',
				height: '43px',
				width: '250px'
			}
		},
		input: {
			'& input': {
				paddingTop: '15px',
				paddingBottom: '15px'
			}
		},
		locationsSelect: {
			fontFamily: 'Manrope',
			marginLeft: '16px',
			'& a': {
				borderRadius: '8px',
				padding: '7px 16px !important',
				'& input': {
					borderBottom: 'none !important',
					fontSize: '16px',
					fontFamily: 'Manrope',
					fontWeight: 500,
					fontStyle: 'normal',
					lineHeight: '22px'
				}
			},
			'& .dropdown .dropdown-trigger.arrow.bottom:after': {
				fontSize: '17px',
				color: 'rgba(0, 0, 0, 0.54)',
				content: '"\\23F7"'
			},
			'& .dropdown .dropdown-trigger.arrow.top:after': {
				fontSize: '17px',
				color: 'rgba(0, 0, 0, 0.54)',
				content: '"\\23F6"'
			},
			'& .dropdown .dropdown-content li': {
				fontSize: '18px'
			},
			'& .dropdown .dropdown-content': {
				width: '250px'
			}
		},
		label: {
			fontSize: '13px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			color: '#969696',
			position: 'absolute',
			left: '25px',
			top: '-7px',
			backgroundColor: 'white',
			zIndex: 2,
			padding: '0 3px'
		},
		locationContainer: {
			position: 'relative'
		}
	})
);
