/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Resource } from 'react-admin';
import ValueCodeList from '../valueCodeLists/ValueCodeList';
import { ValueCoeListShow } from '../valueCodeLists/ValueCodeListShow';
import ServicesList from '../services/ServicesList';
import { ServiceShow } from '../services/ServiceShow';
import LocationsList from '../locations/LocationsList';
import TransactionsList from '../transactions/TransactionsList';
import { LocationShow } from '../locations/LocationShow';
import { ValueCodeShow } from '../valuecodes/ValueCodeShow';
import { ValueCodesList } from '../valuecodes/ValueCodesList';
import { ConsumersList } from '../consumers/ConsumersList';
import { ConsumerShow } from '../consumers/ConsumerShow';
import UsersList from '../permissions/UsersList';
import { UserShow } from '../permissions/UserShow';
import RolesList from '../permissions/RolesList';
import { ReactComponent as ValueCodeListsIcon } from '../../assets/menu_icon_valueCodeLists.svg';
import { ReactComponent as LocationsIcon } from '../../assets/menu_icon_locations.svg';
import { ReactComponent as ServicesIcon } from '../../assets/menu_icon_services.svg';
import { ReactComponent as TransactionsIcon } from '../../assets/menu_icon_transactions.svg';
import { ReactComponent as ValueCodesIcon } from '../../assets/menu_icon_valueCodes.svg';
import { ReactComponent as ConsumersIcon } from '../../assets/menu_icon_consumers.svg';
import { ReactComponent as PermissionsIcon } from '../../assets/menu_icon_permissions.svg';
import { hasView } from '../../utils/permissions';

export const Resources = (access: any) => {
	let resources: any[] = [];
	if (hasView(access, 'valueCodeLists')) {
		resources = resources.concat([
			<Resource
				key='valuecodelists'
				name='valuecodelists'
				list={ValueCodeList}
				show={ValueCoeListShow}
				options={{ label: 'Value Code Lists' }}
				icon={ValueCodeListsIcon}
			/>
		]);
	}
	if (hasView(access, 'services')) {
		resources = resources.concat([
			<Resource
				key='serviceslist'
				name='services'
				list={ServicesList}
				show={ServiceShow}
				options={{ label: 'Services' }}
				icon={ServicesIcon}
			/>
		]);
	}
	if (hasView(access, 'locations')) {
		resources = resources.concat([
			<Resource
				key='locationslist'
				name='locations'
				list={LocationsList}
				show={LocationShow}
				options={{ label: 'Locations' }}
				icon={LocationsIcon}
			/>
		]);
	}
	if (hasView(access, 'transactions')) {
		resources = resources.concat([
			<Resource
				key='transactions'
				name='transactions'
				list={TransactionsList}
				options={{ label: 'Transactions' }}
				icon={TransactionsIcon}
			/>
		]);
	}
	if (hasView(access, 'valueCodes')) {
		resources = resources.concat([
			<Resource
				key='valuecodes'
				name='valuecodes'
				options={{ label: 'Value Codes' }}
				show={ValueCodeShow}
				list={ValueCodesList}
				icon={ValueCodesIcon}
			/>
		]);
	}
	if (hasView(access, 'consumers')) {
		resources = resources.concat([
			<Resource
				key='consumers'
				name='consumers'
				options={{ label: 'Consumers' }}
				icon={ConsumersIcon}
				list={ConsumersList}
				show={ConsumerShow}
			/>
		]);
	}
	if (hasView(access, 'users')) {
		resources = resources.concat([
			<Resource
				key='users'
				name='users'
				options={{ label: 'Permissions' }}
				icon={PermissionsIcon}
				list={UsersList}
				show={UserShow}
			/>
		]);
	}
	resources = resources.concat([
		<Resource key='currencytypes' name='currencytypes' options={{ label: 'Currency Type' }} />
	]);
	resources = resources.concat([
		<Resource key='webhooks' name='webhooks' options={{ label: 'Webhooks' }} />
	]);
	resources = resources.concat([
		<Resource key='roles' name='roles' list={RolesList} show={RolesList} />
	]);
	return resources;
};
