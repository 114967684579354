import * as React from 'react';
import { useEffect, useState } from 'react';
import { Show, useDataProvider, useNotify, useShowController } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles, Box } from '@material-ui/core';
import moment from 'moment';
import Check from '@material-ui/icons/Check';
import uiColor from '../../utils/utilsColors';
import { HeaderShow } from './HeaderShow';
import { stringFirstCharUpperCase } from '../../utils/util';

/**
 * Read only list of branches
 * https://marmelab.com/react-admin/doc/2.8/Show.html
 * @param props
 * @constructor
 */
export const UserShow = (props: any) => {
	const classes = useStyles();
	const dataProvider = useDataProvider();
	const [curSort] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [search, searchInput] = useState('');
	const history = useHistory();
	const notify = useNotify();
	const [roleName, setRoleName] = useState<any>('');
	// eslint-disable-next-line no-undef
	const [permissions, setPermissions] = useState<permissions[]>([]);
	const { record } = useShowController(props);

	useEffect(() => {
		if (record) {
			dataProvider
				.getOne('roles', { id: record.roleId })
				.then(({ data }) => {
					setRoleName(data.name);
					if (data.permissionsJSON !== undefined) {
						data.permissionsJSON.sort((a: any, b: any) => a.type.localeCompare(b.type));
					}
					setPermissions(data.permissionsJSON);
				})
				.catch(() => {
					notify('Role not found', 'warning');
				});
		}
	}, [record]);

	const goToRole = (roleId: any) => {
		history.push(`/roles/${roleId}/show`);
	};

	const permissionsText = (permission: any) => {
		return (
			<div>
				{permission.permissions.create === true ? (
					<div className={classes.permissionsText}>
						<Check style={{ fontSize: 18, color: '#3BA359', marginRight: 15 }} />
						<span className={classes.valueCodesItemText}>Create</span>
					</div>
				) : (
					<div></div>
				)}
				{permission.permissions.view === true ? (
					<div className={classes.permissionsText}>
						<Check style={{ fontSize: 18, color: '#3BA359', marginRight: 15 }} />
						<span className={classes.valueCodesItemText}>View</span>
					</div>
				) : (
					<div></div>
				)}
				{permission.permissions.edit === true ? (
					<div className={classes.permissionsText}>
						<Check style={{ fontSize: 18, color: '#3BA359', marginRight: 15 }} />
						<span className={classes.valueCodesItemText}>Edit</span>
					</div>
				) : (
					<div></div>
				)}
				{permission.permissions.delete === true ? (
					<div className={classes.permissionsText}>
						<Check style={{ fontSize: 18, color: '#3BA359', marginRight: 15 }} />
						<span className={classes.valueCodesItemText}>Delete</span>
					</div>
				) : (
					<div></div>
				)}
			</div>
		);
	};

	return (
		<Show
			{...props}
			classes={{
				root: classes.showRoot,
				noActions: classes.showNoActions,
				card: classes.showCard
			}}
		>
			<div className={classes.container}>
				<HeaderShow
					searchInput={searchInput}
					idSearch='id'
					disableExport={true}
					curSort={curSort}
					id={props.id}
					record={record}
				/>
				<div className={classes.codeInformationContainer}>
					<span className={classes.valueCodesItemLabel}>Name</span>
					<span className={classes.valueCodesItemText}>{record?.name}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<span className={classes.valueCodesItemLabel}>Username</span>
					<span className={classes.valueCodesItemText}>{record?.userName}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<span className={classes.valueCodesItemLabel}>Email</span>
					<span className={classes.valueCodesItemText}>{record?.email}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<span className={classes.valueCodesItemLabel}>Role</span>
					<span className={classes.valueCodesItemText}>{record?.role?.name}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<span className={classes.valueCodesItemLabel}>Created Date</span>
					<span className={classes.valueCodesItemText}>
						{moment(record?.createdOn).format('MM/DD/YYYY h:mm A')}
					</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<span className={classes.valueCodesItemLabel}>Last Updated</span>
					<span className={classes.valueCodesItemText}>
						{moment(record?.lastUpdated).format('MM/DD/YYYY h:mm A')}
					</span>
				</div>
				<div className={classes.subtitle}>Role Permissions</div>
				{permissions.map((permission: any, index: number) => {
					return (
						<div key={index} className={classes.searchAnyFieldContainer}>
							{index === 0 ? (
								<div className={classes.roleNameContainer}>
									<div onClick={() => goToRole(record?.roleId)} className={classes.subtitleRole}>
										{roleName}
									</div>
								</div>
							) : (
								<div></div>
							)}
							<div
								className={
									index === permissions.length - 1
										? classes.searchAnyFieldBoxLast
										: classes.searchAnyFieldBox
								}
							>
								<Box flex={12} className={classes.searchFieldBox}>
									<Box display='flex'>
										<Box flex={12}>
											<div className={classes.permissionsText}>
												<span className={classes.valueCodesItemLabel}>
													{stringFirstCharUpperCase(
														permission.type.replace(/([A-Z])/g, ' $1').trim()
													) || null}
												</span>
											</div>
											{permissionsText(permission)}
										</Box>
									</Box>
								</Box>
							</div>
						</div>
					);
				})}
			</div>
		</Show>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		showRoot: {},
		showNoActions: {},
		showCard: {
			boxShadow: '0 0 0'
		},
		container: {},
		showLayout: {
			padding: 0,
			'&:first-child': {
				paddingTop: '0px'
			},
			'&:last-child': {
				paddingBottom: '0px'
			}
		},
		gridWrapper: {
			display: 'flex',
			margin: '0px 40px'
		},
		table: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			background: '#FFFFFF',
			minWidth: '1191px'
		},
		thead: {
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px'
		},
		tbody: {
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px',
			border: '1px solid #EEEFF3'
		},
		headerCell: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#111111',
			paddingTop: '12px',
			paddingBottom: '15px',
			paddingLeft: '24px',
			'&:first-child': {
				width: '20px',
				paddingLeft: '0',
				paddingRight: '0'
			},
			'&:nth-child(2)': {
				width: '350px'
			},
			'&:last-child': {
				width: '50px'
			}
		},
		locationName: {
			fontWeight: 'bold',
			color: uiColor.secondary,
			letterSpacing: '-0.5px'
		},
		tableRow: {
			'&$hover:hover': {
				background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
				cursor: 'pointer'
			}
		},
		hover: {},
		tableCell: {
			paddingTop: '16px',
			paddingBottom: '16px',
			borderRight: '1px solid #EEEFF3',
			paddingLeft: '24px'
		},
		tableCellNoBorder: {
			paddingTop: '16px',
			paddingBottom: '16px',
			paddingLeft: '24px'
		},
		showBtn: {
			visibility: 'hidden',
			'$hover:hover &': {
				visibility: 'visible'
			}
		},
		normalField: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#111111'
		},
		addRoomBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '1px 0px',
			borderRadius: '8px',
			width: '154px',
			height: '36px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		addRoomBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		tabsRoot: {
			borderBottom: '1px solid lightgray',
			padding: '0 40px'
		},
		tabsIndicator: {
			background: '#1695CB'
		},
		tabRoot: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			letterSpacing: '0.75px',
			fontWeight: 'bold',
			fontSize: '18px',
			lineHeight: '25px',
			color: '#969696',
			padding: 0,
			minWidth: '73px',
			marginRight: '32px'
		},
		tabSelected: {
			color: '#1695CB'
		},
		humanReadableId: {
			fontWeight: 'bold',
			color: '#1695CB',
			cursor: 'pointer'
		},
		codeInformationContainer: {
			display: 'flex',
			justifyContent: 'flex-start',
			margin: '33px 40px 0 40px',
			flexDirection: 'row'
		},
		roleNameContainer: {
			display: 'flex',
			justifyContent: 'flex-start',
			flexDirection: 'row'
		},
		subtitleValueCode: {
			alignItems: 'center',
			color: '#111111',
			fontWeight: 700,
			fontSize: '18px',
			fontFamily: 'Manrope',
			lineHeight: '25px',
			width: '150px',
			marginTop: '20px',
			marginLeft: '50px'
		},
		valueCodesItemLabel: {
			fontWeight: 700,
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '18px',
			lineHeight: '25px',
			letterSpacing: '-0.5px',
			color: '#111111',
			width: '250px'
		},
		valueCodesItemText: {
			fontWeight: 500,
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '25px',
			letterSpacing: '-0.5px',
			color: '#111111',
			width: '400px'
		},
		statusButton: {
			marginLeft: 'auto'
		},
		status: {
			color: '#2D7D44',
			backgroundColor: '#E9F7ED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		active: {
			color: '#2D7D44',
			backgroundColor: '#E9F7ED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		inactive: {
			color: '#5F5F5F',
			backgroundColor: '#EDEDED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		statusBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
			borderTopLeftRadius: '8px',
			borderBottomLeftRadius: '8px',
			borderTopRightRadius: '0',
			borderBottomRightRadius: '0',
			width: '110px',
			height: '30px',
			cursor: 'default'
		},
		statusBtnTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '0.5px',
			color: '#5F5F5F',
			textTransform: 'capitalize'
		},
		arrowBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
			borderTopRightRadius: '8px',
			borderBottomRightRadius: '8px',
			borderTopLeftRadius: '0',
			borderBottomLeftRadius: '0',
			width: '20px',
			height: '36px',
			display: 'inline',
			padding: '7px'
		},
		activeIndicator: {
			height: '8px',
			width: '8px',
			background: '#3BA359',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginBottom: '1px'
		},
		inactiveIndicator: {
			height: '8px',
			width: '8px',
			background: '#EB5757',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginBottom: '1px'
		},
		subtitle: {
			margin: '33px 40px 5px 40px',
			paddingTop: '30px',
			paddingBottom: '10px',
			display: 'flex',
			alignItems: 'center',
			color: '#111111',
			fontWeight: 700,
			fontSize: '18px',
			fontFamily: 'Manrope',
			lineHeight: '25px',
			borderBottom: '1px solid #E2E3E9'
		},
		fieldInBlue: {
			color: uiColor.secondary,
			fontWeight: 'bold',
			letterSpacing: '-0.5px',
			cursor: 'pointer'
		},
		searchFieldBox: {
			padding: '11px 40px 37px 40px',
			borderRadius: '8px',
			background: '#FFFFFF',
			border: '1px solid #E0E0E0',
			boxSizing: 'border-box',
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)'
		},
		searchAnyFieldContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			width: '900px'
		},
		searchAnyFieldBox: {
			width: '636px',
			textAlign: 'left',
			marginTop: '20px',
			padding: 0
		},
		searchAnyFieldBoxLast: {
			width: '636px',
			textAlign: 'left',
			marginTop: '20px',
			marginBottom: '40px',
			padding: 0
		},
		permissionsText: {
			width: '170px',
			height: '25px',
			position: 'relative',
			top: '6.5px',
			textAlign: 'left',
			color: '#111111',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '18px',
			lineHeight: '25px',
			letterSpacing: '-0.5px',
			marginTop: '10px'
		},
		subtitleRole: {
			alignItems: 'center',
			fontSize: '18px',
			fontFamily: 'Manrope',
			lineHeight: '25px',
			width: '150px',
			marginTop: '20px',
			marginLeft: '50px',
			color: uiColor.secondary,
			fontWeight: 'bold',
			letterSpacing: '-0.5px',
			cursor: 'pointer'
		},
		blueItemText: {
			fontWeight: 'bold',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '25px',
			letterSpacing: '-0.5px',
			color: uiColor.secondary,
			width: '400px',
			cursor: 'pointer'
		}
	})
);
