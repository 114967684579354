import React, { useEffect, useState } from 'react';
import { List, TextField, DateField, useDataProvider, useNotify } from 'react-admin';
import { useStyles } from '../services/servicesStyles';
import { EmptyGrid } from '../common/EmptyGrid';
import { HeaderList } from '../common/HeaderList';
import { InfiniteDataGrid } from '../common/customDataGrid/InfiniteDataGrid';
import { ColumnCustom, TrueFalseColumn } from '../common/ColumnCustom';
import { MyShowButton } from '../common/myDataGrid/MyDatagrid';
import { UsersFilters } from './UsersFilters';

function UsersList(props: any) {
	const classes = useStyles();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const [search, searchInput] = React.useState('');
	const [filter, setFilter] = useState<any>({ status: 'all', inviteAccepted: 'all' });
	const [roles, setRoles] = useState<any>([]);

	const retrieveRoles = () => {
		dataProvider
			.getList('roles', {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then(({ data }) => {
				setRoles(data);
			})
			.catch((error) => {
				notify('Error getting roles: ' + error, 'warning');
			});
	};

	useEffect(() => {
		if (roles.length === 0) retrieveRoles();
	}, []);

	return (
		<>
			<HeaderList searchInput={searchInput} idSearch='branchName' showSearch={true} {...props} />
			<UsersFilters filter={filter} setFilter={setFilter} view={'list'} roles={roles} />
			<div className={classes.listContainer}>
				<List
					{...props}
					bulkActionButtons={false}
					empty={<EmptyGrid />}
					actions={null}
					pagination={false}
					perPage={10}
					filter={filter}
					sort={{ field: 'name', order: 'ASC' }}
					syncWithService
					classes={{
						root: classes.listRoot,
						content: classes.listContent
					}}
				>
					<InfiniteDataGrid
						hover={true}
						classes={{
							table: classes.table,
							thead: classes.thead,
							tbody: classes.tbody,
							headerCell: classes.headerCell,
							rowOdd: classes.rowOdd
						}}
						hasRowClick={true}
						filter={{ key: 'label', value: `${search}` }}
					>
						<TextField label='Name' source='name' />
						<TextField label='Username' source='userName' />
						<TextField label='Email' source='email' />
						<TextField label='Role' source='role.name' />
						<DateField label='Date Created' source='createdOn' showTime />
						<DateField label='Last Updated' source='lastUpdated' showTime />
						<ColumnCustom label='Status' />
						<TrueFalseColumn label='Invite Accepted' />
						<MyShowButton />
					</InfiniteDataGrid>
				</List>
			</div>
		</>
	);
}

export default UsersList;
