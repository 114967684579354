import React, { useState, useEffect } from 'react';
import { SimpleShowLayout, useNotify, TextField, useDataProvider } from 'react-admin';
import { Button, Divider, Container, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { RaBox } from 'ra-compact-ui';
import moment from 'moment';
import LocationsDetailList from './LocationsDetailList';
import { AddSubLocationDialog } from './Dialogs/AddSubLocationDialog';
import useStyles from './styles';
import { hasCreate, hasViewById } from '../../utils/permissions';

export const LocationDetailShow = (props: any) => {
	const classes = useStyles();
	const history = useHistory();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const [openAddSubLocationDialog, setOpenAddSubLocationDialog] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [filter, setFilter] = useState({ parent: props.record.id, status: 'all' });
	const { record } = props;
	const [parentName, setParentName] = useState('');
	const [serviceName, setServiceName] = useState('');

	useEffect(() => {
		if (record.parentId) {
			dataProvider.getOne('locations', { id: record.parentId }).then(({ data }) => {
				setParentName(data.name);
			});
		}
		if (record.serviceId) {
			dataProvider.getOne('services', { id: record.serviceId }).then(({ data }) => {
				setServiceName(data.name);
			});
		}
	}, [dataProvider, record]);

	const addSubLocationClicked = () => {
		setOpenAddSubLocationDialog(true);
	};

	const addSubLocationDialogClosed = () => {
		setOpenAddSubLocationDialog(false);
	};

	const dialogAddSubLocationClicked = (roomData: any) => {};

	const goToLocation = (locationId: any) => {
		history.push(`/locations/${locationId}/show`);
	};
	const goToService = (serviceId: any) => {
		history.push(`/services/${serviceId}/show`);
	};

	useEffect(() => {
		if (hasViewById(null, 'locations', record.id) === false) {
			notify(`Unauthorized`, 'warning');
			history.push(`${props.basePath}`);
		}
	}, [record.id]);

	return (
		<Container className={classes.container}>
			<SimpleShowLayout>
				<span className={classes.menuTitle}>Identification</span>
				<Divider className={classes.dividerDetailShow} />
				<RaBox>
					<Grid container className={classes.gridContainer}>
						<Grid item xs={6} className={classes.detailLabelGrid}>
							<label className={classes.detailLabel}>Parent Location</label>
						</Grid>
						<Grid item xs={6} className={classes.detailLabelGrid}>
							<label className={classes.detailLabel}>Service</label>
						</Grid>
						<Grid item xs={6} className={classes.detailTextFieldGrid}>
							{props.record?.parentId ? (
								<span
									onClick={() => goToLocation(props.record.parentId)}
									className={classes.fieldInBlue}
								>
									{parentName}
								</span>
							) : (
								<span>None</span>
							)}
						</Grid>
						<Grid item xs={6} className={classes.detailTextFieldGrid}>
							{props.record?.serviceId ? (
								<span
									onClick={() => goToService(props.record.serviceId)}
									className={classes.fieldInBlue}
								>
									{serviceName}
								</span>
							) : (
								<span>None</span>
							)}
						</Grid>
						<Grid item xs={6} className={classes.detailLabelGrid}>
							<span className={classes.detailLabel}>Location ID</span>
						</Grid>
						<Grid item xs={6} className={classes.detailLabelGrid}>
							<span className={classes.detailLabel}>Service Location ID</span>
						</Grid>
						<Grid item xs={6} className={classes.detailTextFieldGrid}>
							<TextField source='id' className={classes.textField} />
						</Grid>
						<Grid item xs={6} className={classes.detailTextFieldGrid}>
							<span>
								{props.record?.serviceLocationId ? props.record?.serviceLocationId : 'None'}
							</span>
						</Grid>
						<Grid item xs={6} className={classes.detailLabelGrid}>
							<span className={classes.detailLabel}>AS400ID</span>
						</Grid>
						<Grid item xs={6} className={classes.detailLabelGrid}>
							<span className={classes.detailLabel}>Date Created</span>
						</Grid>
						<Grid item xs={6} className={classes.detailTextFieldGrid}>
							<span>{props.record?.aS400ID ? props.record?.aS400ID : 'None'}</span>
						</Grid>
						<Grid item xs={6} className={classes.detailTextFieldGrid}>
							<span>{moment(props.record?.createdOn).format('MM/DD/YYYY h:mm A')}</span>
						</Grid>
						<Grid item xs={6} className={classes.detailLabelGrid}>
							<span className={classes.detailLabel}>Last Updated</span>
						</Grid>
						<Grid item xs={6} className={classes.detailTextFieldGrid}>
							<span></span>
						</Grid>
						<Grid item xs={6} className={classes.detailTextFieldGrid}>
							<span>{moment(props.record?.lastUpdated).format('MM/DD/YYYY h:mm A')}</span>
						</Grid>
					</Grid>
				</RaBox>
				<span className={classes.menuTitleChildren}>
					Children Locations ({props.record?.childrenLocations})
				</span>
				<Divider className={classes.dividerDetailShow} />
				{hasCreate(null, props.resource) ? (
					<div className={classes.createSublocationButton}>
						<Button
							variant='contained'
							size='small'
							onClick={addSubLocationClicked}
							classes={{
								root: classes.createBtnRoot,
								label: classes.createBtnTxt
							}}
						>
							New child location
						</Button>
					</div>
				) : (
					<div></div>
				)}
				<LocationsDetailList filter={filter}></LocationsDetailList>
			</SimpleShowLayout>
			{hasCreate(null, props.resource) ? (
				<AddSubLocationDialog
					openAddSubLocation={openAddSubLocationDialog}
					addSubLocationClosed={addSubLocationDialogClosed}
					addSubLocationClicked={dialogAddSubLocationClicked}
					parentId={props.record.id}
					serviceId={props.record.serviceId}
				/>
			) : (
				<></>
			)}
		</Container>
	);
};
