export const CURRENCY_TYPE = [
	{
		id: 'all',
		description: 'All'
	},
	{
		id: 'usd',
		description: 'USD'
	},
	{
		id: 'wash',
		description: 'Wash'
	},
	{
		id: 'wdf',
		description: 'Wash & Fold'
	},
	{
		id: 'dry',
		description: 'Dry'
	}
];
export const TRANSACTION_TYPE = [
	{
		id: 'all',
		description: 'All'
	},
	{
		id: 'debit',
		description: 'Debit'
	},
	{
		id: 'credit',
		description: 'Credit'
	},
	{
		id: 'refund',
		description: 'Refund'
	},
	{
		id: 'transfer',
		description: 'Transfer'
	},
	{
		id: 'balance-forward',
		description: 'Balance forward'
	}
];
export const DATA_SOURCE = [
	{
		id: 'current',
		description: 'Current'
	},
	{
		id: 'archive',
		description: 'Archive'
	}
];
