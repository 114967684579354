import { makeStyles, createStyles } from '@material-ui/core';
import uiColor from '../../utils//utilsColors';

export const useStyles = makeStyles(() =>
    createStyles({
        listContainer: {
            width: 'auto',
            marginLeft: 0,
            borderTop: '1px solid #E2E3E9',
            background: '#F2F4FB'
        },
        listRoot: {
            margin: '24px 40px 0px 40px'
        },
        listContent: {
            boxShadow: '0 0 0 white',
            background: '#F2F4FB'
        },
        table: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            background: '#FFFFFF',
            minWidth: '980px'
        },
        thead: {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px'
        },
        tbody: {
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px'
        },
        headerCell: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '-0.5px',
            color: '#111111',
            padding: '20px 24px',
            width: '100px',
            '&:first-child': {
                width: '200px'
            },
            '&:last-child': {
                width: '10px'
            }
        },
        branchName: {
            fontWeight: 'bold',
            color: '#1695CB'
        },
        location: {
            fontWeight: 'bold',
            color: '#1695CB'
        },
        status: {
            color: '#2D7D44',
            backgroundColor: '#E9F7ED',
            fontWeight: 500,
            padding: '3px 10px'
        },
        rowOdd: {
            backgroundColor: '#F8F9FA',
            cursor: 'pointer'
        },
        exportBtnRoot: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            padding: '8px, 16px, 8px, 12px',
            borderRadius: '8px',
            width: '163px',
            height: '40px',
            background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
        },
        exportBtnTxt: {
            color: '#FFFFFF',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 'bold',
            letterSpacing: '0.5px'
        },
        addUserDialogItem: {
            margin: '33px 40px',
            width: '600px',
            display: 'flex',
            alignItems: 'center',
            minWidth: '600px'
        },
        addRoleDialogItem: {
            margin: '20px 0px',
            width: '500px',
            display: 'flex',
            alignItems: 'center'
        },
        addUserDialogItemLocations: {
            margin: '33px 40px',
            display: 'flex',
            alignItems: 'center'
        },
        addUserItemLabel: {
            width: '40%',
            fontWeight: 600,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111'
        },
        addRoleItemLabel: {
            width: '30%',
            fontWeight: 600,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111'
        },
        addUserItemLabelLocations: {
            width: '240px',
            fontWeight: 600,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111'
        },
        addRoleDialogItemLocations: {
            margin: '20px 0px',
            display: 'flex',
            alignItems: 'center'
        },
        addRoleItemLabelLocations: {
            width: '150px',
            fontWeight: 600,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111'
        },
        subtitle: {
            margin: '33px 40px',
            paddingBottom: '10px',
            alignItems: 'center',
            color: '#111111',
            fontWeight: 700,
            fontSize: '18px',
            fontFamily: 'Manrope',
            lineHeight: '25px',
            borderBottom: '1px solid #E2E3E9'
        },
        dialogContent: {
            padding: '16px 56px 34px 24px'
        },
        yesBtnRoot: {
            marginLeft: '16px',
            background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            width: '152px',
            height: '40px'
        },
        yesBtnTxt: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.5px',
            color: '#FFFFFF'
        },
        addUserBtnDisabled: {
            opacity: 0.3
        },
        addUserDialogAppBar: {
            position: 'relative',
            backgroundColor: 'white',
            minWidth: '1000px',
            boxShadow: '0 0 0',
            borderBottom: '1px solid #E2E3E9'
        },
        backIcon: {
            color: '#969696',
            cursor: 'pointer'
        },
        addUserDialogTitle: {
            flex: 1,
            paddingLeft: '8px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '33px',
            letterSpacing: '-0.75px',
            color: '#232F64',
            minWidth: '200px'
        },
        cancelBtnRoot: {
            background: '#FFFFFF',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            width: '152px',
            height: '36px'
        },
        cancelBtnTxt: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.5px',
            color: '#5F5F5F'
        },
        locationsSelect: {
            fontFamily: 'Manrope',
            '& a': {
                borderRadius: '8px',
                padding: '7px 16px !important',
                '& input': {
                    borderBottom: 'none !important',
                    fontSize: '16px',
                    fontFamily: 'Manrope',
                    fontWeight: 500,
                    fontStyle: 'normal',
                    lineHeight: '22px',
                    paddingRight: '103px !important'
                }
            },
            '& .dropdown .dropdown-trigger.arrow.bottom:after': {
                fontSize: '17px',
                color: 'rgba(0, 0, 0, 0.54)',
                content: '"\\23F7"'
            },
            '& .dropdown .dropdown-trigger.arrow.top:after': {
                fontSize: '17px',
                color: 'rgba(0, 0, 0, 0.54)',
                content: '"\\23F6"'
            },
            '& .dropdown .dropdown-content li': {
                fontSize: '18px'
            },
            '& .dropdown': {
                width: '360px'
            },
            '& .dropdown .dropdown-content': {
                width: '360px'
            }
        },
        label: {
            fontSize: '13px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            color: '#969696',
            position: 'absolute',
            left: '25px',
            top: '-7px',
            backgroundColor: 'white',
            zIndex: 2,
            padding: '0 3px'
        },
        searchAnyFieldContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '900px'
        },
        searchAnyFieldBox: {
            width: '636px',
            textAlign: 'left',
            marginTop: '20px',
            padding: 0
        },
        searchAnyFieldBoxRoles: {
            width: '636px',
            textAlign: 'left',
            padding: 0
        },
        searchAnyFieldBoxLast: {
            width: '636px',
            textAlign: 'left',
            marginTop: '20px',
            marginBottom: '40px',
            padding: 0
        },
        permissionsText: {
            width: '170px',
            height: '25px',
            position: 'relative',
            top: '6.5px',
            textAlign: 'left',
            color: '#111111',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            marginTop: '10px'
        },
        permissionsTextRole: {
            // width: '170px',
            position: 'relative',
            top: '6.5px',
            textAlign: 'left',
            color: '#111111',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            marginTop: '10px'
        },
        searchFieldBox: {
            padding: '11px 40px 37px 40px',
            borderRadius: '8px',
            background: '#FFFFFF',
            border: '1px solid #E0E0E0',
            boxSizing: 'border-box',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            marginBottom: '20px'
        },
        rolesItemLabel: {
            fontWeight: 700,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111',
            width: '250px'
        },
        roleNameContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignItems: 'start'
        },
        selectAllContainer: {
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignItems: 'start'
        },
        subtitleRole: {
            alignItems: 'center',
            fontSize: '18px',
            fontFamily: 'Manrope',
            lineHeight: '25px',
            width: '150px',
            marginTop: '20px',
            marginLeft: '50px',
            color: uiColor.secondary,
            fontWeight: 'bold',
            letterSpacing: '-0.5px',
            cursor: 'pointer'
        },
        myMenu: {
            minHeight: '100vh',
            width: '170px',
            paddingTop: '10px'
        },
        menuLink: {
            color: uiColor.inactive,
            fontSize: '18px',
            fontWeight: 'bold'
        },
        active: {
            color: uiColor.sidebar_active
        },
        valueCodesItemText: {
            fontWeight: 500,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111',
            width: '400px'
        },
        valueCodesItemLabel: {
            fontWeight: 700,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111',
            width: '250px'
        },
        searchAnyFieldTitle: {
            fontSize: '23px',
            lineHeight: '30px',
            fontWeight: 'bold',
            letterSpacing: '-0.75px',
            marginBottom: '9px',
            marginTop: '20px'
        },
        createBtnRoot: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            padding: '8px, 16px, 8px, 12px',
            borderRadius: '8px',
            width: '140px',
            height: '40px',
            background: '#FFFFFF',
            marginTop: '20px',
            marginBottom: '20px'
        },
        createBtnTxt: {
            color: '#5F5F5F',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 'bold',
            letterSpacing: '0.5px'
        },
        editRoleButton: {
            position: 'absolute',
            right: '45%',
            top: '225px'
        },
        subtitleRolePermissions: {
            margin: '60px 40px',
            paddingBottom: '10px',
            alignItems: 'center',
            color: '#111111',
            fontWeight: 700,
            fontSize: '18px',
            fontFamily: 'Manrope',
            lineHeight: '25px',
            borderBottom: '1px solid #E2E3E9'
        },
        checkBoxAll: {
            width: '280px',
            '& .MuiTypography-body1': {
                fontWeight: 600
            }
        },
        checkBoxApplication: {
            '& .MuiTypography-body1': {
                marginLeft: '25px',
                fontWeight: 600
            }
        },
        tableRow: {
            '&$hover:hover': {
                background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
                cursor: 'pointer'
            }
        },
        tokenText: {
            marginTop: '10px'
        },
        tokenItemLabel: {
            fontWeight: 600,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111',
            marginRight: '55px'
        },
        appItemText: {
            fontWeight: 600,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111',
            width: '400px'
        },
        serviceItemLabel: {
            fontWeight: 600,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111',
            marginRight: '110px'
        },
        locationItemLabel: {
            fontWeight: 600,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111',
            marginRight: '97px'
        },
        gridContainer: {},
        detailLabelGrid: {},
        tokenItemText: {
            fontWeight: 500,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111',
            width: '400px',
            wordBreak: 'break-word'
        }
    })
);
