import * as React from 'react';
import { forwardRef, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useRefresh, useNotify, useMutation } from 'react-admin';
import {
	Typography,
	Button,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	TextField,
	DialogActions,
	IconButton
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../styles';
import { EditCurrencyTypeProps } from './DialogsProps';
import { ActiveInactiveCurrencyTypeDialog } from './ActiveInactiveCurrencyTypeDialog';

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export const EditCurrencyTypeDialog = ({
	openEditCurrencyType,
	editCurrencyTypeClosed,
	currencyType
}: EditCurrencyTypeProps) => {
	const classes = useStyles();
	const refresh = useRefresh();
	const notify = useNotify();
	const [mutate] = useMutation();
	const [editCurrencyName, setCurrencyName] = useState('');
	const [editCurrencyDescription, setCurrencyDescription] = useState('');
	const [openActiveInactiveDialog, setOpenActiveInactiveDialog] = useState(false);

	useEffect(() => {
		if (currencyType?.name) setCurrencyName(currencyType.name);
		if (currencyType?.description) setCurrencyDescription(currencyType.description);
	}, [currencyType]);

	const onEditCurrencyTypeClicked = () => {
		mutate(
			{
				type: 'update',
				resource: 'currencytypes',
				payload: {
					id: currencyType?.id,
					data: {
						Name: editCurrencyName,
						Description: editCurrencyDescription,
						serviceId: currencyType?.serviceId,
						Monetary: false
					}
				}
			},
			{
				onSuccess: ({ data }) => {
					editCurrencyTypeClosed();
					refresh();
					notify(`Currency type edited: ${editCurrencyName}.`);
				},
				onFailure: (error) => {
					notify(`Currency type not edited`, 'warning');
					console.log('Error: ' + error);
				}
			}
		);
	};

	const handleChangeName = (event: any) => {
		setCurrencyName(event.target.value);
	};

	const handleChangeDescription = (event: any) => {
		setCurrencyDescription(event.target.value);
	};

	const onCancelClicked = (event: any) => {
		setCurrencyName(currencyType?.name);
		setCurrencyDescription(currencyType?.description);
		editCurrencyTypeClosed();
	};

	const onActiveInactiveClicked = (event: any) => {
		mutate(
			{
				type: 'delete',
				resource: 'currencytypes',
				payload: {
					id: currencyType?.id
				}
			},
			{
				onSuccess: () => {
					editCurrencyTypeClosed();
					refresh();
					notify(
						`Currency type: ${editCurrencyName} ${
							currencyType?.active === false ? 'activated.' : 'deactivated.'
						}`
					);
				},
				onFailure: (error) => {
					notify(
						`Currency type: ${editCurrencyName} ${
							currencyType?.active === false ? 'not activated.' : 'not deactivated.'
						}`
					);
					console.log('Error: ' + error);
				}
			}
		);
	};

	const activeInactiveDialogClosed = () => {
		setOpenActiveInactiveDialog(false);
	};

	const onClickActivateDeactivate = () => {
		setOpenActiveInactiveDialog(true);
	};

	return (
		<Dialog
			open={openEditCurrencyType}
			onClose={editCurrencyTypeClosed}
			TransitionComponent={Transition}
		>
			<AppBar className={classes.addServiceDialogAppBar}>
				<Toolbar>
					<Typography className={classes.addServiceDialogTitle}>Edit Currency Type</Typography>
					<IconButton aria-label='close' className={classes.closeBtn} onClick={onCancelClicked}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Name'
						size='small'
						type='search'
						value={editCurrencyName}
						onChange={handleChangeName}
					/>
				</div>
			</div>
			<div>
				<div className={classes.addRoomDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Description'
						size='small'
						type='search'
						multiline
						value={editCurrencyDescription}
						onChange={handleChangeDescription}
					/>
				</div>
			</div>
			<DialogActions>
				<Button
					onClick={onClickActivateDeactivate}
					size='small'
					variant='contained'
					color='default'
					classes={{
						root: classes.cancelBtnRoot,
						label: classes.cancelBtnTxt
					}}
				>
					{currencyType?.active === true ? 'Deactivate' : 'Activate'}
				</Button>
				<Button
					disabled={isEmpty(editCurrencyName.trim())}
					onClick={onEditCurrencyTypeClicked}
					size='small'
					color='secondary'
					variant='contained'
					classes={{
						root: classes.yesBtnRoot,
						label: classes.yesBtnTxt,
						disabled: classes.addRoomBtnDisabled
					}}
				>
					Save
				</Button>
			</DialogActions>
			<ActiveInactiveCurrencyTypeDialog
				activeInactiveClosed={activeInactiveDialogClosed}
				openActiveInactive={openActiveInactiveDialog}
				activeInactiveClicked={onActiveInactiveClicked}
				active={!currencyType?.active}
			/>
		</Dialog>
	);
};
