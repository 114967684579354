import React from 'react';
import { DatagridBody } from 'react-admin';
import CustomDataGridRow from './CustomDataGridRow';

/**
 *
 * @param props
 * @constructor
 */
const CustomDatagridBody = (props: any) => {
	const row = (
		<CustomDataGridRow
			filterSearch={props.filterSearch}
			hasRowClick={props.hasRowClick}
			whenRowClicked={props.whenRowClicked}
		/>
	);

	return <DatagridBody {...props} row={row} />;
};

export default CustomDatagridBody;
