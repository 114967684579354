/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
	Show,
	useDataProvider,
	useMutation,
	useNotify,
	useRefresh,
	useShowController,
	downloadCSV,
	fetchUtils,
	HttpError
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import { RaBox } from 'ra-compact-ui';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import { ConsumerHeaderGrids } from './ConsumerHeaderGrids';
import uiColor from '../../utils/utilsColors';
import CustomTransactionList from '../transactions/CustomTransactionList';
import { TransactionsFilters } from '../transactions/TransactionsFilters';
import CustomValueCodesList from './CustomValueCodesList';

/**
 * Read only list of branches
 * https://marmelab.com/react-admin/doc/2.8/Show.html
 * @param props
 * @constructor
 */
export const ConsumerShow = (props: any) => {
	const httpClient = fetchUtils.fetchJson;
	const classes = useStyles();
	const dataProvider = useDataProvider();
	const [curSort, setCurSort] = useState({});
	const [search, searchInput] = useState('');
	const history = useHistory();
	const notify = useNotify();
	const [services, setServices] = useState<any>([]);
	const [locations, setLocations] = useState<any>([]);
	const [valueCodes, setValueCodes] = useState<any>([]);
	const [valueCodesTable, setValueCodesTable] = useState<any>([]);
	const [valueStore, setValueStore] = useState<any>(null);
	const [valueStores, setValueStores] = useState<any>([]);
	const [filter, setFilter] = useState<any>({});
	const [filterValueCode, setFilterValueCode] = useState<any>({ valueStoreId: '' });
	const { record } = useShowController(props);
	const apiUrl = process.env.REACT_APP_API_URL as string;
	const token = localStorage.getItem('cscvc_token') as string;

	useEffect(() => {
		dataProvider
			.getList('services', {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then(({ data }) => {
				setServices(data);
			})
			.catch((error) => {
				notify('Error getting services: ' + error, 'warning');
			});
		dataProvider
			.getList('locations', {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then(({ data }) => {
				setLocations(data);
			})
			.catch((error) => {
				notify('Error getting locations: ' + error, 'warning');
			});
		if (record) {
			const email = localStorage.getItem('cscvc_loginEmail');
			const headers = new Headers({
				Accept: 'application/json',
				Authorization: token
			});
			if (email) headers.set('User', btoa(email));
			httpClient(`${apiUrl}/valuecodes/${record?.id}?consumerId=true`, {
				headers
			})
				.then(({ json }) => {
					setValueCodes(json);
					const valueStores: string[] = [];
					const valueCodes: string[] = [];
					// eslint-disable-next-line array-callback-return
					json.map((valueCode: any) => {
						valueCodes.push(valueCode.id);
						if (valueCode.valueStoreId !== null) valueStores.push(valueCode.valueStoreId);
					});
					setFilter({ valueStores, valueStore: null });
					setValueStores(valueStores);
					setFilterValueCode({ valueCodes });
					setValueCodesTable(valueCodes);
				})
				.catch((e: HttpError) => {});
		}
	}, [record]);

	const onExports = () => {
		dataProvider
			.getList(`valuestores/${valueStore}/transactions`, {
				filter,
				sort: { field: 'transactionDate', order: 'ASC' },
				pagination: { page: 1, perPage: 1000000 }
			})
			.then((transactions: any) => {
				const transactionsForExport = transactions.data.map((transaction: any) => ({
					TransactionDate: transaction.transactionDate,
					Service: transaction.service?.name,
					Location: transaction.location?.name,
					AS400: transaction.aS400ID,
					HumanReadableId: transaction.valueCode?.humanReadableId,
					TransactionType: transaction.transactionType,
					CurrencyType: transaction.currencyType,
					Amount: `$ ${Number(transaction.amount).toFixed(2)}`
				}));
				jsonExport(
					transactionsForExport,
					{
						headers: []
					},
					// eslint-disable-next-line n/handle-callback-err
					(err, csv) => {
						downloadCSV(csv, 'transactions');
					}
				);
			});
	};

	return (
		<Show
			{...props}
			classes={{
				root: classes.showRoot,
				noActions: classes.showNoActions,
				card: classes.showCard
			}}
		>
			<div className={classes.container}>
				<ConsumerHeaderGrids
					searchInput={searchInput}
					idSearch='id'
					disableExport={true}
					curSort={curSort}
					onExports={onExports}
					id={props.id}
					record={record}
				/>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}>Consumer Information</div>
					<span className={classes.valueCodesItemLabel}>ID</span>
					<span className={classes.valueCodesItemText}>{record?.id}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>First Name</span>
					<span className={classes.valueCodesItemText}>{record?.firstName}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Last Name</span>
					<span className={classes.valueCodesItemText}>{record?.lastName}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Email</span>
					<span className={classes.valueCodesItemText}>{record?.email}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Phone</span>
					<span className={classes.valueCodesItemText}>{record?.phone}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Created Date</span>
					<span className={classes.valueCodesItemText}>
						{moment(record?.createdOn).format('MM/DD/YYYY h:mm A')}
					</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Last Updated</span>
					<span className={classes.valueCodesItemText}>
						{moment(record?.lastUpdated).format('MM/DD/YYYY h:mm A')}
					</span>
				</div>
				{valueCodes.length > 0 ? (
					<div>
						<div className={classes.subtitle}>Value Codes</div>
						<RaBox marginRight='30px' marginLeft='30px' marginTop='10px'>
							<CustomValueCodesList
								{...props}
								setValueCodes={setValueCodesTable}
								filter={filterValueCode}
								setFilter={setFilterValueCode}
							/>
						</RaBox>
						<div className={classes.subtitle}>Activity</div>
						<RaBox marginRight='30px' marginLeft='30px' marginTop='10px'>
							<TransactionsFilters
								filter={filter}
								setFilter={setFilter}
								onExports={onExports}
								locations={locations}
								services={services}
								valueStores={valueStores}
								showExportBtn={true}
								valueCodes={valueCodes}
							/>
							<CustomTransactionList valueStoreId={valueStore} filter={filter} {...props} />
						</RaBox>
					</div>
				) : (
					<div></div>
				)}
			</div>
		</Show>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		showRoot: {},
		showNoActions: {},
		showCard: {
			boxShadow: '0 0 0'
		},
		container: {},
		showLayout: {
			padding: 0,
			'&:first-child': {
				paddingTop: '0px'
			},
			'&:last-child': {
				paddingBottom: '0px'
			}
		},
		gridWrapper: {
			display: 'flex',
			margin: '0px 40px'
		},
		table: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			background: '#FFFFFF',
			minWidth: '1191px'
		},
		thead: {
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px'
		},
		tbody: {
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px',
			border: '1px solid #EEEFF3'
		},
		headerCell: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#111111',
			paddingTop: '12px',
			paddingBottom: '15px',
			paddingLeft: '24px',
			'&:first-child': {
				width: '20px',
				paddingLeft: '0',
				paddingRight: '0'
			},
			'&:nth-child(2)': {
				width: '350px'
			},
			'&:last-child': {
				width: '50px'
			}
		},
		locationName: {
			fontWeight: 'bold',
			color: uiColor.secondary,
			letterSpacing: '-0.5px'
		},
		tableRow: {
			'&$hover:hover': {
				background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
				cursor: 'pointer'
			}
		},
		hover: {},
		tableCell: {
			paddingTop: '16px',
			paddingBottom: '16px',
			borderRight: '1px solid #EEEFF3',
			paddingLeft: '24px'
		},
		tableCellNoBorder: {
			paddingTop: '16px',
			paddingBottom: '16px',
			paddingLeft: '24px'
		},
		showBtn: {
			visibility: 'hidden',
			'$hover:hover &': {
				visibility: 'visible'
			}
		},
		normalField: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#111111'
		},
		addRoomBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '1px 0px',
			borderRadius: '8px',
			width: '154px',
			height: '36px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		addRoomBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		tabsRoot: {
			borderBottom: '1px solid lightgray',
			padding: '0 40px'
		},
		tabsIndicator: {
			background: '#1695CB'
		},
		tabRoot: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			letterSpacing: '0.75px',
			fontWeight: 'bold',
			fontSize: '18px',
			lineHeight: '25px',
			color: '#969696',
			padding: 0,
			minWidth: '73px',
			marginRight: '32px'
		},
		tabSelected: {
			color: '#1695CB'
		},
		humanReadableId: {
			fontWeight: 'bold',
			color: '#1695CB',
			cursor: 'pointer'
		},
		codeInformationContainer: {
			display: 'flex',
			justifyContent: 'flex-start',
			margin: '33px 40px 0 40px',
			flexDirection: 'row'
		},
		subtitleValueCode: {
			// paddingBottom: '10px',
			alignItems: 'center',
			color: '#111111',
			fontWeight: 700,
			fontSize: '18px',
			fontFamily: 'Manrope',
			lineHeight: '25px',
			width: '250px'
		},
		valueCodesItemLabel: {
			fontWeight: 500,
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '18px',
			lineHeight: '25px',
			letterSpacing: '-0.5px',
			color: '#111111',
			width: '250px'
		},
		valueCodesItemText: {
			fontWeight: 700,
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '25px',
			letterSpacing: '-0.5px',
			color: '#111111',
			width: '400px'
		},
		statusButton: {
			marginLeft: 'auto'
		},
		status: {
			color: '#2D7D44',
			backgroundColor: '#E9F7ED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		active: {
			color: '#2D7D44',
			backgroundColor: '#E9F7ED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		inactive: {
			color: '#5F5F5F',
			backgroundColor: '#EDEDED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		statusBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
			borderTopLeftRadius: '8px',
			borderBottomLeftRadius: '8px',
			borderTopRightRadius: '0',
			borderBottomRightRadius: '0',
			width: '110px',
			height: '30px',
			cursor: 'default'
		},
		statusBtnTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '0.5px',
			color: '#5F5F5F',
			textTransform: 'capitalize'
		},
		arrowBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
			borderTopRightRadius: '8px',
			borderBottomRightRadius: '8px',
			borderTopLeftRadius: '0',
			borderBottomLeftRadius: '0',
			width: '20px',
			height: '36px',
			display: 'inline',
			padding: '7px'
		},
		activeIndicator: {
			height: '8px',
			width: '8px',
			background: '#3BA359',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginBottom: '1px'
		},
		inactiveIndicator: {
			height: '8px',
			width: '8px',
			background: '#EB5757',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginBottom: '1px'
		},
		subtitle: {
			margin: '33px 40px 5px 40px',
			paddingBottom: '10px',
			display: 'flex',
			alignItems: 'center',
			color: '#111111',
			fontWeight: 700,
			fontSize: '18px',
			fontFamily: 'Manrope',
			lineHeight: '25px',
			borderBottom: '1px solid #E2E3E9'
		},
		fieldInBlue: {
			color: uiColor.secondary,
			fontWeight: 'bold',
			letterSpacing: '-0.5px',
			cursor: 'pointer'
		}
	})
);
