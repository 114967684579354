import React, { useEffect, useState } from 'react';

import { makeStyles, createStyles, Theme, Typography, Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from 'react-router-dom';
import { ValueCode } from '../../../types';
import MenuDropdown from '../common/MenuDropdown';
import { hasDelete } from '../../utils/permissions';

export const ValueCodeHeaderGrids = ({ codeStatusClicked, record }: any, props: any) => {
	const classes = useStyles();
	const history = useHistory();
	const [valueCode, setValueCode] = useState<ValueCode | null>(null);
	const [listName, setListName] = useState('');

	useEffect(() => {
		setValueCode(JSON.parse(localStorage.getItem('valuCode') as string));
		if (record) setValueCode(record);
		setListName(localStorage.getItem('listName') as string);
	}, [record]);

	const firstBreadcrumbTitleClicked = () => {
		history.push('/valuecodelists');
	};

	const secondBreadcrumbTitleClicked = () => {
		history.push(`/valuecodelists/${valueCode?.valueCodeListId}/show`);
	};

	const optionsActivate: any = [
		{
			label: 'Activate',
			action: codeStatusClicked,
			disabled: false
		}
	];

	const optionsDeactivate: any = [
		{
			label: 'Deactivate',
			action: codeStatusClicked,
			disabled: false
		}
	];

	return (
		<div className={classes.showTitle}>
			<div className={classes.titleContainer}>
				<div className={classes.breadcrumsContainer}>
					<Typography noWrap className={classes.breadcrumbTitle}>
						<span className={classes.firstBreadcrumbTitle} onClick={firstBreadcrumbTitleClicked}>
							Value Code Lists
						</span>
						<NavigateNextIcon fontSize='small' className={classes.navigationIcon} />
						<span className={classes.firstBreadcrumbTitle} onClick={secondBreadcrumbTitleClicked}>
							{listName || 'List Name'}
						</span>
						<NavigateNextIcon fontSize='small' className={classes.navigationIcon} />
						<span className={classes.secondBreadcrumbTitle}>
							{valueCode?.humanReadableId || valueCode?.id}
						</span>
					</Typography>
				</div>
				<div className={classes.secondTitleContainer}>
					<Typography noWrap className={classes.secondTitle}>
						{`${valueCode?.humanReadableId || valueCode?.id}`}
					</Typography>
					{hasDelete(null, 'valueCodes') ? (
						<Typography noWrap className={classes.address}>
							<Button
								size='small'
								variant='contained'
								color='default'
								classes={{
									root: classes.statusBtnRoot,
									label: classes.statusBtnTxt
								}}
							>
								{valueCode?.active && (
									<>
										<span className={classes.activeIndicator}></span>
										<span>Active</span>
									</>
								)}
								{!valueCode?.active && (
									<>
										<span className={classes.inactiveIndicator}></span>
										<span>Inactive</span>
									</>
								)}
							</Button>
							<div className={classes.arrowBtnRoot}>
								{valueCode?.active && (
									<MenuDropdown options={optionsDeactivate} iconName='ArrowDropDownIcon' />
								)}
								{!valueCode?.active && (
									<MenuDropdown options={optionsActivate} iconName='ArrowDropDownIcon' />
								)}
							</div>
						</Typography>
					) : (
						<div></div>
					)}
				</div>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		showTitle: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			minWidth: '1376px'
		},
		titleContainer: {
			background: 'white',
			borderBottom: '1px solid #E2E3E9',
			height: '182px'
		},
		breadcrumsContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginTop: '5px',
			marginLeft: '40px',
			marginRight: '40px',
			marginBottom: '13px'
		},
		creatorInfo: {
			marginTop: '5px'
		},
		breadcrumbTitle: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#969696'
		},
		firstBreadcrumbTitle: {
			'&:hover': {
				cursor: 'pointer'
			}
		},
		navigationIcon: {
			position: 'relative',
			top: '5px',
			margin: '0px 5px 0px 6px',
			color: '#BDBDBD'
		},
		arrowDropDownIcon: {
			position: 'relative',
			color: '#5F5F5F'
		},
		secondBreadcrumbTitle: {
			color: '#5F5F5F'
		},
		secondTitleContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			borderTop: '1px solid #E0E0E0'
		},
		thirdTitleContainer: {
			display: 'flex',
			justifyContent: 'left',
			marginTop: '21px',
			marginLeft: '40px'
		},
		secondTitle: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '44px',
			fontWeight: 800,
			lineHeight: '113%',
			marginLeft: '40px',
			marginTop: '21px',
			color: '#232F64',
			letterSpacing: '-1px'
		},
		address: {
			marginTop: '21px',
			paddingLeft: '33.88px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '18px',
			lineHeight: '25px',
			color: '#5F5F5F',
			height: '48px',
			width: '200px'
		},
		toolbarContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			paddingLeft: '41px',
			paddingRight: '40px',
			height: '73px'
		},
		filter: {
			position: 'relative',
			top: '-10px'
		},
		searchInput: {
			width: '300px'
		},
		toolbarRight: {
			display: 'flex',
			alignItems: 'center'
		},
		statusInformation: {
			display: 'flex',
			alignItems: 'center',
			marginRight: '32px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#5F5F5F'
		},
		onlineIndicator: {
			height: '8px',
			width: '8px',
			background: '#2AAAE1',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginBottom: '1px'
		},
		offlineIndicator: {
			height: '8px',
			width: '8px',
			background: '#FF746B',
			borderRadius: '50%',
			display: 'inline-block',
			marginLeft: '24px',
			marginRight: '7px',
			marginBottom: '1px'
		},
		divider: {
			background: '#D0D5E8',
			height: '32px',
			marginLeft: '26px'
		},
		locations: {
			marginLeft: '31px'
		},
		locationCount: {
			color: '#232F64',
			fontWeight: 'bold',
			letterSpacing: '-0.5px',
			marginLeft: '16px'
		},
		exportBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '1px 0px',
			borderRadius: '8px',
			width: '129px',
			height: '36px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		exportBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		exportBtnDisabled: {
			opacity: '0.5'
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff'
		},
		label: {
			color: '#232F64',
			fontSize: '16px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold'
		},
		labelText: {
			color: '#5F5F5F',
			fontSize: '16px',
			fontFamily: 'Manrope',
			fontStyle: 'normal'
		},
		divider2: {
			background: '#D0D5E8',
			height: '20px',
			marginLeft: '26px',
			marginRight: '26px',
			paddingBottom: '5px'
		},
		divider3: {
			height: '10px',
			marginRight: '10px',
			marginLeft: '10px',
			borderLeft: '1px solid #D0D5E8'
		},
		statusBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
			borderTopLeftRadius: '8px',
			borderBottomLeftRadius: '8px',
			borderTopRightRadius: '0',
			borderBottomRightRadius: '0',
			width: '110px',
			height: '36px',
			cursor: 'default'
		},
		statusBtnTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '0.5px',
			color: '#5F5F5F',
			textTransform: 'capitalize'
		},
		arrowBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
			borderTopRightRadius: '8px',
			borderBottomRightRadius: '8px',
			borderTopLeftRadius: '0',
			borderBottomLeftRadius: '0',
			width: '20px',
			height: '36px',
			display: 'inline',
			padding: '7px'
		},
		activeIndicator: {
			height: '8px',
			width: '8px',
			background: '#3BA359',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginBottom: '1px'
		},
		inactiveIndicator: {
			height: '8px',
			width: '8px',
			background: '#EB5757',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginBottom: '1px'
		},
		exportBtnContainer: {
			position: 'absolute',
			right: '40px',
			top: '140px'
		}
	})
);
