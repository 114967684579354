import { makeStyles, createStyles, Theme } from '@material-ui/core';
import uiColor from '../../utils/utilsColors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        navIcon: {
            position: 'relative',
            top: '5px',
            margin: '0px 5px 0px 6px',
            color: '#BDBDBD'
        },
        navLocationName: {
            color: '#5F5F5F'
        },
        locationBar: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '22px'
        },
        locationName: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '44px',
            lineHeight: '113%',
            letterSpacing: '-1px',
            fontWeight: 800,
            color: '#232F64'
        },
        locationInfo: {
            position: 'relative',
            top: '6px',
            marginRight: '144px',
            height: '75px',
            display: 'flex',
            fontFamily: 'Manrope',
            fontStyle: 'normal'
        },
        locationId: {
            fontSize: '18px',
            fontWeight: 'bold',
            lineHeight: '25px',
            marginRight: '37px',
            color: '#232F64',
            width: '227px',
            height: '25px',
            textAlign: 'right',
            letterSpacing: '-0.5px'
        },
        locationInfoDivider: {
            color: '#E0E0E0',
            width: '1px',
            height: '48px'
        },
        locationAddress: {
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '25px',
            color: '#5F5F5F',
            height: '75px',
            marginLeft: '30px'
        },
        addRoomBtnRoot: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            padding: '1px 0px',
            borderRadius: '8px',
            width: '154px',
            height: '36px',
            background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
        },
        addRoomBtnTxt: {
            color: '#FFFFFF',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 'bold',
            letterSpacing: '0.5px'
        },
        addRoomDialogAppBar: {
            position: 'relative',
            backgroundColor: 'white',
            minWidth: '1000px',
            boxShadow: '0 0 0',
            borderBottom: '1px solid #E2E3E9'
        },
        addRoomDialogTitle: {
            flex: 1,
            paddingLeft: '8px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '33px',
            letterSpacing: '-0.75px',
            color: '#232F64',
            minWidth: '200px'
        },
        subtitle: {
            margin: '33px 40px 0 40px',
            paddingBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            color: '#111111',
            fontWeight: 700,
            fontSize: '18px',
            fontFamily: 'Manrope',
            lineHeight: '25px',
            borderBottom: '1px solid #E2E3E9',
            minWidth: '350px'
        },
        addRoomBtnDisabled: {
            opacity: 0.3
        },
        addRoomDialogItem: {
            margin: '33px 126px',
            width: '600px',
            display: 'flex',
            alignItems: 'center'
        },
        addValueCodeListItemLabel: {
            width: '40%',
            fontWeight: 500,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111'
        },
        listContainer: {
            marginTop: '15px',
            padding: '0px'
        },
        tabsRoot: {
            borderBottom: '1px solid lightgray',
            padding: '0 40px'
        },
        tabsIndicator: {
            background: '#1695CB'
        },
        tabRoot: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            letterSpacing: '0.75px',
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '25px',
            color: '#969696',
            padding: 0,
            minWidth: '73px',
            marginRight: '32px'
        },
        tabSelected: {
            color: '#1695CB'
        },
        tabPanel: {
            background: '#F2F4FB'
        },
        rooms: {
            margin: '-8px 16px 0px 16px'
        },
        roomsToolbar: {
            position: 'relative'
        },
        roomsBtnDropdownContainer: {
            display: 'inline-block'
        },
        roomsBtnRoot: {
            position: 'relative',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '28px',
            lineHeight: '35px',
            letterSpacing: '-1px',
            color: '#232F64',
            borderRadius: '8px',
            padding: '5px 0px',
            '&:hover': {
                cursor: 'pointer',
                color: '#111111'
            }
        },
        roomsBtnName: {
            fontWeight: 800
        },
        roomsBtnId: {
            fontWeight: 'normal'
        },
        roomsBtnDropdown: {
            width: '24px',
            height: '24px',
            position: 'relative',
            top: '6px'
        },
        popoverPaper: {
            background: '#FFFFFF',
            boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.12)',
            borderRadius: '8px',
            marginLeft: '5px'
        },
        menuItemRoot: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '3px 14px',
            padding: '0px 13px 0px 13px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '40px',
            color: '#111111',
            '&:first-child': {
                marginTop: '13px'
            },
            '&:last-child': {
                marginBottom: '14px'
            },
            '&:hover': {
                borderRadius: '8px',
                background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)'
            }
        },
        menuItemSelected: {
            background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
            borderRadius: '8px'
        },
        svgIcon: {
            position: 'relative',
            top: '3px',
            width: '24px',
            height: '24px'
        },
        editNameBtnRoot: {
            position: 'relative',
            top: '-4px',
            marginLeft: '10px',
            borderRadius: '8px'
        },
        editNameBtnTxt: {
            letterSpacing: '0.5px',
            color: '#969696',
            fontWeight: 'bold'
        },
        machineAtRoomToolbar: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px'
        },
        statusInformation: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            textAlign: 'right',
            letterSpacing: '-0.5px',
            color: '#5F5F5F',
            fontWeight: 'bold'
        },
        labelProblem: {
            height: '23px',
            backgroundColor: uiColor.icon_offline,
            color: uiColor.icon_txt,
            fontFamily: 'Manrope',
            fontSize: 18,
            lineHeight: '25px',
            textAlign: 'center',
            fontWeight: 700,
            borderRadius: '3px',
            marginLeft: '10px',
            letterSpacing: '-0.5px',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem'
        },
        onlineIndicator: {
            height: '8px',
            width: '8px',
            backgroundColor: uiColor.icon_online,
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0px 7px 0px 10px',
            position: 'relative',
            top: '-2px'
        },
        onlineTxt: {
            color: uiColor.icon_online,
            fontWeight: 500
        },
        offlineIndicator: {
            height: '8px',
            width: '8px',
            backgroundColor: uiColor.icon_offline,
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0px 7px 0px 10px',
            position: 'relative',
            top: '-2px'
        },
        offlineTxt: {
            color: uiColor.icon_offline,
            fontWeight: 500
        },
        dividerBeforeMachines: {
            width: '1px',
            height: '32px',
            color: '#E2E3E9',
            marginLeft: '20px'
        },
        machinesInfoLabel: {
            paddingLeft: '21px',
            display: 'flex',
            flexDirection: 'column'
        },
        machinesInfo: {
            marginLeft: '5px',
            marginRight: 0,
            color: '#232F64'
        },
        clearAllCoinBoxesBtnRoot: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            border: '1px solid white',
            width: '213px',
            height: '36px',
            background: 'white'
        },
        clearAllCoinBoxesBtnLabel: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            letterSpacing: '0.5px',
            color: '#1695CB',
            fontSize: '16px',
            lineHeight: '22px'
        },
        clearAllCoinDialogPaper: {
            background: '#FFFFFF',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px'
        },
        clearAllCoinDialogContentTxt: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            lineHeight: '30px',
            letterSpacing: '-0.75px',
            color: '#000000',
            fontSize: '22px',
            fontWeight: 'bold',
            textAlign: 'center',
            margin: '32px 14px 33px 14px'
        },
        clearAllCoinDialogActions: {
            backgroundColor: '#EEEFF3',
            justifyContent: 'center',
            padding: '24px 40px'
        },
        cancelBtnRoot: {
            background: '#FFFFFF',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            width: '152px',
            height: '36px'
        },
        cancelBtnTxt: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.5px',
            color: '#5F5F5F'
        },
        yesBtnRoot: {
            marginLeft: '16px',
            background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            width: '152px',
            height: '40px'
        },
        yesBtnTxt: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.5px',
            color: '#FFFFFF'
        },
        closeBtnRoot: {
            background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            width: '214px',
            height: '36px'
        },
        moreBtnRoot: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            border: '1px solid white',
            width: '36px',
            height: '36px',
            background: 'white',
            marginLeft: '16px',
            color: '#1695CB'
        },
        machinesContainer: {
            marginTop: '6px'
        },
        machinesCardRoot: {
            borderRadius: '8px',
            background: '#FFFFFF',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            padding: '0 40px'
        },
        machinesCardRounded: {
            borderRadius: '8px',
            '&:first-child': {
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px'
            },
            '&:last-child': {
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px'
            }
        },
        machinesCardTitleRoot: {
            borderBottom: '1px solid #E0E0E0',
            padding: 0
        },
        heading: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '22px',
            lineHeight: '30px',
            fontWeight: 'bold',
            letterSpacing: '-0.75px',
            color: '#232F64'
        },
        machinesCardDetails: {
            margin: '24px 0 40px 0',
            padding: 0
        },
        machineCardOnline: {
            borderRadius: '8px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
            border: '1px solid #EEEFF3'
        },
        machineCardOffline: {
            borderRadius: '8px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
            background: '#E4E5EB'
        },
        machineConnectedOn: {
            position: 'relative',
            top: '12px',
            color: '#1695CB'
        },
        machineConnectedOff: {
            position: 'relative',
            top: '12px',
            color: '#F86D6D'
        },
        machineConnectedIconOn: {
            height: '8px',
            width: '8px',
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0px 10px',
            backgroundColor: '#1695CB'
        },
        machineConnectedIconOff: {
            height: '8px',
            width: '8px',
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0px 10px',
            backgroundColor: '#F86D6D'
        },
        machineConnectedTxt: {
            marginRight: '10px',
            position: 'relative',
            top: '1px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '22px'
        },
        machineNumber: {
            fontSize: '24px',
            fontWeight: 800,
            lineHeight: '33px',
            color: '#232F64',
            letterSpacing: '-0.75px'
        },
        machineID: {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '22px',
            color: '#5F5F5F',
            position: 'relative',
            top: '-3px',
            left: '10px'
        },
        gridList: {
            width: '100%',
            height: '100%'
        },
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper
        },
        dividerSpace: {
            fontWeight: 'bold',
            paddingRight: '20px'
        },
        selectRoot: {
            fontWeight: 'bold',
            fontSize: '20px',
            color: uiColor.sidebar_bk
        },
        selectIcon: {
            color: uiColor.sidebar_bk
        },
        cardHeader: {
            padding: '5px 16px'
        },
        cardHeaderOnline: {
            padding: '5px 16px',
            borderBottom: '1px solid lightgray'
        },
        cardHeaderOffline: {
            padding: '5px 16px',
            borderBottom: '1px solid white'
        },
        machineInfoItem: {
            position: 'relative',
            width: '100%',
            display: 'flex',
            fontSize: '16px',
            marginBottom: '5px'
        },
        machineInfoTitle: {
            color: '#5F5F5F',
            width: '40%'
        },
        machineStatus: {
            padding: '2px 5px',
            color: 'white',
            borderRadius: '2px',
            fontWeight: 'bold'
        },
        notDisplay: {
            display: 'none'
        },
        machineRunning: {
            padding: '2px 5px',
            color: 'white',
            borderRadius: '2px',
            fontWeight: 'bold',
            background: '#1695CB'
        },
        machineIdle: {
            padding: '2px 5px',
            color: 'white',
            borderRadius: '2px',
            fontWeight: 'bold',
            background: '#969696'
        },
        machineStartMode: {
            padding: '2px 5px',
            color: 'white',
            borderRadius: '2px',
            fontWeight: 'bold',
            background: '#3BA359'
        },
        machineTimeRemain: {
            fontSize: '14px',
            marginLeft: '5px'
        },
        machineError: {
            fontSize: '14px',
            marginLeft: '5px'
        },
        machineCoins: {
            fontWeight: 'bold',
            color: 'black'
        },
        machineCoinsMax: {
            fontWeight: 'bold',
            color: '#F86D6D'
        },
        machineInfoContent: {
            fontWeight: 'bold',
            color: 'black'
        },
        machineMoreBtn: {
            position: 'absolute',
            right: '0px',
            top: '-5px',
            color: '#969696',
            fontSize: '12px',
            fontWeight: 'bold'
        },
        NavigateNextIcon: {
            position: 'relative',
            left: '-8px',
            top: '-1px',
            width: '23px',
            height: '23px',
            color: '#969696'
        },
        hardwareInfoTitle: {
            color: '#5F5F5F',
            width: '45%'
        },
        pricingTabRoot: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            letterSpacing: '0.75px',
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '25px',
            padding: 0,
            minWidth: '90px',
            marginRight: '40px'
        },
        listRoot: {
            paddingTop: 0,
            PaddingBottom: 0
        },
        listItemRoot: {
            padding: 0,
            margin: 0,
            alignItems: 'flex-start'
        },
        listItemIconRoot: {
            paddingTop: '16px',
            width: '40px',
            height: '40px'
        },
        listItemTextRoot: {
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: 0,
            marginBottom: 0
        },
        inputAdornmentStart: {
            fontWeight: 'bold',
            color: '#969696',
            mixBlendMode: 'normal'
        },
        inputAdornmentEnd: {
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: '16px',
            color: '#969696',
            mixBlendMode: 'normal'
        },
        editMachinesPricesInput: {
            padding: '8px 0px',
            width: '70px',
            fontWeight: 500,
            color: '#111111',
            mixBlendMode: 'normal'
        },
        events: {
            margin: '-8px 16px 0 16px'
        },
        eventsToolbar: {
            display: 'flex',
            padding: '12px 0px 22px 0px',
            position: 'relative',
            left: '-16px'
        },
        datesPickerContainer: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        datesPicker: {
            marginRight: '15px',
            width: '220px'
        },
        label: {
            fontSize: '16px',
            lineHeight: '22px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            color: '#969696'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff'
        },
        filterItem: {
            marginRight: '16px',
            minWidth: '193px',
            height: '42px',
            '& label.MuiFormLabel-root.Mui-focused': {
                color: '#1695CB',
                fontWeight: 'bold'
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#1695CB'
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#1695CB'
                }
            }
        },
        inputItem: {
            minWidth: '330px',
            marginBottom: '24px',
            '& label.MuiFormLabel-root.Mui-focused': {
                color: '#1695CB',
                fontWeight: 'bold'
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#1695CB'
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#1695CB'
                }
            }
        },
        filterLabel: {
            fontSize: '16px',
            lineHeight: '22px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            color: '#969696'
        },
        inputLabel: {
            fontSize: '16px',
            lineHeight: '22px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 500,
            color: '#969696'
        },
        labelFocused: {
            color: '#1695CB',
            fontWeight: 'bold'
        },
        inputSelectRoot: {
            padding: '13px 16px'
        },
        inputSelectOutlined: {
            borderRadius: '8px'
        },
        inputSelectSelected: {
            '&:focus': {
                background: '#F2F4FB'
            }
        },
        locationPropertyToolbar: {
            paddingLeft: '5px'
        },
        locationPropertyDivider: {
            width: '1px',
            height: '460px',
            background: '#E0E0E0',
            position: 'absolute',
            top: '20px',
            left: '440px'
        },
        errorMsg: {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: 'red',
            margin: '10px 19px',
            textAlign: 'center'
        },
        backIcon: {
            color: '#969696',
            cursor: 'pointer'
        },
        dividerContainer: {
            display: 'flex',
            flex: 6
        },
        divider: {
            borderLeft: '1px solid #E0E0E0',
            paddingLeft: '15px',
            color: 'grey',
            background: 'none',
            height: '33px'
        },
        request: {
            fontSize: '22px',
            color: '#232F64',
            marginLeft: '10px',
            fontWeight: 500
        },
        input: {
            '& input': {
                paddingTop: '15px',
                paddingBottom: '15px',
                minWidth: '420px'
            }
        },
        dialogContent: {
            padding: '16px 56px 34px 24px'
        },
        addSubLocationDialogItem: {
            margin: '13px 40px',
            width: '380px',
            display: 'flex',
            alignItems: 'center'
        },
        addSubLocationDialogSearch: {
            marginLeft: '15px'
        },
        closeBtn: {
            color: '#969696'
        },
        foundLocationDialogAppBar: {
            position: 'relative',
            backgroundColor: '#EEEFF3',
            boxShadow: '0 0 0',
            borderBottom: '1px solid #E2E3E9'
        },
        foundLocationDialogTitle: {
            flex: 1,
            paddingLeft: '8px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '33px',
            letterSpacing: '-0.75px',
            color: '#232F64'
        },
        container: {
            paddingTop: '5px'
        },
        textField: {
            paddingBottom: '25px'
        },
        active: {
            color: uiColor.active,
            paddingBottom: '25px'
        },
        inactive: {
            color: uiColor.inactive,
            paddingBottom: '25px'
        },
        createBtnRoot: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            padding: '8px, 16px, 8px, 12px',
            borderRadius: '8px',
            width: '210px',
            height: '40px',
            background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
        },
        createBtnTxt: {
            color: '#FFFFFF',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 'bold',
            letterSpacing: '0.5px'
        },
        dividerDetailShow: {
            marginBottom: '20px',
            color: 'grey'
        },
        menuTitle: {
            fontWeight: 700,
            fontSize: '18px',
            fontFamily: 'Manrope',
            marginBottom: '10px',
            marginTop: '10px'
        },
        menuTitleChildren: {
            fontWeight: 700,
            fontSize: '18px',
            fontFamily: 'Manrope',
            marginBottom: '10px',
            marginTop: '30px'
        },
        createSublocationButton: {
            position: 'absolute',
            left: '400px',
            marginTop: '7px'
        },
        addRoomDialogItemLabel: {
            width: '40%',
            fontWeight: 'bold',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '-0.5px',
            color: '#111111'
        },
        gridFoundLocation: {
            marginTop: '30px'
        },
        foundLocationcontainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row'
        },
        subtitleValueCode: {
            alignItems: 'center',
            color: '#111111',
            fontWeight: 700,
            fontSize: '18px',
            fontFamily: 'Manrope',
            lineHeight: '25px',
            width: '250px'
        },
        textFieldAddressLabel: {
            fontWeight: 400,
            fontFamily: 'Manrope',
            fontSize: '14px',
            lineHeight: '1',
            color: '#969696',
            width: '100px'
        },
        textFieldAddress: {
            fontWeight: 400,
            fontFamily: 'Manrope',
            fontSize: '16px',
            lineHeight: '1.43',
            color: '#111111',
            width: '100px',
            paddingBottom: '25px'
        },
        textFieldAddressCityLabel: {
            fontWeight: 400,
            fontFamily: 'Manrope',
            fontSize: '14px',
            lineHeight: '1',
            color: '#969696',
            width: '140px'
        },
        textFieldAddressCity: {
            fontWeight: 400,
            fontFamily: 'Manrope',
            fontSize: '16px',
            lineHeight: '1.43',
            color: '#111111',
            width: '140px',
            paddingBottom: '25px'
        },
        addLocationTextField: {
            '& div.MuiInputBase-root': {
                height: '53px'
            }
        },
        detailLabel: {
            fontSize: '13px',
            fontWeight: 400,
            fontFamily: 'Manrope',
            color: uiColor.grey_label
        },
        detailLabelGrid: {
            '& .MuiGrid-spacing-xs-1 > .MuiGrid-item': {
                padding: '0px',
                paddingBottom: '10px'
            }
        },
        detailTextFieldGrid: {
            paddingBottom: '20px'
        },
        gridContainer: {
            maxWidth: '800px'
        },
        fieldInBlue: {
            color: uiColor.secondary,
            fontWeight: 'bold',
            letterSpacing: '-0.5px',
            cursor: 'pointer'
        }
    })
);

export default useStyles;
