import React, { useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import uiColor from '../../../utils/utilsColors';
import AlertIcon from '../../../assets/alert.svg';
import { DeleteRoomDialogPros } from './DialogsProps';

export const ChangeListStatus = ({
	deleteRoomClosed,
	openDeleteRoom,
	deleteRoomDeleteClicked,
	active
}: DeleteRoomDialogPros) => {
	const [status, setStatus] = React.useState('');
	useEffect(() => {
		if (active) setStatus('enable');
		else setStatus('disable');
	}, [active]);

	return (
		<Dialog open={openDeleteRoom} onClose={deleteRoomClosed}>
			<DialogContent
				style={{
					textAlign: 'center'
				}}
			>
				<img src={AlertIcon} alt='alertIcon' />
				<DialogContentText
					style={{
						fontSize: '22px',
						fontWeight: 'bold',
						color: 'black',
						textAlign: 'center',
						margin: '0px 30px'
					}}
				>
					Are you sure you want to
					<br /> {status === 'enable' ? 'activate' : 'deactivate'} this Value Code List?
				</DialogContentText>
				<DialogContentText
					style={{
						fontSize: '16px',
						color: '#5F5F5F',
						textAlign: 'center'
					}}
				>
					Warning, this action will {status === 'enable' ? 'activate' : 'deactivate'} all
					<br /> codes associated with this Value Code List.
				</DialogContentText>
			</DialogContent>
			<DialogActions
				style={{
					backgroundColor: '#EEEFF3',
					justifyContent: 'center',
					padding: '20px 0px'
				}}
			>
				<Button
					onClick={deleteRoomClosed}
					size='small'
					variant='contained'
					color='default'
					style={{
						width: '150px',
						fontWeight: 'bold',
						backgroundColor: 'white'
					}}
				>
					cancel
				</Button>
				{!active && (
					<Button
						onClick={deleteRoomDeleteClicked}
						size='small'
						variant='contained'
						style={{
							width: '150px',
							fontWeight: 'bold',
							backgroundColor: uiColor.icon_offline
						}}
					>
						Deactivate
					</Button>
				)}
				{active && (
					<Button
						onClick={deleteRoomDeleteClicked}
						size='small'
						variant='contained'
						style={{
							width: '150px',
							fontWeight: 'bold',
							backgroundColor: uiColor.icon_online
						}}
					>
						Activate
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
