export const LISTS_STATUS = [
	{
		id: 'all',
		description: 'All'
	},
	{
		id: 'active',
		description: 'Active'
	},
	{
		id: 'inactive',
		description: 'Inactive'
	}
];
export const LISTS_TOTALCODES = [
	{
		id: 'all',
		description: 'All'
	},
	{
		id: '0-100',
		description: '0 - 100'
	},
	{
		id: '100-1000',
		description: '100 - 1,000'
	},
	{
		id: '1000-10000',
		description: '1,000 - 10,000'
	},
	{
		id: '10000-50000',
		description: '10,000 - 50,000'
	},
	{
		id: '50000+',
		description: '50,000+'
	}
];
