import * as React from 'react';
import { Admin } from 'react-admin';
import restProvider from 'ra-data-simple-rest';
import LoginForm from './components/login';
import authProvider from './providers/auth/authProvider';
import { fetchUtils } from 'ra-core';
import { theme } from './utils/theme';
import MyLayout from './components/layout/Layout';
import { Resources } from './components/common/Resources';

const apiUrl = process.env.REACT_APP_API_URL as string;

export interface IApplicationProps {}

const httpClient = (url: any, options: any = {}) => {
	const email = localStorage.getItem('cscvc_loginEmail');
	const token = localStorage.getItem('cscvc_token');
	if (!options.headers) {
		options.headers = new Headers({ Accept: 'application/json' });
	}
	options.headers.set('Authorization', token);
	if (email != null) options.headers.set('User', btoa(email));
	return fetchUtils.fetchJson(url, options);
};

const App = (props: any) => {
	return (
		<Admin
			theme={theme}
			title='Value Cards Admin Portal'
			layout={MyLayout}
			dataProvider={restProvider(apiUrl, httpClient, 'X-Total-Count')}
			loginPage={LoginForm}
			authProvider={authProvider}
			{...props}
		>
			{(permissions) => Resources(permissions)}
		</Admin>
	);
};

export default App;
