import * as React from 'react';
import { forwardRef } from 'react';
import { SimpleShowLayout, TextField } from 'react-admin';
import {
	Typography,
	Button,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	DialogActions,
	IconButton,
	Container,
	Divider
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { FoundLocationProps } from './DialogsProps';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../styles';

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export const FoundLocationDialog = ({
	openFoundLocation,
	foundLocationClosed,
	foundLocationClicked,
	foundLocation
}: FoundLocationProps) => {
	const classes = useStyles();

	const onYesClicked = () => {
		foundLocationClicked();
		foundLocationClosed();
	};

	return (
		<Dialog open={openFoundLocation} onClose={foundLocationClosed} TransitionComponent={Transition}>
			<AppBar className={classes.foundLocationDialogAppBar}>
				<Toolbar>
					<Typography className={classes.foundLocationDialogTitle}>
						Found CSC-Go Location
					</Typography>
					<IconButton aria-label='close' className={classes.closeBtn} onClick={foundLocationClosed}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<div>
				<Container className={classes.container}>
					<SimpleShowLayout record={foundLocation}>
						<TextField source='as400Number' label='AS400' className={classes.textField} />
						<Divider className={classes.dividerDetailShow} />
						<TextField source='label' label='Location Name' className={classes.textField} />
						<TextField
							source='locationId'
							label='Service Location ID'
							className={classes.textField}
						/>
						<TextField source='address.address1' label='Address' className={classes.textField} />
						<div className={classes.foundLocationcontainer}>
							<span className={classes.textFieldAddressLabel}>State</span>
							<span className={classes.textFieldAddressCityLabel}>City</span>
							<span className={classes.textFieldAddressLabel}>Zip</span>
						</div>
						<div className={classes.foundLocationcontainer}>
							<span className={classes.textFieldAddress}>
								{foundLocation ? foundLocation.address.state : ''}
							</span>
							<span className={classes.textFieldAddressCity}>
								{foundLocation ? foundLocation.address.city : ''}
							</span>
							<span className={classes.textFieldAddress}>
								{foundLocation ? foundLocation.address.zipcode : ''}
							</span>
						</div>
						<span>Is this the correct location that you want to add?</span>
					</SimpleShowLayout>
				</Container>
			</div>
			<DialogActions>
				<Button
					onClick={foundLocationClosed}
					size='small'
					variant='contained'
					color='default'
					classes={{
						root: classes.cancelBtnRoot,
						label: classes.cancelBtnTxt
					}}
				>
					No
				</Button>
				<Button
					onClick={onYesClicked}
					size='small'
					color='secondary'
					variant='contained'
					classes={{
						root: classes.yesBtnRoot,
						label: classes.yesBtnTxt,
						disabled: classes.addRoomBtnDisabled
					}}
				>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};
