import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { range } from 'lodash';
import { getYear, getMonth } from 'date-fns';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { MONTHS as months, DEFAULT_START_YEAR } from './constants';
import { CustomHeaderProps } from './CustomDatePickerProps';

const years = range(DEFAULT_START_YEAR, getYear(new Date()) + 1, 1);

const CustomDatePickerHeader = ({
	date,
	changeYear,
	changeMonth,
	decreaseMonth,
	increaseMonth,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled
}: CustomHeaderProps) => {
	return (
		<div
			style={{
				margin: 10,
				display: 'flex',
				justifyContent: 'center'
			}}
		>
			<Button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
				<ChevronLeft />
			</Button>
			<select
				style={{
					border: '1px solid #1695CB',
					boxSizing: 'border-box',
					borderRadius: '8px',
					width: '75px'
				}}
				value={getYear(date)}
				onChange={({ target: { value } }) => changeYear(value)}
			>
				{years.map((option) => (
					<option key={option} value={option}>
						{option}
					</option>
				))}
			</select>
			<select
				style={{
					border: '1px solid #1695CB',
					boxSizing: 'border-box',
					borderRadius: '8px',
					width: '75px'
				}}
				value={months[getMonth(date)]}
				onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
			>
				{months.map((option) => (
					<option key={option} value={option}>
						{option}
					</option>
				))}
			</select>
			<Button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
				<ChevronRight />
			</Button>
		</div>
	);
};

export default CustomDatePickerHeader;
