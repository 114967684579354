const CLASSES_RESOURCES: any = {
	valuecodelists: 'tableCellValueCodeLists',
	default: 'tableCellActivity'
};

const CLASSES_CELL_ROW: any = {
	users: 'tableRowUsers',
	withClick: 'tableRow',
	noClick: 'tableRowActivity'
};

const HISTORY_PROPS_VCL: any = {
	valuecodelists: ({ id, filterSearch }: any) => ({
		pathname: `/valuecodelists/${id}/show`,
		state: {
			filterSearch
		}
	})
};

const HISTORY_PROPS_SERVICES: any = {
	services: ({ id, filterSearch }: any) => ({
		pathname: `/services/${id}/show`,
		state: {
			filterSearch
		}
	})
};

const HISTORY_PROPS_LOCATIONS: any = {
	locations: ({ id, filterSearch }: any) => ({
		pathname: `/locations/${id}/show`,
		state: {
			filterSearch
		}
	})
};

const HISTORY_PROPS_USERS: any = {
	users: ({ id, filterSearch }: any) => ({
		pathname: `/users/${id}/show`,
		state: {
			filterSearch
		}
	})
};

export const getCellClassName = (field: any, resource: string): any => {
	return CLASSES_RESOURCES[resource] || CLASSES_RESOURCES.default;
};

export const onRowClicked = (resource: string, id: any, record: any, filterSearch: any) => {
	let funct;
	if (resource === 'valuecodelists') funct = HISTORY_PROPS_VCL[resource];
	if (resource === 'services') funct = HISTORY_PROPS_SERVICES[resource];
	if (resource === 'locations') funct = HISTORY_PROPS_LOCATIONS[resource];
	if (resource === 'users') funct = HISTORY_PROPS_USERS[resource];
	return funct && funct({ id, record, filterSearch });
};

export const getCellRowClassName = (resource: string, hasRowClick: boolean): string => {
	const defaultValue = hasRowClick ? 'withClick' : 'noClick';
	return CLASSES_CELL_ROW[resource] || CLASSES_CELL_ROW[defaultValue];
};
