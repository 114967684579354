import * as React from 'react';
import { forwardRef } from 'react';
import {
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
	SvgIcon
} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './useStyles';
import { ReactComponent as RangedDatePickerIcon } from '../../../assets/rangedDatePicker.svg';
import { DEFAULT_DATES_LABEL, DEFAULT_LABEL_WIDTH } from './constants';
import { CustomInputProps } from './CustomDatePickerProps';
import './styles.css';
import './default.css';

const CustomInputRef = (props: CustomInputProps, ref: any) => {
	const classes = useStyles();
	return (
		<FormControl className={clsx(classes.margin, classes.textField)} variant='outlined'>
			<InputLabel htmlFor={'date_range_input'} classes={{ formControl: classes.label }}>
				{props.label || DEFAULT_DATES_LABEL}
			</InputLabel>
			<OutlinedInput
				id={'date_range_input'}
				color='secondary'
				value={props.value}
				ref={ref}
				placeholder={props.label}
				onClick={props.onClick}
				endAdornment={
					<InputAdornment
						position='end'
						classes={{
							positionEnd: classes.inputAdornmentEnd
						}}
					>
						<IconButton
							size='small'
							aria-label='show ranged date picker'
							onClick={props.onClick}
							edge='end'
							id={'ci_date_range_icon'}
						>
							<SvgIcon
								component={RangedDatePickerIcon}
								viewBox='0 0 24 24'
								className={classes.svgIcon}
							/>
						</IconButton>
					</InputAdornment>
				}
				labelWidth={props.labelWidth || DEFAULT_LABEL_WIDTH}
				classes={{
					root: classes.inputRoot,
					input: classes.input
				}}
			/>
		</FormControl>
	);
};

export default forwardRef(CustomInputRef);
