import * as React from 'react';
import { forwardRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useRefresh, useNotify, useMutation } from 'react-admin';
import {
	Typography,
	Button,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	TextField,
	DialogActions,
	IconButton,
	MenuItem,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../styles';
import { Filter } from '../../services/Filter';
import { AddWebhookProps } from './DialogsProps';
import { DATA_TYPE, ACTION_FILTER, HTTP_VERB, RESULT_PROCESSING } from '../values';

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export const AddWebhookDialog = ({
	openAddWebhook,
	addWebhookClosed,
	serviceId
}: AddWebhookProps) => {
	const classes = useStyles();
	const refresh = useRefresh();
	const notify = useNotify();
	const [mutate] = useMutation();
	const [addWebhookName, setWebhookName] = useState('');
	const [addWebhookDescription, setWebhookDescription] = useState('');
	const [addWebhookUrl, setWebhookUrl] = useState('');
	const [addWebhookHeaders, setWebhookHeaders] = useState('');
	const [addWebhookBody, setWebhookBody] = useState('');
	const [addWebhookSequenceOrder, setWebhookSequenceOrder] = useState(1);
	const [dataTypeSelected, setDataTypeSelected] = useState('');
	const [actionFilterSelected, setActionFilterSelected] = useState('');
	const [httpVerbSelected, setHttpVerbSelected] = useState('');
	const [resultPSelected, setResultPSelected] = useState('');
	const [dataTypes] = useState<any>(DATA_TYPE);
	const [actionFilters] = useState<any>(ACTION_FILTER);
	const [httpVerbs] = useState<any>(HTTP_VERB);
	const [resultsP] = useState<any>(RESULT_PROCESSING);
	const [isCheckOrderSequence, setIsCheckOrderSequence] = useState(false);
	const [showOrderSequence, setShowOrderSequence] = useState(false);

	const onAddWebhookClicked = () => {
		mutate(
			{
				type: 'create',
				resource: 'webhooks',
				payload: {
					data: {
						Name: addWebhookName,
						Description: addWebhookDescription,
						Url: addWebhookUrl,
						DataType: dataTypeSelected,
						ActionFilter: actionFilterSelected,
						HttpVerb: httpVerbSelected.toLowerCase(),
						Headers: addWebhookHeaders !== '' ? addWebhookHeaders : null,
						Body: addWebhookBody !== '' ? addWebhookBody : null,
						ServiceId: serviceId,
						Monetary: false,
						ResultProcessing: resultPSelected !== '' ? resultPSelected : null,
						SequenceOrder: addWebhookSequenceOrder
					}
				}
			},
			{
				onSuccess: ({ data }) => {
					addWebhookClosed();
					refresh();
					notify(`Webhook created: ${addWebhookName}.`);
				},
				onFailure: (error) => {
					notify(`${error}`, 'warning');
					console.log('Error: ' + error);
				}
			}
		);
	};

	const handleChangeName = (event: any) => {
		setWebhookName(event.target.value);
	};
	const handleChangeDescription = (event: any) => {
		setWebhookDescription(event.target.value);
	};
	const handleChangeUrl = (event: any) => {
		setWebhookUrl(event.target.value);
	};
	const handleChangeHeaders = (event: any) => {
		setWebhookHeaders(event.target.value);
	};
	const handleChangeBody = (event: any) => {
		setWebhookBody(event.target.value);
	};
	const handleChangeSequenceOrder = (event: any) => {
		setWebhookSequenceOrder(event.target.value);
	};

	const onCancelClicked = (event: any) => {
		setWebhookName('');
		setWebhookDescription('');
		setWebhookUrl('');
		setWebhookHeaders('');
		setWebhookBody('');
		setDataTypeSelected('');
		setActionFilterSelected('');
		setHttpVerbSelected('');
		setResultPSelected('None');
		addWebhookClosed();
		setWebhookSequenceOrder(1);
		setIsCheckOrderSequence(false);
		setShowOrderSequence(false);
	};

	const dataTypeChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const dataType = event.target.value as string;
		setDataTypeSelected(dataType);
	};

	const actionFilterChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const actionFilter = event.target.value as string;
		setActionFilterSelected(actionFilter);
	};

	const httpVerbChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const httpVerb = event.target.value as string;
		setHttpVerbSelected(httpVerb);
	};

	const resultPChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const resultP = event.target.value as string;
		setResultPSelected(resultP);
	};

	const handleCheckOrderSequence = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsCheckOrderSequence(!isCheckOrderSequence);
		if (!isCheckOrderSequence) {
			setShowOrderSequence(true);
		} else {
			setShowOrderSequence(false);
		}
	};

	return (
		<Dialog open={openAddWebhook} onClose={addWebhookClosed} TransitionComponent={Transition}>
			<AppBar className={classes.addServiceDialogAppBar}>
				<Toolbar>
					<Typography className={classes.addServiceDialogTitle}>Add New Webhook</Typography>
					<IconButton aria-label='close' className={classes.closeBtn} onClick={onCancelClicked}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Name'
						size='small'
						type='search'
						value={addWebhookName}
						onChange={handleChangeName}
					/>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='Description'
						size='small'
						type='search'
						multiline
						value={addWebhookDescription}
						onChange={handleChangeDescription}
					/>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='URL'
						size='small'
						type='search'
						value={addWebhookUrl}
						onChange={handleChangeUrl}
						placeholder='http://test.com/test?valuecodeid={$valueCode.id}'
					/>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<Filter
						id='dataType_label'
						label='Data Type'
						value={dataTypeSelected}
						onChange={dataTypeChanged}
						labelWidth={65}
					>
						{dataTypes.map((dataType: any, index: number) => {
							return (
								<MenuItem
									key={`dataTypes_${index}`}
									value={dataType.id}
									selected={dataTypeSelected === dataType.id}
								>
									{dataType.description}
								</MenuItem>
							);
						})}
					</Filter>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<Filter
						id='actionFilter_label'
						label='Action Filter'
						value={actionFilterSelected}
						onChange={actionFilterChanged}
						labelWidth={65}
					>
						{actionFilters.map((actionFilter: any, index: number) => {
							return (
								<MenuItem
									key={`actionFilter_${index}`}
									value={actionFilter.id}
									selected={actionFilterSelected === actionFilter.id}
								>
									{actionFilter.description}
								</MenuItem>
							);
						})}
					</Filter>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='HTTP Headers'
						size='medium'
						type='search'
						value={addWebhookHeaders}
						onChange={handleChangeHeaders}
						multiline
						placeholder='{"test1":"test1", "test2": "test2"}'
					/>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<Filter
						id='httpVerb_label'
						label='HTTP Verb'
						value={httpVerbSelected}
						onChange={httpVerbChanged}
						labelWidth={65}
					>
						{httpVerbs.map((httpVerb: any, index: number) => {
							return (
								<MenuItem
									key={`httpVerb_${index}`}
									value={httpVerb.id}
									selected={httpVerbSelected === httpVerb.id}
								>
									{httpVerb.description}
								</MenuItem>
							);
						})}
					</Filter>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<TextField
						id='outlined-helperText'
						variant='outlined'
						label='HTTP Body'
						size='medium'
						type='search'
						value={addWebhookBody}
						onChange={handleChangeBody}
						multiline
						placeholder='{"test1":"test1", "test2": "test2"}'
					/>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<Filter
						id='resultP_label'
						label='Result Processing'
						value={resultPSelected}
						onChange={resultPChanged}
						labelWidth={65}
					>
						{resultsP.map((resultP: any, index: number) => {
							return (
								<MenuItem
									key={`resultP_${index}`}
									value={resultP.description}
									selected={resultPSelected === resultP.id}
								>
									{resultP.description}
								</MenuItem>
							);
						})}
					</Filter>
				</div>
			</div>
			<div>
				<div className={classes.addCurrencyTypeDialogItem}>
					<FormControlLabel
						control={
							<Checkbox
								checked={isCheckOrderSequence}
								onChange={handleCheckOrderSequence}
								name='sequenceOrder'
							/>
						}
						label='Sequence Order'
					/>
				</div>
			</div>
			{showOrderSequence ? (
				<div>
					<div className={classes.addCurrencyTypeDialogItem}>
						<TextField
							id='outlined-helperText'
							variant='outlined'
							label='Sequence Order'
							size='medium'
							type='number'
							InputProps={{ inputProps: { min: 1 } }}
							value={addWebhookSequenceOrder}
							onChange={handleChangeSequenceOrder}
						/>
					</div>
				</div>
			) : (
				''
			)}
			<DialogActions>
				<Button
					onClick={onCancelClicked}
					size='small'
					variant='contained'
					color='default'
					classes={{
						root: classes.cancelBtnRoot,
						label: classes.cancelBtnTxt
					}}
				>
					Cancel
				</Button>
				<Button
					disabled={isEmpty(addWebhookName.trim())}
					onClick={onAddWebhookClicked}
					size='small'
					color='secondary'
					variant='contained'
					classes={{
						root: classes.yesBtnRoot,
						label: classes.yesBtnTxt,
						disabled: classes.addRoomBtnDisabled
					}}
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
};
