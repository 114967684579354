import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		backIcon: {
			color: '#1695CB'
		},
		searchResultsBtnRoot: {
			position: 'relative',
			top: '-30px',
			left: '40px'
		},
		searchResultsBtnTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '18px',
			lineHeight: '25px',
			letterSpacing: '-0.5px',
			color: '#111111'
		},
		searchAnyFieldContainer: {
			minHeight: '702px',
			borderTop: '1px solid #E2E3E9',
			background: '#F2F4FB',
			display: 'flex',
			justifyContent: 'center'
		},
		searchAnyFieldBox: {
			width: '636px',
			textAlign: 'left',
			marginTop: '25px',
			marginBottom: '269px',
			padding: 0
		},
		searchAnyFieldTitle: {
			fontSize: '22px',
			lineHeight: '30px',
			color: '#232F64',
			fontWeight: 'bold',
			letterSpacing: '-0.75px',
			marginBottom: '9px'
		},
		searchFieldBox: {
			padding: '31px 40px 47px 40px',
			borderRadius: '8px',
			background: '#FFFFFF',
			border: '1px solid #E0E0E0',
			boxSizing: 'border-box'
		},
		searchBtnRoot: {
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)',
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			width: '154px',
			height: '36px',
			margin: '10px 0px 0px 402px'
		},
		searchBtnLabel: {
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '0.5px',
			textTransform: 'uppercase',
			color: '#FFFFFF'
		},
		searchInputLabel: {
			width: '170px',
			height: '25px',
			position: 'relative',
			top: '6.5px',
			textAlign: 'left',
			color: '#111111',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '18px',
			lineHeight: '25px',
			letterSpacing: '-0.5px'
		},
		searchInputItem: {
			boxSizing: 'border-box',
			borderRadius: '8px',
			height: '42px',
			width: '386px',
			marginBottom: '21px',
			'&:hover': {
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: '#1695CB'
				}
			},
			'&.Mui-focused': {
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: '#1695CB'
				}
			},
			'& .MuiOutlinedInput-input': {
				padding: '12px 10px'
			}
		},
		searchResultsContainer: {
			width: 'auto',
			marginTop: '-30px',
			borderTop: '1px solid #E2E3E9',
			background: '#F2F4FB',
			padding: '24px 40px 0px 40px'
		},
		searchResultsToolbar: {
			display: 'flex'
		},
		dateAddedFilter: {
			marginLeft: '16px'
		},
		listContainer: {
			minHeight: '500px' // for ranged date picker show completely
		},
		listRoot: {
			margin: '0px'
		},
		listContent: {
			boxShadow: '0 0 0 white',
			background: '#F2F4FB'
		},
		table: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			background: '#FFFFFF',
			minWidth: '1191px'
		},
		thead: {
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px'
		},
		tbody: {
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px'
		},
		headerCell: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#111111',
			padding: '20px 24px'
		}
	})
);

export default useStyles;
