import React, { useState } from 'react';

import {
	makeStyles,
	createStyles,
	Theme,
	Typography,
	Button,
	Backdrop,
	CircularProgress,
	Divider,
	AppBar,
	Toolbar,
	IconButton
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { useMutation, useRefresh, useNotify } from 'react-admin';
import MenuDropdown from '../common/MenuDropdown';
import { ActiveInactiveUserDialog } from './Dialogs/ActiveInactiveUserDialog';
import { EditUserDialog } from './Dialogs/EditUserDialog';
import { hasEdit, hasDelete } from '../../utils/permissions';

export const HeaderShow = (props: any) => {
	const [mutate] = useMutation();
	const notify = useNotify();
	const refresh = useRefresh();
	const classes = useStyles();
	const history = useHistory();
	const [open, setOpen] = React.useState(false);
	const [active, setActive] = useState(false);
	const [openActiveInactiveDialog, setOpenActiveInactiveDialog] = useState(false);
	const [openEditUserDialog, setOpenEditUserDialog] = useState(false);

	const firstBreadcrumbTitleClicked = () => {
		history.push('/users');
	};

	const handleClose = () => {
		setOpen(false);
	};

	const detailUserBack = () => {
		history.push('/users');
	};

	const handleChange = () => {};

	const onClickDisable = () => {
		setOpenActiveInactiveDialog(true);
		setActive(false);
	};

	const onClickEnable = () => {
		setOpenActiveInactiveDialog(true);
		setActive(true);
	};

	const optionsActivate: any = [
		{
			label: 'Activate',
			action: onClickEnable,
			disabled: false
		}
	];

	const optionsDeactivate: any = [
		{
			label: 'Deactivate',
			action: onClickDisable,
			disabled: false
		}
	];

	const activeInactiveDialogClosed = () => {
		setOpenActiveInactiveDialog(false);
	};

	const activeInactiveClicked = () => {
		mutate(
			{
				type: 'delete',
				resource: 'users',
				payload: {
					id: props.record.id
				}
			},
			{
				onSuccess: () => {
					refresh();
					notify(
						`User: ${
							props.record.name !== null ? ` ${props.record?.name}` : `${props.record?.email}`
						} ${active === true ? 'activated.' : 'deactivated.'}`
					);
				},
				onFailure: (error) => {
					notify(
						`User: ${
							props.record.name !== null ? ` ${props.record?.name}` : `${props.record?.email}`
						} ${active === true ? 'not activated.' : 'not deactivated.'}`,
						'warning'
					);
					console.log('Error: ' + error);
				}
			}
		);
		setOpenActiveInactiveDialog(false);
	};

	const editUserClicked = () => {
		setOpenEditUserDialog(true);
	};

	const editUserDialogClosed = () => {
		setOpenEditUserDialog(false);
	};

	return (
		<div className={classes.showTitle}>
			<div className={classes.titleContainer}>
				<div className={classes.breadcrumsContainer}>
					<Typography noWrap className={classes.breadcrumbTitle}>
						<span className={classes.firstBreadcrumbTitle} onClick={firstBreadcrumbTitleClicked}>
							Permissions
						</span>
						<NavigateNextIcon fontSize='small' className={classes.navigationIcon} />
						<span className={classes.secondBreadcrumbTitle} onChange={handleChange}>
							{props.record.name !== null ? ` ${props.record?.name}` : `${props.record?.email}`}
						</span>
					</Typography>
				</div>
			</div>
			<AppBar className={classes.addRoomDialogAppBar} position='static'>
				<Toolbar>
					<IconButton aria-label='back' onClick={detailUserBack}>
						<ArrowBackIcon className={classes.backIcon} />
					</IconButton>
					<Typography className={classes.addRoomDialogTitle}>User Profile</Typography>
					<div className={classes.dividerContainer}>
						<Divider orientation='vertical' className={classes.divider} />
						<Typography className={classes.request}>
							{props.record.name !== null ? ` ${props.record?.name}` : `${props.record?.email}`}
						</Typography>
					</div>
					{hasDelete(null, 'users') ? (
						<div>
							<Typography noWrap className={classes.address}>
								<Button
									size='small'
									variant='contained'
									color='default'
									classes={{
										root: classes.statusBtnRoot,
										label: classes.statusBtnTxt
									}}
								>
									{props.record.active === true && (
										<>
											<span className={classes.activeIndicator}></span>
											<span>Active</span>
										</>
									)}
									{props.record.active !== true && (
										<>
											<span className={classes.inactiveIndicator}></span>
											<span>Inactive</span>
										</>
									)}
								</Button>
								<div className={classes.arrowBtnRoot}>
									{props.record.active === true && (
										<MenuDropdown options={optionsDeactivate} iconName='ArrowDropDownIcon' />
									)}
									{props.record.active === false && (
										<MenuDropdown options={optionsActivate} iconName='ArrowDropDownIcon' />
									)}
								</div>
							</Typography>
						</div>
					) : (
						<div></div>
					)}
					{hasEdit(null, 'users') ? (
						<Button
							variant='contained'
							size='small'
							onClick={editUserClicked}
							classes={{
								root: classes.createBtnRoot,
								label: classes.createBtnTxt
							}}
						>
							Edit
						</Button>
					) : (
						<div></div>
					)}
				</Toolbar>
			</AppBar>
			<Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{hasDelete(null, 'users') ? (
				<ActiveInactiveUserDialog
					activeInactiveClosed={activeInactiveDialogClosed}
					openActiveInactive={openActiveInactiveDialog}
					activeInactiveClicked={activeInactiveClicked}
					active={active}
				/>
			) : (
				<></>
			)}
			{hasEdit(null, 'users') ? (
				<EditUserDialog
					openEditUser={openEditUserDialog}
					editUserClosed={editUserDialogClosed}
					user={props.record}
				/>
			) : (
				<></>
			)}
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		showTitle: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column'
		},
		titleContainer: {
			background: 'white',
			borderBottom: '1px solid #E2E3E9'
		},
		breadcrumsContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginTop: '5px',
			marginLeft: '40px',
			marginRight: '40px',
			marginBottom: '13px'
		},
		breadcrumbTitle: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#969696'
		},
		firstBreadcrumbTitle: {
			'&:hover': {
				cursor: 'pointer'
			}
		},
		navigationIcon: {
			position: 'relative',
			top: '5px',
			margin: '0px 5px 0px 6px',
			color: '#BDBDBD'
		},
		arrowDropDownIcon: {
			position: 'relative',
			color: '#5F5F5F'
		},
		secondBreadcrumbTitle: {
			color: '#5F5F5F'
		},
		secondTitleContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			borderTop: '1px solid #E0E0E0'
		},
		thirdTitleContainer: {
			display: 'flex',
			justifyContent: 'left',
			marginTop: '21px',
			marginLeft: '40px'
		},
		secondTitle: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '44px',
			fontWeight: 800,
			lineHeight: '113%',
			marginLeft: '40px',
			marginTop: '21px',
			color: '#232F64',
			letterSpacing: '-1px'
		},
		address: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '18px',
			lineHeight: '25px',
			color: '#5F5F5F',
			height: '48px',
			width: '200px',
			marginTop: '12px'
		},
		divider: {
			background: '#D0D5E8',
			height: '32px',
			marginLeft: '26px'
		},
		locations: {
			marginLeft: '31px'
		},
		locationCount: {
			color: '#232F64',
			fontWeight: 'bold',
			letterSpacing: '-0.5px',
			marginLeft: '16px'
		},
		exportBtnRoot: {
			width: '134px',
			height: '36px',
			background: '#FFFFFF',
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			border: '1px solid #FFFFFF'
		},
		exportBtnTxt: {
			fontWeight: 'bold',
			letterSpacing: '0.5px',
			fontSize: '16px',
			lineHeight: '22px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			color: '#1695CB'
		},
		exportBtnDisabled: {
			opacity: '0.5'
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff'
		},
		label: {
			color: '#232F64',
			fontSize: '16px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold'
		},
		labelText: {
			color: '#5F5F5F',
			fontSize: '16px',
			fontFamily: 'Manrope',
			fontStyle: 'normal'
		},
		statusBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
			borderTopLeftRadius: '8px',
			borderBottomLeftRadius: '8px',
			borderTopRightRadius: '0',
			borderBottomRightRadius: '0',
			width: '110px',
			height: '36.5px',
			cursor: 'default'
		},
		statusBtnTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '0.5px',
			color: '#5F5F5F',
			textTransform: 'capitalize'
		},
		arrowBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
			borderTopRightRadius: '8px',
			borderBottomRightRadius: '8px',
			borderTopLeftRadius: '0',
			borderBottomLeftRadius: '0',
			width: '20px',
			height: '36px',
			display: 'inline',
			padding: '8px'
		},
		activeIndicator: {
			height: '8px',
			width: '8px',
			background: '#3BA359',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginBottom: '1px'
		},
		inactiveIndicator: {
			height: '8px',
			width: '8px',
			background: '#EB5757',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginBottom: '1px'
		},
		addRoomDialogAppBar: {
			position: 'relative',
			backgroundColor: 'white',
			minWidth: '500px',
			boxShadow: '0 0 0',
			borderBottom: '1px solid #E2E3E9',
			paddingRight: '0px',
			justifyContent: 'space-between'
		},
		addRoomDialogTitle: {
			flex: 1,
			paddingLeft: '8px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 800,
			fontSize: '24px',
			lineHeight: '33px',
			letterSpacing: '-0.75px',
			color: '#232F64',
			minWidth: '200px'
		},
		backIcon: {
			color: '#969696',
			cursor: 'pointer'
		},
		dividerContainer: {
			display: 'flex',
			flex: 10
		},
		request: {
			fontSize: '22px',
			color: '#232F64',
			marginLeft: '40px',
			fontWeight: 500
		},
		createBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '8px, 16px, 8px, 12px',
			borderRadius: '8px',
			width: '150px',
			height: '40px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		createBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		}
	})
);
