import { makeStyles, createStyles } from '@material-ui/core';
import uiColor from '../../utils/utilsColors';

export const useStyles = makeStyles(() =>
	createStyles({
		root: {
			// defined in theme.ts
		},
		active: {
			// defined in theme.ts doesn't work, so defined here
			color: uiColor.sidebar_active
		},
		myMenu: {
			backgroundColor: uiColor.sidebar_bk,
			position: 'fixed',
			top: '40px',
			bottom: '0px',
			left: '0px',
			minHeight: '600px',
			paddingTop: '44px',
			width: '64px'
		},
		icon: {
			fill: 'currentColor',
			width: '32px',
			height: '32px',
			stroke: 'currentColor'
		},
		iconFill: {
			width: '32px',
			height: '32px',
			stroke: 'currentColor'
		}
	})
);
