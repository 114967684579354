import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useListContext, useNotify } from 'react-admin';
import useStyles from './styles';
import { MyDataGridProps } from './MyDatagridProps';
import { getCellClassName, getCellRowClassName, onRowClicked } from './utils';
import { useHistory } from 'react-router';
import { hasViewById } from '../../../utils/permissions';

/**
 * https://marmelab.com/react-admin/CreateEdit.html#custom-row-container
 * @param props
 * @constructor
 */
const CustomDataGridRow = (props: MyDataGridProps) => {
	const { record, resource, children, basePath, id, filterSearch, hasRowClick, whenRowClicked } =
		props;

	const classes: any = useStyles();
	const history = useHistory();
	const { loading } = useListContext();
	const notify = useNotify();

	const getTableCellClassName = (field: any) => {
		const className = getCellClassName(field, resource);
		return classes[className];
	};

	const tableRowClicked = () => {
		if (hasRowClick) {
			if (whenRowClicked) {
				whenRowClicked(record);
			} else {
				const params = onRowClicked(resource, id, record, filterSearch);
				if (params) {
					if (
						resource === 'services' ||
						resource === 'locations' ||
						resource === 'valueCodeLists'
					) {
						if (hasViewById(null, resource, record.id)) {
							history.push(params);
						} else {
							notify(`Unauthorized`, 'warning');
						}
					} else {
						history.push(params);
					}
				}
			}
		}
	};

	const getTableCellRowClassName = () => {
		const className = getCellRowClassName(resource, hasRowClick);
		return classes[className];
	};

	return (
		<TableRow
			key={id}
			hover
			classes={{ hover: classes.hover }}
			className={getTableCellRowClassName()}
			onClick={() => tableRowClicked()}
		>
			{!loading &&
				React.Children.map(children, (field) => (
					<TableCell
						id={`${id}_${field.props.source}`}
						key={`${id}_${field.props.source}`}
						className={getTableCellClassName(field)}
					>
						{React.cloneElement(field, {
							record,
							basePath,
							resource
						})}
					</TableCell>
				))}
		</TableRow>
	);
};

export default CustomDataGridRow;
