import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, MenuItem, SvgIcon, Button } from '@material-ui/core';
import { Filter } from './Filter';
import CustomDatePicker from '../common/customDatePicker/CustomDatePicker';
import { LISTS_STATUS, LISTS_TOTALCODES } from '../valueCodeLists/values';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { SearchInput, Filter as SearchFilter } from 'react-admin';

export const ValueCodeListsFilters = (
	{ setFilterVCL, filterVCL, services, locationVCL }: any,
	props: any
) => {
	const classes = useStyles();
	const [statusSelectedVCL, setStatusSelectedVCL] = useState('all');
	const [serviceSelected, setServiceSelected] = useState('all');
	const [totalCodesSelected, setTotalCodesSelected] = useState('all');
	const [statusVCL] = useState<any>(LISTS_STATUS);
	const [totalCodes] = useState<any>(LISTS_TOTALCODES);
	const [filterChangedVCL, setFilterChangedVCL] = useState(false);
	const [searchTextVCL, setSearchTextVCL] = useState('');
	const [searchVCL, searchInputVCL] = useState<Object | null>({});
	useEffect(() => {
		if (filterVCL?.status) {
			setStatusSelectedVCL(filterVCL.status);
		}
		if (filterVCL?.totalCodes) {
			setTotalCodesSelected(filterVCL.totalCodes);
		}
		if (
			(filterVCL?.status === undefined || filterVCL?.status === 'all') &&
			(filterVCL?.totalCodes === undefined || filterVCL?.totalCodes === 'all') &&
			(filterVCL?.service === undefined || filterVCL?.service === 'all') &&
			filterVCL?.datesRange === undefined
		) {
			setFilterChangedVCL(false);
		} else {
			setFilterChangedVCL(true);
		}
	}, [filterVCL]);

	const statusChangedVCL = (event: React.ChangeEvent<{ value: unknown }>) => {
		const status = event.target.value as string;
		if (status !== 'all') {
			setFilterVCL({ ...filterVCL, status });
		} else {
			setFilterVCL({ ...filterVCL, status: undefined });
		}
		setStatusSelectedVCL(status);
	};

	const totalCodesChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const totalCodes = event.target.value as string;
		if (totalCodes !== 'all') {
			setFilterVCL({ ...filterVCL, totalCodes });
		} else {
			setFilterVCL({ ...filterVCL, totalCodes: undefined });
		}
		setTotalCodesSelected(totalCodes);
	};

	const serviceChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
		const service = event.target.value as string;
		if (service !== 'all') {
			setFilterVCL({ ...filterVCL, service });
		} else {
			setFilterVCL({ ...filterVCL, service: undefined });
		}
		setServiceSelected(service);
	};

	const refundsFilterOpened = () => {
		const datesPicker = document.getElementById('Dates_date_range_picker');
		if (datesPicker) {
			if (datesPicker.style.display !== 'none') {
				datesPicker.style.display = 'none';
			}
		}
	};

	const changedDates = (date: any) => {
		if (date !== null) {
			setFilterVCL({ ...filterVCL, ...date });
		} else {
			if (filterVCL.datesRange) {
				delete filterVCL.datesRange;
			}
			setFilterVCL({ ...filterVCL });
		}
	};

	const resetFilterValueCodesLists = () => {
		setStatusSelectedVCL('all');
		setServiceSelected('all');
		setTotalCodesSelected('all');
		changedDates(null);
		setFilterVCL({ location: locationVCL, status: 'all' });
	};

	const onFilterVCLChange = (e: any) => {
		const value = e?.target?.value || '';
		setSearchTextVCL(value);
		if (value && value.length > 2) {
			searchInputVCL({
				search: { query: value, fields: ['name'] }
			});
		} else {
			searchInputVCL(null);
		}
	};

	useEffect(() => {
		if (searchVCL !== null) {
			setFilterVCL({ ...filterVCL, ...searchVCL });
		} else {
			setFilterVCL({ ...filterVCL, search: undefined });
		}
	}, [searchVCL]);

	useEffect(() => {
		if (filterVCL?.$text) {
			setSearchTextVCL(filterVCL?.$text);
		}
	}, [searchTextVCL, filterVCL?.$text]);

	return (
		<div className={classes.eventsToolbar}>
			<div className={classes.filterPosition}>
				<SearchFilter
					{...props}
					hideFilter={false}
					className={classes.filter}
					filterValues={[]}
					setFilters={() => []}
				>
					<SearchInput
						variant='outlined'
						size='small'
						source='valueCodeList-id'
						alwaysOn
						placeholder='Search by list id or name'
						className={classes.input}
						onChange={onFilterVCLChange}
						options={{
							value: searchTextVCL
						}}
					/>
				</SearchFilter>
				<Filter
					id='service_label'
					label='Service'
					value={serviceSelected}
					onOpen={refundsFilterOpened}
					onChange={serviceChanged}
					labelWidth={60}
				>
					<MenuItem key={`service_all`} value={'all'} selected={true}>
						All
					</MenuItem>
					{services.map((serviceEle: any, index: number) => {
						return (
							<MenuItem key={`status_${index}`} value={serviceEle.id} selected={false}>
								{serviceEle.name}
							</MenuItem>
						);
					})}
				</Filter>
				<CustomDatePicker
					fieldKey='datesRange'
					setFilterSearch={changedDates}
					ts={filterVCL.datesRange}
					labelWidth={50}
				/>
				<Filter
					id='status_label'
					label='Status'
					value={statusSelectedVCL}
					onOpen={refundsFilterOpened}
					onChange={statusChangedVCL}
					labelWidth={55}
				>
					{statusVCL.map((statusEle: any, index: number) => {
						return (
							<MenuItem
								key={`status_${index}`}
								value={statusEle.id}
								selected={statusSelectedVCL === statusEle.id}
							>
								{statusEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				<Filter
					id='totalCodes_label'
					label='Total Codes'
					value={totalCodesSelected}
					onOpen={refundsFilterOpened}
					onChange={totalCodesChanged}
					labelWidth={95}
				>
					{totalCodes.map((totalCodeEle: any, index: number) => {
						return (
							<MenuItem
								key={`totalCodes_${index}`}
								value={totalCodeEle.id}
								selected={totalCodesSelected === totalCodeEle.id}
							>
								{totalCodeEle.description}
							</MenuItem>
						);
					})}
				</Filter>
				{filterChangedVCL && (
					<Button
						classes={{
							root: classes.btn,
							text: classes.btnText
						}}
						onClick={resetFilterValueCodesLists}
						endIcon={
							<SvgIcon component={CloseIcon} viewBox='0 0 24 24' className={classes.svgIcon} />
						}
					>
						Reset All Filters
					</Button>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		eventsToolbar: {
			display: 'flex',
			padding: '12px 0px 22px 0px',
			backgroundColor: '#FFFFFF'
		},
		filterPosition: {
			display: 'flex',
			'& MuiFormControl-root': {
				height: '48px'
			},
			'& .MuiOutlinedInput-root': {
				height: '100%'
			},
			'& .react-datepicker-popper': {
				zIndex: 2
			}
		},
		btn: {
			textTransform: 'none',
			marginLeft: '5px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			borderRadius: '8px',
			marginTop: '-27px'
		},
		btnText: {
			padding: '6px 14px 6px 8px'
		},
		svgIcon: {
			width: '13px',
			height: '13px'
		},
		filter: {
			backgroundColor: '#ffffff',
			'& .filter-field': {
				margin: '25px -16px 24px 0px',
				height: '43px',
				width: '250px'
			}
		},
		input: {
			'& input': {
				paddingTop: '15px',
				paddingBottom: '15px'
			}
		},
		datePicker: {
			marginLeft: '11px'
		}
	})
);
