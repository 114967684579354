import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
	createStyles({
		listContainer: {
			width: 'auto',
			marginLeft: 0,
			borderTop: '1px solid #E2E3E9',
			background: '#F2F4FB'
		},
		listRoot: {
			margin: '24px 40px 0px 40px'
		},
		listContent: {
			boxShadow: '0 0 0 white',
			background: '#F2F4FB'
		},
		table: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			background: '#FFFFFF',
			minWidth: '980px'
		},
		thead: {
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px'
		},
		tbody: {
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px'
		},
		headerCell: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#111111',
			padding: '20px 24px',
			width: '200px',
			'&:first-child': {
				// paddingLeft: '24px',
				width: '100px'
			},
			'&:last-child': {
				width: '10px'
			}
		},
		branchName: {
			fontWeight: 'bold',
			color: '#1695CB'
		},
		location: {
			fontWeight: 'bold',
			color: '#1695CB'
		},
		status: {
			color: '#2D7D44',
			backgroundColor: '#E9F7ED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		rowOdd: {
			backgroundColor: '#F8F9FA'
		},
		exportBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '8px, 16px, 8px, 12px',
			borderRadius: '8px',
			width: '163px',
			height: '40px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		exportBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		}
	})
);
