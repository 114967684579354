import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify, MenuItemLink } from 'react-admin';
import { RaBox } from 'ra-compact-ui';
import { useStyles } from './permissionsStyles';
import { HeaderList } from '../common/HeaderList';
import { RoleDetailShow } from './RoleDetailShow';

function RolesList(props: any) {
	const classes = useStyles();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	// eslint-disable-next-line no-unused-vars
	const [search, searchInput] = React.useState('');
	const [roles, setRoles] = useState<any>();
	const [showDetail, setshowDetail] = React.useState(true);

	useEffect(() => {
		dataProvider
			.getList('roles', {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then(({ data }) => {
				setRoles(data);
			})
			.catch((error) => {
				notify('Error getting roles: ' + error, 'warning');
			});
	}, [props]);

	const onMenuClick = (roleId: any) => {
		setshowDetail(true);
	};

	return (
		<>
			<HeaderList searchInput={searchInput} idSearch='branchName' showSearch={true} {...props} />
			<div className={classes.listContainer}>
				<RaBox display='flex' flexWrap='wrap' flexGrow={4}>
					<div id='my_menu' className={classes.myMenu}>
						{roles?.map((role: any, index: number) => {
							return (
								<MenuItemLink
									key={index}
									to={`/roles/${role.id}/show`}
									primaryText={role.name}
									onClick={onMenuClick}
									className={classes.menuLink}
									activeClassName={showDetail ? classes.active : ''}
								/>
							);
						})}
					</div>
					{showDetail ? <RoleDetailShow record={props}></RoleDetailShow> : <span></span>}
				</RaBox>
			</div>
		</>
	);
}

export default RolesList;
