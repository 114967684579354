import React from 'react';
import { IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

type optionType = {
	label: string;
	action: (param?: any) => any;
	disabled: boolean;
};

interface MenuProps {
	options?: optionType[];
	record?: any;
	iconName: string;
}

const useStyles = makeStyles(() => ({
	container: {
		display: 'inherit'
	},
	menuPaper: {
		borderRadius: '8px',
		background: '#FFFFFF',
		boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.12)',
		padding: '16px 16px 0 16px',
		zIndex: 9999
	},
	menuItemRoot: {
		marginBottom: '16px',
		'&:hover': {
			background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
			borderRadius: '8px'
		},
		padding: '7.5px 12px',
		fontFamily: 'Manrope',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '18px',
		lineHeight: '25px',
		textAlign: 'right',
		color: '#111111'
	}
}));

export default function LongMenu(props: MenuProps) {
	const classes = useStyles();
	const menuOptions = props.options || [];
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className={classes.container}>
			<IconButton
				size='small'
				aria-label='more'
				aria-controls='long-menu'
				aria-haspopup='true'
				onClick={handleClick}
			>
				{props.iconName === 'MoreVertIcon' && <MoreVertIcon />}
				{props.iconName === 'ArrowDropDownIcon' && <ArrowDropDownIcon />}
			</IconButton>
			<Menu
				id='long-menu'
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				classes={{
					paper: classes.menuPaper
				}}
			>
				{menuOptions.map((option: any) => (
					<MenuItem
						key={option.label}
						disabled={option.disabled}
						selected={option.label === 'None'}
						onClick={() => {
							option.action(props.record);
							handleClose();
						}}
						classes={{
							root: classes.menuItemRoot
						}}
					>
						{option.label}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
