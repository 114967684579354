import React, { useState } from 'react';
import { createStyles, makeStyles, Typography, Button, Snackbar } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { AddValueCodeListDialog } from './Dialogs/AddValueCodeListDialog';
import { useCreate, useMutation, useNotify, useRefresh } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { hasCreate } from '../../utils/permissions';

const useStyles = makeStyles(() =>
	createStyles({
		showTitle: {
			width: 'auto',
			marginTop: '8px',
			marginLeft: 0,
			background: '#FFFFFF'
		},
		secondaryTitle: {
			display: 'flex',
			justifyContent: 'space-between',
			borderBottom: '1px solid #E0E0E0',
			marginBottom: '20px',
			paddingBottom: '15px'
		},
		titleBackBranchList: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			paddingTop: '25px',
			color: '#969696',
			'&:hover': {
				cursor: 'pointer'
			}
		},
		breadcrumbElement: {
			color: 'gray',
			display: 'flex',
			alignItems: 'center',
			paddingLeft: '40px',
			paddingBottom: '10px',
			borderBottom: '1px solid #E0E0E0'
		},
		colorId: {
			color: 'black'
		},
		breadcrumbBase: {
			fontSize: '44px',
			fontWeight: 800,
			lineHeight: '113%',
			color: '#232F64',
			letterSpacing: '-1px',
			marginLeft: '40px',
			marginTop: '21px'
		},
		divider: {
			borderLeft: '1px solid lightgrey',
			paddingLeft: '15px',
			color: 'grey',
			display: 'flex',
			flexDirection: 'column'
		},
		dividerItems: {
			flex: 1
		},
		dividerSpace: {
			paddingRight: '25px'
		},
		navigationIcon: {
			position: 'relative',
			top: '20px',
			margin: '0px 5px 0px 6px',
			color: '#BDBDBD'
		},
		searchResultsTitle: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#5F5F5F',
			marginTop: '33px'
		},
		filter: {
			position: 'absolute',
			right: '30px',
			top: '78px'
		},
		createListButton: {
			position: 'absolute',
			right: '30px',
			marginTop: '98px'
		},
		createListBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '8px, 16px, 8px, 12px',
			borderRadius: '8px',
			width: '163px',
			height: '40px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		createListBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		exportBtnContainer: {
			position: 'absolute',
			right: '30px',
			top: '23px'
		},
		exportBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '1px 0px',
			borderRadius: '8px',
			width: '129px',
			height: '36px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		exportBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		}
	})
);

export function HeaderValueCodeList(props: any) {
	const classes = useStyles();
	const history = useHistory();
	const [create, { error }] = useCreate();
	const refresh = useRefresh();
	const notify = useNotify();
	const [list, setList] = useState({
		name: '',
		id: ''
	});
	const [openAddListSucces, setOpenAddListSucces] = React.useState(false);
	const [mutate] = useMutation();
	const [openAddValueCodeListDialog, setOpenAddValueCodeListDialog] = useState(false);

	const addValueCodeListClicked = () => {
		setOpenAddValueCodeListDialog(true);
	};

	const addValueCodeListDialogClosed = () => {
		setOpenAddValueCodeListDialog(false);
	};

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAddListSucces(false);
	};

	const goToList = () => {
		history.push(`valuecodelists/${list.id}/show`);
	};

	const dialogAddValueCodeListClicked = (listData: any) => {
		var initialDeposits: any[] = [];
		listData.currenciesAndAmounts.forEach((element: any) => {
			if (element.currencyId !== '' && element.amount !== '')
				initialDeposits.push({
					currencyId: element.currencyId,
					currency: element.currencyName,
					amount: element.amount
				});
		});
		listData.values.totalCodes = parseInt(listData.values.totalCodes);
		setOpenAddValueCodeListDialog(false);
		mutate(
			{
				type: 'create',
				resource: 'valuecodelists',
				payload: {
					id: '',
					data: {
						name: listData.listName,
						createdBy: localStorage.getItem('cscvc_loginFullName'),
						locationId: listData.location !== '' ? listData.location : null,
						serviceId: listData.service,
						totalCodes: listData.values.totalCodes,
						SettingsJSON: {
							initialAmounts: initialDeposits,
							expiration: {
								validityPeriod: listData.expiration.validityPeriod,
								expirationDate:
									listData.expiration.expirationDate !== 'All'
										? listData.expiration.expirationDate
										: null
							}
						}
					}
				}
			},
			{
				onSuccess: ({ data }) => {
					create('valuecodes/' + data.id + '/' + listData.values.totalCodes);
					if (error) {
						notify(error, 'error');
					} else {
						refresh();
						setList({ name: data.name, id: data.id });
						setOpenAddListSucces(true);
					}
				},
				onFailure: (error) => {
					notify(`${error}`, 'warning');
				}
			}
		);
	};

	return (
		<>
			<div className={classes.showTitle}>
				<Typography variant='body1' noWrap className={classes.breadcrumbElement}>
					<span
						className={classes.titleBackBranchList}
						onClick={() => {
							history.push('/' + props.resource);
						}}
					>
						Value Codes Lists
					</span>
					<span className={classes.colorId}>{props.id}</span>
				</Typography>
				<div className={classes.exportBtnContainer}>
					<Button
						variant='contained'
						size='small'
						startIcon={<GetAppIcon />}
						onClick={props.onExports}
						classes={{
							root: classes.exportBtnRoot,
							label: classes.exportBtnTxt
						}}
					>
						Export
					</Button>
				</div>
				<div className={classes.secondaryTitle}>
					<Typography noWrap className={classes.breadcrumbBase}>
						Value Codes Lists
					</Typography>
				</div>

				{props.children}
			</div>
			{hasCreate(null, 'valueCodeLists') ? (
				<div className={classes.createListButton}>
					<Button
						variant='contained'
						size='small'
						startIcon={<AddIcon />}
						onClick={addValueCodeListClicked}
						classes={{
							root: classes.createListBtnRoot,
							label: classes.createListBtnTxt
						}}
					>
						Create List
					</Button>
				</div>
			) : (
				<div></div>
			)}
			{hasCreate(null, 'valueCodeLists') ? (
				<AddValueCodeListDialog
					openAddValueCodeList={openAddValueCodeListDialog}
					addValueCodeListClosed={addValueCodeListDialogClosed}
					addValueCodeListClicked={dialogAddValueCodeListClicked}
					locations={props.locations}
					services={props.services}
				/>
			) : (
				<></>
			)}
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				open={openAddListSucces}
				autoHideDuration={10000}
				onClose={handleClose}
				message={`List created: ${list.name}`}
				action={
					<React.Fragment>
						<Button color='secondary' size='small' onClick={goToList}>
							VIEW LIST
						</Button>
						<IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
							<CloseIcon fontSize='small' />
						</IconButton>
					</React.Fragment>
				}
			/>
		</>
	);
}
