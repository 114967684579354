import React, { useEffect, useState } from 'react';
import { List, TextField, DateField, useDataProvider, downloadCSV } from 'react-admin';
import { HeaderList } from '../common/HeaderList';
import { EmptyGrid } from '../common/EmptyGrid';
import CustomPagination from '../common/PaginationCustom';
import useStyles from './tableStyles';
import { TransactionsFilters } from './TransactionsFilters';
import jsonExport from 'jsonexport/dist';
import { ViewTransactionDialog } from './Dialogs/ViewTransactionDialog';
import { MyDatagrid } from '../common/myDataGrid/MyDatagrid';

function TransactionsList(props: any) {
	const classes = useStyles();
	const dataProvider = useDataProvider();
	// eslint-disable-next-line no-unused-vars
	const [search, searchInput] = useState('');
	const [services, setServices] = useState<any>([]);
	const [locations, setLocations] = useState<any>([]);
	const [transaction, setTransaction] = useState({});
	const [filter, setFilter] = useState<any>({});
	const [openTransactionDialog, setOpenTransactionDialog] = useState(false);

	useEffect(() => {
		setServices(JSON.parse(localStorage.getItem('cscvc_filterServices') as string));
		setLocations(JSON.parse(localStorage.getItem('cscvc_filterLocations') as string));
	}, []);

	const onExports = () => {
		dataProvider
			.getList(`transactions`, {
				filter: filter,
				sort: { field: 'transactionDate', order: 'ASC' },
				pagination: { page: 1, perPage: 1000000 }
			})
			.then((transactions: any) => {
				const transactionsForExport = transactions.data.map((transaction: any) => ({
					TransactionDate: transaction.transactionDate,
					Service: transaction.service?.name,
					Location: transaction.location?.name,
					AS400: transaction.aS400ID,
					HumanReadableId: transaction.valueCode?.humanReadableId,
					TransactionType: transaction.transactionType,
					CurrencyType: transaction.currencyType,
					Amount: `$ ${Number(transaction.amount).toFixed(2)}`
				}));
				jsonExport(
					transactionsForExport,
					{
						headers: []
					},
					// eslint-disable-next-line n/handle-callback-err
					(err, csv) => {
						downloadCSV(csv, 'transactions');
					}
				);
			});
	};

	const closeTransactionDialog = () => {
		setOpenTransactionDialog(false);
	};

	const Amount = (props: any) => {
		return <span>{props.record.amount}</span>;
	};

	return (
		<>
			<HeaderList
				searchInput={searchInput}
				idSearch='branchName'
				showSearch={true}
				onExports={onExports}
				{...props}
			/>
			<div className={classes.filtersContainer}>
				<TransactionsFilters
					filter={filter}
					setFilter={setFilter}
					locations={locations}
					services={services}
					showExportBtn={false}
					valueCodeFilter={false}
				/>
			</div>
			<div className={classes.listContainer}>
				<List
					{...props}
					bulkActionButtons={false}
					empty={<EmptyGrid />}
					sort={{ field: 'transactionDate', order: 'DESC' }}
					filter={filter}
					actions={null}
					pagination={<CustomPagination />}
					perPage={10}
					syncWithLocation
					classes={{
						root: classes.listRoot,
						content: classes.listContent
					}}
				>
					<MyDatagrid
						hover={true}
						resource='valuecodelists'
						classes={{
							table: classes.table,
							thead: classes.thead,
							tbody: classes.tbody,
							headerCell: classes.headerCell,
							rowCell: classes.tableCell,
							rowOdd: classes.rowOdd
						}}
						hasRowClick={true}
						setTransaction={setTransaction}
						setOpenTransactionDialog={setOpenTransactionDialog}
					>
						<DateField source='transactionDate' label='Transaction Date' showTime />
						<TextField source='service.name' label='Service' />
						<TextField source='location.name' label='Location' />
						<TextField source='aS400ID' label='AS400ID' />
						<TextField source='valueCode.humanReadableId' label='Human Readable ID' />
						<TextField source='transactionType' label='Transaction Type' />
						<TextField source='currencyType' label='Currency Type' />
						<Amount label='Amount' />
					</MyDatagrid>
				</List>
			</div>
			<ViewTransactionDialog
				transactionDialogClosed={closeTransactionDialog}
				openTransactionDialog={openTransactionDialog}
				transaction={transaction}
				{...props}
			/>
		</>
	);
}

export default TransactionsList;
