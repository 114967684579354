import React from 'react';
import { Datagrid, Loading, useListSortContext } from 'react-admin';
import { EmptyGrid } from '../EmptyGrid';
import {
	FILTERING_LOADING_LABEL,
	GENERAL_LOADING_LABEL,
	SECONDARY_LOADING_LABEL
} from './constants';
import CustomDatagridBody from './CustomDataGridBody';

export interface FilterProps {
	value: any;
	created?: string;
	key?: string;
}

/**
 *
 * @param props
 * @constructor
 */
export const CustomDatagrid = (props: any) => {
	const { loading, filter, setCurSort, ids } = props;

	if (loading) {
		return (
			<div>
				<Loading
					loadingPrimary={
						/* https://marmelab.com/react-admin/Translation.html#translation-messages */
						/* https://github.com/marmelab/react-admin/blob/master/packages/ra-language-english/src/index.ts */
						filter && filter.value ? FILTERING_LOADING_LABEL : GENERAL_LOADING_LABEL
					}
					loadingSecondary={SECONDARY_LOADING_LABEL}
				/>
			</div>
		);
	}

	if (ids.length > 0) {
		const { currentSort } = useListSortContext();
		if (setCurSort) setCurSort(currentSort);

		return (
			<Datagrid
				{...props}
				body={
					<CustomDatagridBody filterSearch={props.filterSearch} hasRowClick={props.hasRowClick} />
				}
				hasBulkActions={false}
			/>
		);
	}

	return <EmptyGrid />;
};
