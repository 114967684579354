import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
	createStyles({
		active: {
			color: '#2D7D44',
			backgroundColor: '#E9F7ED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		inactive: {
			color: '#5F5F5F',
			backgroundColor: '#EDEDED',
			fontWeight: 500,
			padding: '3px 10px'
		}
	})
);

export const ColumnCustom = (props: any) => {
	const classes = useStyles();
	if (props.record.active) {
		return <label className={classes.active}>Active</label>;
	} else return <label className={classes.inactive}>Inactive</label>;
};

export const TrueFalseColumn = (props: any) => {
	const classes = useStyles();
	if (props.record.active) {
		return <label className={classes.active}>True</label>;
	} else return <label className={classes.inactive}>False</label>;
};
