import React from 'react';
import { makeStyles, createStyles, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(() =>
	createStyles({
		emptyResultsContainer: {
			marginTop: '134px',
			marginBottom: '134px'
		},
		emptyResultsTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '24px',
			lineHeight: '33px',
			textAlign: 'center',
			letterSpacing: '-0.5px',
			color: '#111111'
		}
	})
);

export const EmptyGrid = () => {
	const classes = useStyles();

	return (
		<Box textAlign='center' className={classes.emptyResultsContainer}>
			<Typography className={classes.emptyResultsTxt}>0 Results</Typography>
		</Box>
	);
};
