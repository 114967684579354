import * as React from 'react';
import { IconButton, TableCell, TableRow } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
	Datagrid,
	DatagridBody,
	Loading,
	useDataProvider,
	useListContext,
	useListSortContext
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import { EmptyGrid } from '../EmptyGrid';
import { MyDataGridProps } from './MyDatagridProps';

// Show a click indicator when row is hovered over - entire row is a click target, so this icon doesn't need params
export const MyShowButton = () => {
	const classes = useStyles();
	return (
		<IconButton className={classes.showBtn} size='medium' color='secondary' aria-label='Clone'>
			<ArrowForwardIcon />
		</IconButton>
	);
};

// https://stackoverflow.com/questions/55852415/how-to-change-the-text-color-of-the-selected-row-in-material-ui-table
const MyDatagridRow = ({
	record,
	resource,
	children,
	basePath,
	id,
	filterSearch,
	hasRowClick,
	setTransaction,
	setOpenTransactionDialog,
	listName,
	setCurrencyType,
	setOpenEditCurrencyTypeDialog,
	setWebhook,
	setOpenEditWebhookDialog
}: MyDataGridProps) => {
	const classes = useStyles();
	const history = useHistory();
	const { loading } = useListContext();
	const dataProvider = useDataProvider();

	const getTableCellClassName = (field: any): string => {
		if (resource === 'valuecodelists') {
			return classes.tableCellValueCodeLists;
		}

		if (resource === 'valuecodes') {
			return classes.tableCellValueCodes;
		}

		if (resource === 'transactions') {
			return classes.tableCellTransactions;
		}

		if (record.humanReadableId !== null && !resource) {
			return classes.tableCellTransactionsFromCode;
		}

		if (resource === 'users') {
			return classes.tableCellTransactions;
		}

		return classes.tableCellActivity;
	};

	const tableRowClicked = (): void => {
		if (record.humanReadableId !== null && !resource) {
			window.localStorage.setItem('valuCode', JSON.stringify(record));
			window.localStorage.setItem('listName', listName);
			history.push({
				pathname: `/valuecodes/${record.id}/show`
			});
		}
		if (resource === 'valuecodes') {
			dataProvider.getOne('valuecodelists', { id: record.valueCodeListId }).then(({ data }) => {
				window.localStorage.setItem('valuCode', JSON.stringify(record));
				window.localStorage.setItem('listName', data.name);
				history.push({
					pathname: `/valuecodes/${record.id}/show`
				});
			});
		}
		if (resource === 'valuecodelists') {
			history.push({
				pathname: `/valuecodelists/${id}/show`,
				state: {
					filterSearch
				}
			});
		}
		if (resource === 'transactions') {
			setTransaction(record);
			setOpenTransactionDialog(true);
		}
		if (resource === 'currencytypes') {
			setCurrencyType(record);
			setOpenEditCurrencyTypeDialog(true);
		}
		if (resource === 'webhooks') {
			setWebhook(record);
			setOpenEditWebhookDialog(true);
		}
		if (resource === 'consumers') {
			history.push({
				pathname: `/consumers/${id}/show`,
				state: {
					filterSearch
				}
			});
		}
		if (resource === 'users') {
			history.push({
				pathname: `/users/${id}/show`,
				state: {
					filterSearch
				}
			});
		}
	};

	const getTableCellRowClassName = () => {
		return hasRowClick ? classes.tableRow : classes.tableRowActivity;
	};

	const tableRowClickDoNothing = () => {
		// do nothing
	};

	return (
		<TableRow
			key={id}
			hover
			classes={{ hover: classes.hover }}
			className={getTableCellRowClassName()}
			onClick={hasRowClick ? tableRowClicked : tableRowClickDoNothing}
		>
			{!loading &&
				React.Children.map(children, (field: any) => (
					<TableCell
						id={`${id}_${field?.props?.source}`}
						key={`${id}_${field?.props?.source}`}
						className={getTableCellClassName(field)}
					>
						{React.cloneElement(field, {
							record,
							basePath,
							resource
						})}
					</TableCell>
				))}
		</TableRow>
	);
};

const MyDatagridBody = (props: any) => {
	return (
		<DatagridBody
			{...props}
			row={
				<MyDatagridRow
					filterSearch={props.filterSearch}
					hasRowClick={props.hasRowClick}
					setTransaction={props.setTransaction}
					setOpenTransactionDialog={props.setOpenTransactionDialog}
					listName={props.listName}
					setCurrencyType={props.setCurrencyType}
					setOpenEditCurrencyTypeDialog={props.setOpenEditCurrencyTypeDialog}
					setWebhook={props.setWebhook}
					setOpenEditWebhookDialog={props.setOpenEditWebhookDialog}
				/>
			}
		/>
	);
};

export const MyDatagrid = (props: any) => {
	const { data, loading, filter, setCurSort, total, setTotalHeader } = props;
	if (setTotalHeader) setTotalHeader(0);

	if (loading) {
		return (
			<div>
				<Loading
					loadingPrimary={
						/* https://marmelab.com/react-admin/Translation.html#translation-messages */
						/* https://github.com/marmelab/react-admin/blob/master/packages/ra-language-english/src/index.ts */
						filter && filter.value ? 'Searching' : 'Retrieving the information'
					}
					loadingSecondary='Wait a moment'
				/>
			</div>
		);
	}

	if (data && Object.keys(data).length > 0) {
		// for EXPORT usage
		if (setCurSort) {
			const { currentSort } = useListSortContext();
			setCurSort(currentSort);
		}
		if (setTotalHeader) setTotalHeader(total);

		return (
			<Datagrid
				{...props}
				body={
					<MyDatagridBody
						filterSearch={props.filterSearch}
						hasRowClick={props.hasRowClick}
						setTransaction={props.setTransaction}
						setOpenTransactionDialog={props.setOpenTransactionDialog}
						listName={props.listName}
						setCurrencyType={props.setCurrencyType}
						setOpenEditCurrencyTypeDialog={props.setOpenEditCurrencyTypeDialog}
						setWebhook={props.setWebhook}
						setOpenEditWebhookDialog={props.setOpenEditWebhookDialog}
					/>
				}
				hasBulkActions={false}
			/>
		);
	}

	return <EmptyGrid />;
};
